/*
 * checks if the user is logged in
 * redirects to /login if not
 * unprotected routes have to be explicitly defined
 */

import cookies from 'js-cookie'

// TODO: We should really think about refactoring this by removing most of the conditional logic and handle the default case with this middleware, letting pages opt out of the default behaviour

// Explicitly allow certain routes to not need a logged in user
// all others will enforce one
const unprotectedRoutes = [
  'bye',
  'login',
  'password-reset',
  'password-reset-confirm',
  'password-reset-mail-sent',
  'password-reset-new',
  'profile-confirm',
  'signup',
  'business-signup',
  'business-signup-mail-sent',
  'business-signup-steps',
  'signup-confirm',
  'signup-mail-sent',
  'signup-steps',
  'sorry',
  'sso-error',
  'sso-error-company',
  'view-id-userid',
  'direct-sign-identification-id-userid',
  'direct-sign-identification-id-userid-preview',
  '404',
  'expired',
  'component-test',
]

// Unprotected routes which shall not be redirected if user is logged in
const unredirectedRoutes = [
  'bye',
  'profile-confirm',
  'sorry',
  'view-id-userid',
  'direct-sign-identification-id-userid',
  'direct-sign-identification-id-userid-preview',
  '404',
  'component-test',
]

export default defineNuxtRouteMiddleware(async to => {
  const { $sentry } = useNuxtApp()

  const authStore = useAuthStore()
  const userStore = useUserStore()

  // Prevent further authentication attempts in case the user has blocked cookies
  // Blocking cookies also blocks localStorage and sessionStorage, breaking our platform
  if (!navigator.cookieEnabled) return

  try {
    // The potential NAS entry points can build the token from the URL parameters
    if (to.name === 'view-id-userid' || to.name === 'direct-sign-identification-id-userid') {
      const directSignStore = useDirectSignStore()

      const { userid, id } = to.params

      const userId = userid as string

      const token = btoa(`${userid}:${id}`)
      authStore.storeToken(token, 'Basic')

      directSignStore.setDirectSign(userId, id as string)
      await userStore.loadUserBusinessData()
      await userStore.fetchUser()
      $sentry?.setUser({ id: userId })

      return
    }
    // SSO logins will hand the token via a x-access-token cookie. wave will pick
    // this cookie up, set the sessionStorage accordingly and then remove it
    // again to not having it sent to netlify.
    const ssoToken = cookies.get('x-access-token')
    if (ssoToken) {
      authStore.storeToken(ssoToken)
      cookies.remove('x-access-token')
    }

    // Certain integrations are in possession of a user's login credentials to
    // interact with our API in their name. An example being are eID+ from
    // Procivis. Those integrations can use a special API endpoint login/extended
    // to retrieve a short-lived token which in turn can be used as an URL
    // parameter `usertoken` to have this user also logged in on wave for as long
    // as the initial token is valid.
    const usertoken: string | undefined = to.query.usertoken as string | undefined
    if (usertoken) {
      authStore.storeToken(usertoken)
    }

    let userLoggedIn = false
    // First heuristic is to see if we have a user
    if (!userStore.id) {
      // Recover user from session/localStorage if not expired.
      const token = authStore.getStoredToken()
      if (token) {
        try {
          await userStore.loadUserBusinessData()
          await userStore.fetchUser()
          userLoggedIn = true
          $sentry?.setUser({ id: userStore.id })
        } catch {
          // The token in storage is no longer valid in phoebe.
          authStore.removeStoredToken()
        }
      }
    } else {
      // We have a user in store which might still be logged in.
      // If not phoebe will complain once we do a request against it.
      userLoggedIn = true
      $sentry?.setUser({ id: userStore.id })
    }

    const redirectPath = sessionStorage.getItem('redirectURL')

    if (userLoggedIn && redirectPath && to.name !== 'logout') {
      sessionStorage.removeItem('redirectURL')

      if (to.fullPath !== redirectPath) {
        return navigateTo(redirectPath)
      }
    }

    if (!to.name || !unprotectedRoutes.includes(to.name as string)) {
      if (!userLoggedIn) {
        // store where user wanted to go before login
        // but only if it was not on the logout page
        if (to.name !== 'logout') {
          if (to.name !== 'login') {
            sessionStorage.setItem('redirectURL', to.fullPath)
          }
          return navigateTo('/login')
        }
      }
    } else if (!unredirectedRoutes.includes(to.name as string)) {
      // logged in users shall be sent to /
      if (userLoggedIn) {
        return navigateTo('/')
      }
    }
  } catch (error) {
    $sentry?.captureException(error, {
      extra: {
        info: 'Failed to authenticate user',
      },
    })
    throw new Error('Failed to authenticate user')
  }
})
