type Platform = 'CH' | 'EU'

export const useSystemStore = defineStore('system', () => {
  const { $config } = useNuxtApp()

  const domainName = computed<string>(() => $config.public.domainName)

  const platform = computed<Platform>(() => {
    if (/\.(de|eu)$/.test(domainName.value)) {
      return 'EU'
    } else return 'CH'
  })

  const emails = reactive({
    support: 'support@' + domainName.value,
    contact: 'contact@' + domainName.value,
    success: 'success@' + domainName.value,
  })

  const redirectUrls = reactive({
    homepage: 'https://' + domainName.value,
  })

  const time = reactive({
    now: '',
    lastUpdated: 0,
  })

  const updateTime = async () => {
    if ((Date.now() - time.lastUpdated) * 0.001 < 2) return

    const { systemRepository } = useApi()

    const { now } = await systemRepository.getDateTime()
    time.now = now
    time.lastUpdated = Date.now()
  }

  return {
    emails,
    redirectUrls,
    platform,
    time,
    updateTime,
  }
})
