<template>
  <v-navigation-drawer
    v-model="isShown"
    data-cy="adminNavMenu"
    mobile-breakpoint="md"
    location="left"
    width="300"
    :class="['mini-nav business', { 'mini-nav--is-locked': isLocked }]"
  >
    <nav-locked-overlay v-if="isLocked" />
    <!-- Top navigation items -->
    <div class="d-flex flex-column pt-9 | mini-nav__flex">
      <nuxt-link :to="{ name: 'business-profile' }" class="text-no-decoration pl-5">
        <logo data-cy="indexLogo" business="light" />
      </nuxt-link>
      <v-list class="py-0 pt-10 mini-nav__top">
        <template v-for="(item, index) in navMenuStructure.topStructure" :key="item.id">
          <div v-if="item.isEnabled" :class="{ 'pb-3': index !== navMenuStructure.topStructure.length - 1 }">
            <v-list-item :prepend-icon="item.icon" :to="item.route" :data-cy="item.id">
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
            <template v-if="item.children">
              <template v-for="child in item.children">
                <v-list-item
                  v-if="item.isEnabled && child.isEnabled"
                  :key="child.id"
                  prepend-icon=" "
                  exact
                  :to="child.route"
                >
                  <span class="mini-nav__border" />
                  <v-list-item-action />

                  <v-list-item-title>
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </template>
          </div>
        </template>
      </v-list>
      <!-- Bottom navigation items -->
      <v-list class="mt-auto py-0 pt-4 | business-nav-bottom">
        <template v-for="item in navMenuStructure.bottomStructure">
          <div v-if="item.isEnabled" :key="item.id">
            <v-divider />
            <!-- Item with no children -->
            <template v-if="!item.children?.length">
              <v-list-item :key="item.id" :prepend-icon="item.icon" :to="item.route" :data-cy="item.id">
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <!-- Item with expandable list of child items -->
            <template v-else-if="isSmallScreen">
              <v-list-group :key="item.id" :data-cy="item.id">
                <template #activator="{ props }">
                  <v-list-item v-bind="props" :prepend-icon="item.icon" :class="{ 'business-profile': item.avatar }">
                    <!--     prepend-avatar works only for image url, need to add it manually-->
                    <div class="business-profile-avatar">
                      <v-avatar v-if="item.avatar" color="primary" size="40px" class="text-white mr-3">{{
                        item.avatar
                      }}</v-avatar>
                      <div>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                        <v-list-item-subtitle
                          v-if="item.subtitle"
                          class="text-truncate | mini-nav__business-profile__subtitle"
                        >
                          {{ item.subtitle }}
                        </v-list-item-subtitle>
                      </div>
                    </div>
                  </v-list-item>
                </template>
                <template v-for="child in item.children">
                  <v-list-item
                    v-if="child.isEnabled"
                    :key="child.id"
                    :prepend-icon="child.icon"
                    :to="child.route"
                    :href="child.link"
                    :target="child.link ? '_blank' : undefined"
                    :data-cy="child.id"
                  >
                    <v-list-item-title>
                      {{ child.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="child.subtitle">
                      {{ child.subtitle }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
            <!-- Item with flyout menu listing child items -->
            <template v-else-if="!isSmallScreen">
              <div :key="item.id">
                <v-list-item
                  :id="item.id"
                  class="business-nav-bottom-flyout"
                  :prepend-icon="item.icon"
                  :class="{ 'business-profile business-profile-padding': item.avatar }"
                  :data-cy="item.id"
                >
                  <!--  prepend-avatar works only for image url, need to add it manually-->
                  <div class="business-profile-avatar">
                    <v-avatar v-if="item.avatar" color="primary" size="40px" class="text-white mr-3">{{
                      item.avatar
                    }}</v-avatar>
                    <div>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle
                        v-if="item.subtitle"
                        class="text-truncate | mini-nav__business-profile__subtitle"
                      >
                        {{ item.subtitle }}
                      </v-list-item-subtitle>
                    </div>
                  </div>

                  <template #append>
                    <v-icon color="#B3D4F7" size="1rem">custom:arrow_right</v-icon>
                  </template>
                </v-list-item>
                <v-menu
                  class="business-nav-menu"
                  location="end"
                  open-on-hover
                  :offset="[0, Number(item.menuOffset ?? '0')]"
                  :activator="`#${item.id}`"
                  min-width="200px"
                  content-class="nav-overlay-menu"
                >
                  <v-card>
                    <v-list nav class="pa-1">
                      <template v-for="child in item.children">
                        <v-list-item
                          v-if="child.isEnabled"
                          :key="child.id"
                          :prepend-icon="child.icon"
                          :to="child.route"
                          :href="child.link"
                          :target="child.link ? '_blank' : undefined"
                          exact
                          :data-cy="child.id"
                        >
                          <v-list-item-title>
                            {{ child.title }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="child.subtitle">
                            {{ child.subtitle }}
                          </v-list-item-subtitle>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
            </template>
          </div>
        </template>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
import NavLockedOverlay from '@/components/business/NavLockedOverlay.vue'
import Logo from '@/components/Logo.vue'

export default defineComponent({
  components: {
    Logo,
    NavLockedOverlay,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    isLocked: Boolean,
  },
  emits: ['update:modelValue'],
  setup() {
    const { $vuetify } = useNuxtApp()
    const { t } = useI18n()
    const businessStore = useBusinessStore()
    const { activePricePlanId } = useUserPlanInfo()
    const systemStore = useSystemStore()

    const isSmallScreen = computed(() => $vuetify.display.smAndDown.value)

    const seals = computed(() => businessStore.seals)
    const fetchSeals = async () => {
      await businessStore.getSeals()
    }

    const isIndividualPlan = computed(() => activePricePlanId.value?.startsWith('INDIVIDUAL'))
    const isProPlan = computed(() => activePricePlanId.value === 'PRO_V1')
    const isScalePlan = computed(() => activePricePlanId.value === 'SCALE')
    const isTrialPlan = computed(() => activePricePlanId.value?.startsWith('TRIAL'))
    const platform = computed(() => systemStore.platform || 'CH')

    const navMenuStructure = computed(() => {
      const topStructure = [
        {
          id: 'dashboard',
          title: 'Dashboard',
          icon: 'custom:f_dashboard',
          route: { name: 'business-dashboard' },
          isEnabled: !isIndividualPlan.value,
        },
        {
          id: 'team',
          title: t('business.navigation.team'),
          icon: 'custom:multiple',
          route: { name: 'business-members' },
          isEnabled: isScalePlan.value,
          children: [
            {
              id: 'members',
              title: t('business.navigation.members'),
              route: { name: 'business-members' },
              isEnabled: true,
            },
            {
              id: 'domains',
              title: t('business.members.navigation.domains'),
              route: { name: 'business-members-domains' },
              isEnabled: true,
            },
          ],
        },
        // Non-SCALE businesses only have access to member stuff (except `Individual`)
        {
          id: 'members',
          title: t('business.navigation.members'),
          icon: 'custom:multiple',
          route: { name: 'business-members' },
          isEnabled: !isScalePlan.value && !isIndividualPlan.value,
        },
        {
          id: 'plan',
          title: t('business.navigation.plan_and_billing'),
          icon: 'custom:wallet',
          route: { name: 'business-billing-plan' },
          isEnabled: true,
        },
        {
          id: 'activity',
          title: t('business.navigation.activity'),
          icon: 'custom:lightning_big',
          route: { name: 'business-activity' },
          isEnabled: !isIndividualPlan.value,
        },
        {
          id: 'seals',
          title: t('business.navigation.seal'),
          icon: 'custom:seal',
          route: { name: 'business-seals' },
          isEnabled: !isIndividualPlan.value && seals.value.length,
        },
        {
          id: 'api',
          title: t('business.navigation.developers'),
          icon: 'custom:developers',
          route: { name: 'business-developers-api' },
          isEnabled: isScalePlan.value || isProPlan.value || isTrialPlan.value,
        },
        {
          id: 'extras',
          title: t('business.navigation.extras'),
          icon: 'custom:integration',
          route: { name: 'business-extras' },
          isEnabled: isProPlan.value || isTrialPlan.value,
        },
      ]

      const bottomStructure = [
        {
          id: 'settings',
          title: t('global.navigation.business_settings'),
          icon: 'custom:setup_options',
          route: { name: 'business-settings' },
          isEnabled: !isIndividualPlan.value,
        },
        {
          id: 'help',
          title: t('global.navigation.help_center'),
          icon: 'custom:c_question',
          route: { name: 'business-settings' },
          isEnabled: true,
          children: [
            {
              id: 'admin-guide',
              title: t('global.navigation.admin_guide.label'),
              icon: 'custom:admin_guide',
              link: t('global.navigation.admin_guide.link'),
              isEnabled: true,
            },
            {
              id: 'help-center',
              title: t('global.navigation.helpcenter.label'),
              icon: 'custom:help_center',
              link: t('global.navigation.helpcenter.link'),
              isEnabled: true,
            },
            {
              id: 'video-tutorials',
              title: t('global.navigation.tutorials.label'),
              icon: 'custom:video_tutorials',
              link: t('global.navigation.tutorials.link'),
              isEnabled: true,
            },
            {
              id: 'contact-email',
              title: t('global.navigation.contact.label'),
              icon: 'custom:contact_email',
              link:
                // if business has internal level support - return always eng contact form
                businessStore.settings.supportContact?.email
                  ? t('global.navigation.contact.link_always_en')
                  : platform.value === 'EU'
                    ? t('global.navigation.contact.link_form_de')
                    : t('global.navigation.contact.link'),
              isEnabled: true,
            },
            {
              id: 'contact-phone',
              title: t('global.navigation.call.label'),
              subtitle: t('global.navigation.call.number'),
              icon: 'custom:support_hotline',
              link:
                systemStore.platform === 'EU'
                  ? t('global.navigation.call.call_support_form_de')
                  : t('global.navigation.call.call_support_form'),
              isEnabled: true,
            },
          ],
          // TODO: Compute programmatically
          menuOffset: '104',
        },
        {
          id: 'my-skribble',
          title: t('global.navigation.back_to_skribble'),
          icon: 'custom:go_to_skribble',
          route: { path: '/' },
          isEnabled: true,
        },
        {
          id: 'business-profile',
          title: t('business.navigation.business_profile'),
          subtitle: businessStore.name,
          avatar: t('business.navigation.business_profile_abbr'),
          route: { path: '/' },
          isEnabled: true,
          children: [
            {
              id: 'profile',
              title: t('business.navigation.business_profile'),
              icon: 'custom:business_profile',
              route: { name: 'business-profile' },
              isEnabled: true,
            },
            {
              id: 'aes-4-biz',
              title: t('business.profile.navigation.aes'),
              icon: 'custom:business_aes',
              route: { name: 'business-profile-aes-for-business' },
              isEnabled: isScalePlan.value,
            },
            {
              id: 'logout',
              title: t('global.navigation.logout'),
              icon: 'custom:leave',
              route: { name: 'logout' },
              isEnabled: true,
            },
          ],
          menuOffset: '56',
        },
      ]

      return { topStructure, bottomStructure }
    })

    onBeforeMount(() => {
      void fetchSeals()
    })

    return {
      businessStore,
      seals,
      isSmallScreen,
      navMenuStructure,
    }
  },
  computed: {
    isShown: {
      get(): boolean {
        return this.modelValue
      },
      set(newValue: boolean) {
        this.$emit('update:modelValue', newValue)
      },
    },
  },
  watch: {
    isSmallScreen(isMobile: boolean) {
      if (isMobile) this.isShown = false
    },
  },
  methods: {
    upgrade() {
      return navigateTo({ name: 'business-upgrade' })
    },
  },
})
</script>

<style lang="sass" scoped>
/* NB: This component relies on globally set legacy styles in BusinessNav.vue
 *
 * TODO: Clean up, copy over and watch out for scoped vs. Vuetify overrides
 */

.mini-nav
  &--is-locked
    pointer-events: none
    a > .logo
      visibility: hidden
  .v-divider
    border-color: $c-grey-dark
</style>

<style lang="sass">
.nav-overlay-menu
  padding-left: 10px
</style>
