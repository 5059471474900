<template>
  <pop-over-nav v-if="popover" :navigation="navArray" />
  <in-page-nav-bottom v-else-if="bottom" :navigation="navArray" />
  <in-page-nav v-else :navigation="navArray" />
</template>

<script lang="ts">
import InPageNav from '@/components/in-page-nav/InPageNav.vue'
import InPageNavBottom from '@/components/in-page-nav/InPageNavBottom.vue'
import PopOverNav from '@/components/in-page-nav/PopOverNav.vue'

export default defineComponent({
  name: 'ProfileNav',
  components: {
    InPageNav,
    InPageNavBottom,
    PopOverNav,
  },
  props: {
    bottom: Boolean,
    popover: Boolean,
  },
  setup() {
    const { isBusinessMember } = storeToRefs(useUserStore())

    return {
      userIsBusinessMember: isBusinessMember,
    }
  },
  computed: {
    navArray() {
      const nav: (NavigationItem | NavigationDivider)[] = [
        {
          routeName: 'profile',
          displayTitle: this.$t('global.navigation.personal_data'),
          icon: 'custom:circle_profile',
          cypressIdentifier: 'personalDataButton',
        },
        {
          routeName: 'profile-signature-standards',
          displayTitle: this.$t('global.navigation.signature_standards'),
          icon: 'custom:signature_standards',
          cypressIdentifier: 'signatureStandardsButton',
        },
        {
          routeName: 'profile-visual-signature',
          displayTitle: this.$t('global.navigation.visual_signature'),
          icon: 'custom:artboard',
          cypressIdentifier: 'visualSignatureButton',
        },
        {
          routeName: 'profile-settings',
          displayTitle: this.$t('global.navigation.settings'),
          icon: 'custom:gear',
          cypressIdentifier: 'settingsButton',
        },
        {
          divider: true,
        },
        {
          routeName: 'logout',
          displayTitle: this.$t('global.navigation.logout'),
          icon: 'custom:leave',
          cypressIdentifier: 'logoutButton',
        },
      ]

      // remove the dividers in the popover menu
      if (this.popover) {
        nav.forEach((item, index, object) => {
          if ('divider' in item) object.splice(index, 1)
        })
      }

      return nav
    },
  },
})
</script>
