import type { Stripe } from '@stripe/stripe-js'

interface PaymentData {
  stripeInstance: Stripe | null
  vatIncludedCountries: string[]
  supportedCurrencies: Currency[]
  pricePlans: PricePlan[]
  planHierarchy: PricePlanName[]
}

export const usePaymentStore = defineStore('payment', {
  state: (): PaymentData => ({
    stripeInstance: null,
    vatIncludedCountries: ['CH', 'LI'],
    supportedCurrencies: ['EUR', 'CHF'],
    pricePlans: [],
    // The price plans we offer, ordered from cheapest to most expensive
    planHierarchy: ['Trial', 'Individual', 'Team', 'Pro', 'Scale'],
  }),
  getters: {
    getPricePlanById: state => (planId: PricePlanId) => {
      return state.pricePlans.find(plan => plan.id === planId)
    },
    getSortedCurrencies: state => {
      const systemStore = useSystemStore()
      const { platform } = storeToRefs(systemStore)
      return platform.value === 'CH' ? state.supportedCurrencies.toReversed() : state.supportedCurrencies
    },
  },
  actions: {
    async getStripeInstance() {
      const { $config } = useNuxtApp()

      if (this.stripeInstance) return this.stripeInstance
      const stripe = await import(/* webpackChunkName: "stripe-loader" */ '@stripe/stripe-js')
      const stripeInstance = await stripe.loadStripe($config.public.stripePublishableKey || '')
      if (!stripeInstance) throw new Error('Failed to load Stripe')
      return stripeInstance
    },
    /**
     * Get a ready-made Stripe SetupIntent from our backend.
     *
     * Depending on the country of the user initiating this setup process we
     * want to show different payment methods in the PaymentElement.
     *
     * @param country The country specified in the billing form or platform default country
     */
    async getStripeSetupIntent(country?: string) {
      const businessStore = useBusinessStore()
      const { paymentRepository } = useApi()

      if (businessStore.id) {
        return await paymentRepository.getStripeSetupIntent(businessStore.id, country)
      } else {
        return await paymentRepository.getDirectStripeSetupIntent(country)
      }
    },
    async upgradeSubscription(subscriptionPayload: UpgradeSubscriptionPayload) {
      const businessStore = useBusinessStore()
      const { paymentRepository } = useApi()

      const updatedPricePlan = await paymentRepository.upgradeSubscription(businessStore.id, subscriptionPayload)

      businessStore.$patch({ pricePlan: updatedPricePlan })
    },
    async getBusinessPricePlans(): Promise<PricePlan[]> {
      const { paymentRepository } = useApi()

      const pricePlans = await paymentRepository.getPricePlans()

      const namedPricePlans: PricePlan[] = pricePlans
        .filter(plan => !plan.id.startsWith('TRIAL'))
        .map(plan => {
          const inferredName = plan.id.split('_').shift()

          return {
            ...plan,
            name: (inferredName ? inferredName.charAt(0) + inferredName.slice(1).toLowerCase() : '') as PricePlanName,
          }
        })

      this.$patch({ pricePlans: namedPricePlans })

      return namedPricePlans
    },
  },
})
