<script lang="ts" setup>
import { mdiSofaSingleOutline } from '@mdi/js'

import CouponSubtotals from '@/components/business/coupons/CouponSubtotals.vue'
import DancingDotsLoader from '@/components/DancingDotsLoader.vue'
import NumberIncrement from '@/components/NumberIncrement.vue'
import OverlayBase from '@/components/overlays/OverlayBase.vue'
import SkrAlert from '@/components/SkrAlert.vue'
import ToolbarBase from '@/components/ToolbarBase.vue'

const props = defineProps<{
  currentSeats: number
  maxSeatsDelta: number
  isLoading: boolean
}>()

const emit = defineEmits<{
  (e: 'action', type: OverlayAction, data?: unknown): void
  (e: 'close'): void
}>()

const { t } = useI18n()

const { formatCurrency } = useCurrency()
const { paymentRepository } = useApi()

const businessStore = useBusinessStore()

const widgetStore = useWidgetStore()
const paymentStore = usePaymentStore()
const { currentPricePlan, billing, id: businessId } = storeToRefs(useBusinessStore())

const planId = computed(() => currentPricePlan.value?.type ?? '')
const currency = computed(() => billing.value.currency ?? 'EUR')
const cycle = computed(() => (currentPricePlan.value?.cycle === 'YEARLY' ? 'yearly' : 'monthly'))

const icons = reactive({
  sofa: mdiSofaSingleOutline,
})

const pricePlan = ref<PricePlan>()

const updatePlanDetails = async () => {
  await paymentStore.getBusinessPricePlans()
  pricePlan.value = paymentStore.pricePlans.find(plan => plan.id === planId.value)
}

onBeforeMount(() => {
  void updatePlanDetails()
})

const selection = reactive({
  seats: 0,
  timestamp: '',
  price: 0,
  displayPrice: '',
  discountPriceData: {} as PreviewUpgradeSubscriptionPriceResponse,
})

const previewTimeout = ref(0)

const isFetching = ref(false)

const isCalculatingPrice = computed(() => isFetching.value || previewTimeout.value !== 0)

const { isDiscountActive: discountIsActive, discountPromoCode } = storeToRefs(businessStore)

const fetchPreviewPrice = async (seats: number) => {
  if (!selection.seats) {
    selection.displayPrice = formatCurrency(0, currency.value)
    isFetching.value = false
    return
  }

  isFetching.value = true

  try {
    const { amountDue, amountDiscountApplied, percentOff, amountSubtotal, timestamp } =
      await paymentRepository.previewSeatsUpgradePrice(businessId.value, seats)

    selection.price = amountDue
    selection.displayPrice = formatCurrency(amountDue, currency.value)
    selection.timestamp = timestamp
    selection.discountPriceData = {
      amountDiscountApplied,
      amountDiscountTotal: 0,
      amountDue: amountDue || selection.price,
      amountSubtotal,
      currency: currency.value,
      percentOff,
    }
  } catch {
    widgetStore.createSnackbar({ message: t('global.general_error') })
    selection.seats = 0
    selection.displayPrice = formatCurrency(0, currency.value)
  }

  isFetching.value = false
}

const isPreviewLoaded = computed(() => selection.seats > 0 && isFetching.value === false && selection.timestamp !== '')

watch(
  () => selection.seats,
  seatsToAdd => {
    if (previewTimeout.value) window.clearTimeout(previewTimeout.value)

    previewTimeout.value = window.setTimeout(
      () => {
        void fetchPreviewPrice(props.currentSeats + seatsToAdd)
        previewTimeout.value = 0
      },
      seatsToAdd > 0 ? 1000 : 0
    )
  },
  { immediate: true }
)

const seatPrice = computed<number>(() => {
  // FIXME: The backend uses inconsistent casing for currency values
  const lowercaseCurrency = currency.value.toLowerCase() as Lowercase<Currency>
  return pricePlan.value?.[cycle.value][lowercaseCurrency].seat ?? 0
})

const formatPackageName = (name: string) => {
  const screamingSnake = name.replace(/[A-Z]/g, char => `_${char}`).toUpperCase()
  const orString = t('global.or') as string
  return screamingSnake.replace('_', ` ${orString} `)
}

const signaturePackages = computed(() => {
  if (!pricePlan.value) return []

  return (
    Object.entries(pricePlan.value[cycle.value].signaturesIncluded)
      // Filter out packages with 0 or unlimited (-1) seats, those we don't need to show
      .filter(([_, count]) => count > 0)
      .map(([name, count]) => ({
        id: name,
        name: formatPackageName(name),
        count: selection.seats * count,
      }))
  )
})

const colorClass = computed(() => `bg-plan-${pricePlan.value?.name.toLowerCase()}`)

const submit = () => {
  if (props.isLoading) return
  // TODO: Refactor this
  emit('action', 'complete', { count: selection.seats, timestamp: selection.timestamp })
}

const submitAddSeatsButtonContent = computed(() => {
  if (selection.price === 0) return t('business.add_seats.add_at_no_cost', selection.seats, { count: selection.seats })
  return t('business.add_seats.add_and_pay', selection.seats, { count: selection.seats })
})

const couponAppliedText = computed(() => {
  if (selection.discountPriceData.percentOff && selection.discountPriceData.percentOff > 0) {
    const appliedText = String(t('business.coupon.input.banner_applied'))
    return `${appliedText} (-${selection.discountPriceData.percentOff}%)`
  }
  return t('business.add_seats.coupon_applied')
})
</script>

<template>
  <overlay-base>
    <template #header>
      <toolbar-base
        class="toolbar"
        :title="String(t('business.billing.seats.add'))"
        :app="false"
        is-closeable
        @close="emit('close')"
      />
    </template>
    <v-container class="content fill-height">
      <v-row align="center" justify="center">
        <v-col cols="auto">
          <div>
            <div class="upgrade-card" data-cy="upgrade_card">
              <div :class="['upgrade-card__hue mb-4', colorClass]" />
              <section class="pa-8">
                <div class="text-h5 font-weight-bold mb-6">
                  {{ t('business.billing.seats.add') }}
                </div>
                <skr-alert type="warning" class="mb-0">
                  <i18n-t keypath="business.add_seats.disclaimer.charged_directly">
                    <template #emphasis>
                      <span class="font-weight-bold">{{ t('business.add_seats.disclaimer.emphasis') }}</span>
                    </template>
                  </i18n-t>
                </skr-alert>
              </section>
              <v-divider />
              <section class="d-flex align-start px-8 py-6">
                <v-icon color="grey-darker">{{ icons.sofa }}</v-icon>
                <div class="flex-grow-1 ml-4">
                  <div class="d-flex align-start justify-space-between">
                    <div>
                      <div>
                        <span class="font-weight-bold">{{ t('business.upgrade.plan.seats') }}</span>
                        <br />
                        <span data-cy="seat_price_text">
                          {{
                            t('business.upgrade.plan.at_price', {
                              // Yearly prices need to be divided by 12 for comparison with monthly prices
                              price: formatCurrency(cycle === 'monthly' ? seatPrice : seatPrice / 12, currency),
                            })
                          }}
                        </span>
                        {{ ' ' }}
                        <span>{{ t('billing.billing_cycle.per_month') }}</span>
                      </div>
                    </div>
                    <number-increment
                      :incr-enabled="true"
                      :decr-enabled="selection.seats > 0"
                      :min="0"
                      @input="newSeats => (selection.seats = newSeats)"
                    />
                  </div>
                </div>
              </section>
              <v-divider />
              <section class="d-flex align-start px-8 py-6">
                <v-icon color="grey-darker">custom:signature</v-icon>
                <div class="flex-grow-1 ml-4 space-y-4">
                  <div
                    v-for="signaturePackage in signaturePackages"
                    :key="signaturePackage.id"
                    class="d-flex align-center justify-space-between"
                  >
                    <span>
                      <span class="font-weight-bold">{{ signaturePackage.name }}</span>
                      <span v-if="signaturePackage.count !== -1">
                        {{ ' ' }}
                        {{
                          cycle === 'yearly'
                            ? t('billing.billing_cycle.per_year')
                            : t('billing.billing_cycle.per_month')
                        }}
                      </span>
                    </span>
                    <span class="font-weight-bold">
                      {{ signaturePackage.count === -1 ? t('global.unlimited') : signaturePackage.count }}
                    </span>
                  </div>
                </div>
              </section>
              <v-divider />
              <section
                v-if="discountIsActive"
                data-cy="add_seats_coupon_info"
                class="pa-8 pb-0 coupon-applied-add-seats"
              >
                <v-banner v-if="isPreviewLoaded" two-line color="#E1FFF6" rounded>
                  <template #prepend>
                    <v-avatar size="16">
                      <v-icon :size="16">custom:coupon_tag</v-icon>
                    </v-avatar>
                  </template>
                  <strong>{{ discountPromoCode }}</strong> {{ couponAppliedText }}
                </v-banner>
                <coupon-subtotals
                  v-if="isPreviewLoaded && selection.discountPriceData"
                  :coupon-code="discountPromoCode"
                  :price-data="selection.discountPriceData"
                  :is-fetching-price="false"
                />
              </section>
              <section class="pa-8">
                <div class="text-center mb-6">
                  <i18n-t keypath="business.add_seats.due_money">
                    <template #price>
                      <dancing-dots-loader v-if="isCalculatingPrice" />
                      <span v-else class="font-weight-bold" data-cy="total_price_text">{{
                        selection.displayPrice
                      }}</span>
                    </template>
                  </i18n-t>
                </div>
                <v-btn
                  color="primary"
                  size="x-large"
                  block
                  data-cy="upgrade_button"
                  :disabled="selection.seats === 0 || isCalculatingPrice"
                  :loading="isLoading"
                  @click="submit"
                >
                  {{ submitAddSeatsButtonContent }}
                </v-btn>
              </section>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </overlay-base>
</template>

<style lang="sass" scoped>
$card-width: 460px

.toolbar
  position: fixed
  top: 0

.content
  padding-top: 90px

.upgrade-card
  color: $c-grey-darker
  width: $card-width
  max-width: 100%
  box-shadow: 0px 2px 5px rgba(40, 62, 102, 0.19)
  border-radius: 4px
  overflow: hidden
  background-color: $c-white
  margin-top: 4 * $bw
  margin-bottom: 4 * $bw
  &__hue
    height: 12px

.coupon-applied-add-seats :deep(.v-banner) .v-banner__wrapper
  border-bottom: none !important
  padding-top: 16px
  color: $c-skribbleu
  .v-banner__content
    align-items: flex-start
    font-size: 14px
    .v-banner__icon
      min-width: fit-content !important
      width: auto !important
      height: 100% !important
      border-radius: 0%
      margin-right: 12px
      padding-top: 4px
      .v-avatar
        border-radius: 0%
        overflow: visible
</style>
