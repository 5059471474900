<template>
  <business-profile-nav />
  <div class="mb-10">
    <skr-heading level="1" data-cy="biz_name">{{ companyName }}</skr-heading>
    <skr-heading level="2">{{ $t('business.profile.subtitle') }}</skr-heading>
  </div>
  <business-id-copy :id="businessId" />
  <p class="mb-4">
    <strong>{{ $t('business.profile.company.card_label') }}</strong>
  </p>
  <business-info-edit :params="{ name: true, phone: true }" />
  <version-indicator />
  <template v-if="showSupportContactForm">
    <strong class="d-block my-4">{{ $t('business.profile.support_contact.card_label') }}</strong>
    <support-contact-edit />
  </template>
</template>

<script lang="ts">
import BusinessIdCopy from '@/components/business/BusinessIdCopy.vue'
import BusinessInfoEdit from '@/components/business/BusinessInfoEdit.vue'
import SupportContactEdit from '@/components/business/SupportContactEdit.vue'
import BusinessProfileNav from '@/components/in-page-nav/BusinessProfileNav.vue'
import SkrHeading from '@/components/SkrHeading.vue'
import VersionIndicator from '@/components/VersionIndicator.vue'

export default defineComponent({
  components: {
    SkrHeading,
    BusinessIdCopy,
    BusinessProfileNav,
    BusinessInfoEdit,
    VersionIndicator,
    SupportContactEdit,
  },
  setup() {
    definePageMeta({
      layout: 'admin',
    })

    const businessStore = useBusinessStore()

    const { activePricePlanId } = useUserPlanInfo()

    const companyName = computed(() => businessStore.name)
    const businessId = computed(() => businessStore.id)

    const showSupportContactForm = computed(() => {
      return (
        activePricePlanId.value === 'ent_v2' ||
        activePricePlanId.value === 'TRIAL_V1' ||
        activePricePlanId.value === 'TEAM_V1' ||
        activePricePlanId.value === 'PRO_V1' ||
        activePricePlanId.value === 'SCALE'
      )
    })

    return {
      companyName,
      businessId,
      showSupportContactForm,
    }
  },
  head() {
    return {
      title: 'Business - Profile',
    }
  },
})
</script>
