<template>
  <!--
    TODO: This and MainNav.vue are very similar and should be
    refactored for less duplication.
    Maybe even consider MainNavBusiness.vue and MiniNavBusiness.vue too.
  -->

  <!--
    Note: the mini nav gets a simulated clipped applied by styling through
    the has-alert class on the application tag. We just add the margin-top
    and the height with important to overwrite the inline styling set by
    Vuetify.

    Adding the clipped attribute thus has no effect on this element.
  -->
  <v-navigation-drawer floating class="mini-nav" rail rail-width="90" :mobile-breakpoint="600">
    <div class="d-flex flex-column | mini-nav__flex">
      <v-app-bar-nav-icon :ripple="false" class="mini-nav__trigger" @click.stop="$emit('clicked')">
        <v-icon size="x-large">custom:menu</v-icon>
      </v-app-bar-nav-icon>
      <v-list class="py-0" nav>
        <v-tooltip content-class="text-center bg-info" location="right" max-width="207">
          <template #activator="{ props }">
            <div v-on="isNewFreePlan ? props : {}">
              <v-list-item
                :disabled="isNewFreePlan"
                :ripple="false"
                color="primary"
                class="mini-nav__new"
                data-cy="create_doc_button"
                exact
                :to="{ name: 'upload' }"
              >
                <v-list-item-action class="align-center flex-column my-3">
                  <v-icon size="40" :color="isNewFreePlan ? '#D8D8D8' : 'primary'">custom:new_btn_desktop</v-icon>
                  <span class="mini-nav__label">
                    {{ $t('global.navigation.new') }}
                  </span>
                </v-list-item-action>
              </v-list-item>
            </div>
          </template>
          <span>{{ $t('global.navigation.tooltip.upload_blocked') }}</span>
        </v-tooltip>
        <v-list-item :ripple="false" :active="route.name === 'index'" color="primary" :to="{ name: 'index' }">
          <v-list-item-action class="align-center flex-column my-3">
            <div class="mini-nav__border" />
            <v-icon>custom:folder_user</v-icon>
            <span class="mini-nav__label">
              {{ $t('global.navigation.documents') }}
            </span>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-list class="mt-auto py-0" nav>
        <v-menu
          location="end center"
          content-class="mini-nav__help-overview"
          open-delay="150"
          open-on-hover
          :close-on-content-click="true"
        >
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              :ripple="false"
              color="primary"
              exact
              data-cy="userHelpIconMiniNav"
              @click="clickHelpIcon"
            >
              <v-list-item-action class="align-center flex-column my-3">
                <v-icon>custom:c_question</v-icon>
                <span class="mini-nav__label">{{ $t('global.navigation.help_center') }}</span>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list nav class="pa-1">
            <v-list-item :href="$t('global.navigation.helpcenter.link')" target="_blank" rel="noopener">
              <template #prepend>
                <v-icon>custom:help_center</v-icon>
              </template>
              <v-list-item-title>
                {{ $t('global.navigation.helpcenter.label') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item :href="$t('global.navigation.tutorials.link')" target="_blank" rel="noopener">
              <template #prepend>
                <v-icon>custom:video_tutorials</v-icon>
              </template>
              <v-list-item-title>
                {{ $t('global.navigation.tutorials.label') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item :href="getEmailContactLink" target="_blank" rel="noopener" data-cy="userFormContactMiniNav">
              <template #prepend>
                <v-icon>custom:contact_email</v-icon>
              </template>
              <v-list-item-title>
                {{
                  supportContact.email
                    ? $t('global.navigation.internal_support_contact.mail.label')
                    : $t('global.navigation.contact.label')
                }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item :href="getSupportContactLink" target="_blank" rel="noopener" data-cy="userCallSupportMiniNav">
              <template #prepend>
                <v-icon>custom:support_hotline</v-icon>
              </template>
              <v-list-item-title>
                {{
                  supportContact.phone
                    ? $t('global.navigation.internal_support_contact.phone.label')
                    : $t('global.navigation.call.label')
                }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ supportContact.phone ?? $t('global.skribble_support_phone_number') }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-list-item
          v-if="userIsAdmin"
          :ripple="false"
          color="primary"
          exact
          :to="{ name: goToAdmin }"
          data-cy="businessButton"
        >
          <v-list-item-action class="align-center flex-column my-3">
            <v-icon>custom:business_account</v-icon>
            <span class="mini-nav__label">
              {{ $t('global.navigation.admin_area') }}
            </span>
          </v-list-item-action>
        </v-list-item>

        <v-list class="mt-auto py-0" nav>
          <v-menu location="end center" open-delay="150" open-on-hover :close-on-content-click="true">
            <template #activator="{ props }">
              <v-list-item
                :ripple="false"
                color="primary"
                class="mini-nav__avatar"
                :to="$vuetify.display.mdAndUp ? { name: 'profile' } : $route"
                data-cy="profileButton"
                v-bind="props"
              >
                <v-list-item-action class="align-center flex-column my-3">
                  <div class="mini-nav__border" />
                  <avatar />
                  <span class="mini-nav__label">{{ $t('global.profile.profile') }}</span>
                </v-list-item-action>
              </v-list-item>
            </template>
            <profile-nav popover />
          </v-menu>
        </v-list>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Avatar from '@/components/Avatar.vue'
import ProfileNav from '@/components/in-page-nav/ProfileNav.vue'

export default defineComponent({
  name: 'MiniNav',
  components: {
    Avatar,
    ProfileNav,
  },
  setup() {
    const businessStore = useBusinessStore()
    const { isBusinessAdmin } = storeToRefs(useUserStore())

    const route = useRoute()

    const { activePricePlanId, isNewFreePlan } = useUserPlanInfo()
    const systemStore = useSystemStore()
    const platform = computed(() => systemStore.platform || 'CH')
    const supportContact = computed(() => businessStore.settings.supportContact)
    const { t } = useI18n()

    const getEmailContactLink = computed(() => {
      return supportContact.value?.email
        ? `mailto:${supportContact.value.email}`
        : platform.value === 'EU'
          ? t('global.navigation.contact.link_form_de')
          : t('global.navigation.contact.link')
    })

    const getSupportContactLink = computed(() => {
      return supportContact.value?.phone
        ? `tel:${supportContact.value.phone}`
        : platform.value === 'EU'
          ? t('global.navigation.call.call_support_form_de')
          : t('global.navigation.call.call_support_form')
    })

    const goToAdmin = computed(() => {
      if (['TRIAL', 'TEAM', 'PRO', 'SCALE'].some(plan => activePricePlanId.value?.startsWith(plan))) {
        return 'business-dashboard'
      } else if (activePricePlanId.value?.startsWith('INDIVIDUAL')) {
        return 'business-billing-plan'
      } else {
        return 'business-members'
      }
    })

    return {
      userIsAdmin: isBusinessAdmin,
      route,
      isNewFreePlan,
      activePricePlanId,
      supportContact,
      platform,
      getEmailContactLink,
      getSupportContactLink,
      goToAdmin,
    }
  },
  methods: {
    clickHelpIcon(): void {
      // Do not open link on smaller screens to allow touchscreens to hover
      if (this.$vuetify.display.mdAndUp) {
        window.open(this.$t('global.navigation.helpcenter.link'))
      }
    },
  },
})
</script>

<style lang="sass">
.mini-nav
  padding-bottom: 8px
  font-size: 0.75rem
  font-weight: bold

  .v-list--nav
    padding: 0px

  .v-list-item
    padding-left: 0
    padding-right: 0

  .avatar
    font-size: 0.875rem

  &:not(.business)
    .v-list-item
      &.v-theme--light
        +mini-nav-item-hover
        &:hover
          background: transparent
          color: $c-primary !important
          span
            color: $c-primary !important
        &.mini-nav__new
          &:before
            top: 9px
            width: 46px
            height: 46px
          &:not(.v-list-item--disabled) span
            color: $c-primary
          .v-list-item--disabled span
            color: #D8D8D8
      &__action:first-child
        margin-left: 0
        margin-right: 0

      &__overlay
        +mini-nav-item-hover
        top: calc(50% - 31px)
        left: 50%
        transform: translateX(-50%)
        border-radius: 50%
        width: 40px
        height: 40px
        background-color: transparent

      &:hover
        .v-list-item__overlay
          background-color: $c-primary

      &--active
        &:before
          // Don't show the background when active.
          background-color: transparent
        .mini-nav__border
          &:before
            top: 7px
            bottom: 7px
            left: 0
            transform: none
            width: 4px
            opacity: 1
            border-radius: 0 2px 2px 0
            position: absolute
            color: inherit
            background-color: currentColor
            content: ""
            transition: .3s cubic-bezier(.25,.8,.5,1)

  &__flex
    height: 100%

  &__trigger
    margin-bottom: 20px
    width: 100% !important
    height: 90px !important
    border-radius: 0
    .v-btn__overlay
      left: 50%
      top: 50%
      transform: translate(-50%,-50%)
      width: 46px
      height: 46px
      border-radius: 50%
    span
      i
        height: 26px !important
        font-size: 34px !important
        width: 26px !important
    &:hover
      span
        color: $c-primary

  &__label
    margin-top: 9px
    padding-left: 4px
    padding-right: 4px
    text-align: center
    white-space: normal
    line-height: 1.2

  &__signing-status
    position: absolute
    right: 18px
    top: -2px
    width: 16px
    height: 16px

  &__avatar
    &:before
      display: none

  &__help-overview

    .v-list-item
      &__content
        width: min-content
        font-size: 0.75rem
        color: $c-grey-dark

        &:hover
          color: $c-primary !important

        .v-list-item-title
          font-weight: 700
          font-size: .875rem

    .v-list-item__sub-title, .v-list-item__action > span > svg
      font-size: 0.75rem
      color: $c-grey-dark
      &:hover
        color: $c-primary !important
</style>
