<script lang="ts" setup>
import debounce from 'debounce'
import type { Ref } from 'vue'

import SpinnerSmall from '@/components/SpinnerSmall.vue'
import type { VisualSignatureData } from '@/stores/visualSignature.ts'

const props = defineProps<{
  width: number
  height: number
  parameters: Partial<VisualSignatureData & VisualSignatureDetails>
  id?: string // The optional placement ID
  owned?: boolean
  placeholder?: boolean // a.k.a. pre-placed
  draggable?: boolean
  removable?: boolean
  clustered?: boolean
  disabled?: boolean
  additionalParams?: Record<string, string>
}>()

const emit = defineEmits<{
  (event: 'remove'): void
  (event: 'zoom', element: HTMLElement): void
}>()

const visualSignatureStore = useVisualSignatureStore()

const pageNumber = inject<number | null>('pageNumber', null)

const isPlaced = computed(() => Boolean(pageNumber))

const qualityColor = computed(() => {
  switch (props.parameters.quality) {
    case 'aes':
      return '#006FE6'
    case 'qes':
      return '#00A671'
    case 'part11':
      return '#8120FD'
    case 'demo':
      return '#FF7803'
    case 'ses':
    default:
      return '#20ABFD'
  }
})

const imageElement = ref<HTMLImageElement | null>(null)

const itemElement = ref<HTMLElement | null>(null)

const { width: itemWidth, height: itemHeight } = useElementSize(itemElement)

// If this component is used in a zoomable container, the container should provide a zoom reference
const zoomScale = inject<Ref<number>>('zoomScale', ref(1))

const adjustmentFactor = computed(() => (props.id ? 1 / zoomScale.value : 1))

const isZoomable = computed(() => {
  if (!isPlaced.value || !zoomScale.value) return false

  if (!itemWidth.value || !itemHeight.value) return false

  const defaultDimensions = visualSignatureStore.defaultSignatureDimensions

  const widthRatio = itemWidth.value / (defaultDimensions.width / zoomScale.value)
  const heightRatio = itemHeight.value / (defaultDimensions.height / zoomScale.value)

  return Math.max(widthRatio, heightRatio) < 0.5
})

const dragImageElement = computed<HTMLCanvasElement | null>(() => {
  const image = imageElement.value
  if (!image) return null

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) return null

  canvas.width = itemWidth.value / adjustmentFactor.value
  canvas.height = itemHeight.value / adjustmentFactor.value
  ctx.imageSmoothingEnabled = true
  ctx.imageSmoothingQuality = 'high'
  ctx.fillStyle = '#FFFFFF'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
  ctx.strokeStyle = qualityColor.value
  ctx.lineWidth = 1
  ctx.strokeRect(0, 0, canvas.width, canvas.height)

  Object.assign(canvas.style, {
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: -1,
    cursor: 'grabbing',
  })

  return canvas
})

const dragData = computed(() => {
  return {
    type: 'signature',
    id: props.id,
    parameters: props.parameters,
    width: itemWidth.value ?? props.width,
    height: itemHeight.value ?? props.height,
    pageNumber,
    additionalParams: props.additionalParams,
  }
})

const isDraggable = computed(() => props.draggable && !isLoading.value && !isZoomable.value)

const { isDragging } = useDragDrop(itemElement, isDraggable, dragImageElement, dragData)

const isPlaceholder = computed(() => Boolean(props.placeholder))

const isLoading = ref(true)

const srcUrl = ref('')

const alt = computed(() => {
  return `${props.parameters.name} signature image`
})

const onImgLoaded = () => {
  if (srcUrl.value) {
    URL.revokeObjectURL(srcUrl.value)
  }
}

const getCustomImage = async () => {
  if (props.parameters.id) {
    return await visualSignatureStore.getPredefinedVisualSignature(props.parameters.id, props.parameters)
  } else if (props.parameters.image) {
    return await visualSignatureStore.getVisualSignature(props.parameters, props.parameters.image)
  } else {
    return await visualSignatureStore.getVisualSignature(props.parameters)
  }
}

const updateImage = debounce(async () => {
  isLoading.value = true
  const blob = props.owned
    ? await getCustomImage()
    : await visualSignatureStore.getVisualSignaturePreview(props.parameters, props.parameters.image)
  srcUrl.value = URL.createObjectURL(blob)
  isLoading.value = false
}, 300)

watch(
  () => props.parameters,
  (newParams, oldParams) => {
    if (Object.keys(newParams).some(key => newParams[key] !== oldParams[key])) {
      void updateImage()
    }
  },
  { deep: true }
)

onMounted(() => {
  void updateImage()
  updateImage.flush()
})

defineExpose({
  updateImage,
})
</script>

<template>
  <div
    ref="itemElement"
    data-cy="signature_image"
    class="item"
    :class="{
      'item--placeholder': isPlaceholder,
      'item--placed': isPlaced,
      'item--draggable': draggable,
      'item--disabled': isDragging || disabled,
      'item--removable': removable,
      'item--zoomable': isZoomable,
    }"
    :draggable="draggable && !isLoading && !isZoomable"
  >
    <v-fade-transition mode="out-in" :duration="150">
      <svg v-if="isLoading" class="item__skeleton" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 140">
        <defs>
          <linearGradient id="signature-shimmer-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stop-color="rgba(255, 255, 255, 0)" />
            <stop offset="50%" stop-color="rgba(255, 255, 255, 0.3)" />
            <stop offset="100%" stop-color="rgba(255, 255, 255, 0)" />
          </linearGradient>
        </defs>
        <circle cx="45" cy="45" r="25" class="bone" />
        <rect x="90" y="20" width="190" height="15" rx="7.5" class="bone" />
        <rect x="90" y="42.5" width="100" height="15" rx="7.5" class="bone" />
        <rect x="90" y="65" width="130" height="15" rx="7.5" class="bone" />
        <rect x="20" y="105" width="260" height="15" rx="7.5" class="bone" />
        <rect x="-300" y="0" width="300" height="140" class="shimmer">
          <animate attributeName="x" from="-300" to="300" dur="1.5s" repeatCount="indefinite" />
        </rect>
      </svg>
      <div v-else class="item__content">
        <img ref="imageElement" :src="srcUrl" :alt="alt" @load="onImgLoaded" />
      </div>
    </v-fade-transition>
    <v-fade-transition>
      <div v-if="clustered" />
      <div v-else-if="isZoomable && isLoading" class="item__action item__action--zoom item__action--loading">
        <spinner-small class="spinner" />
      </div>
      <div
        v-else-if="isZoomable"
        class="item__action item__action--zoom"
        @click="itemElement && emit('zoom', itemElement)"
      >
        <v-icon size="16" color="white">custom:zoom</v-icon>
      </div>
      <div v-else-if="removable && !isDragging" class="item__action item__action--remove" @click="emit('remove')">
        <v-icon size="13" color="white">custom:close_x</v-icon>
      </div>
    </v-fade-transition>
  </div>
</template>

<style lang="sass" scoped>
@function getAdjustedSize($size)
  @return calc(#{$size} * v-bind('adjustmentFactor'))

.item
  aspect-ratio: 300 / 140
  background: $c-white
  user-select: none
  &__skeleton
    display: block
    cursor: wait
    .bone
      fill: rgba(0, 0, 0, 0.12)
    .shimmer
      fill: url(#signature-shimmer-gradient)
  img
    pointer-events: none
    width: 100%
    height: 100%
    object-fit: contain
    object-position: center
    display: block
  &--placed
    background: rgba(255,255,255,0.5)
    box-shadow: 0px getAdjustedSize(3px) getAdjustedSize(3px) getAdjustedSize(-2px) rgba(0, 0, 0, 0.2),0px getAdjustedSize(3px) getAdjustedSize(4px) 0px rgba(0, 0, 0, 0.14), 0px getAdjustedSize(1px) getAdjustedSize(8px) 0px rgba(0, 0, 0, 0.12)
    .item__content
      isolation: isolate
      &::after
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        backdrop-filter: blur(getAdjustedSize(2px))
        z-index: -1
  &--placeholder
    background: repeating-linear-gradient(0deg, rgba($c-grey-light, 0.75), rgba($c-grey-light, 0.75) getAdjustedSize(10px), rgba($c-grey-fine, 0.75) getAdjustedSize(10px), rgba($c-grey-fine, 0.75) getAdjustedSize(20px))
    border-radius: getAdjustedSize(4px)
    box-shadow: none
    .item__content
      &::after
        backdrop-filter: blur(getAdjustedSize(1px))
    img
      opacity: 0.5
  &--draggable
    cursor: grab
  &--disabled
    opacity: 0.5
    filter: grayscale(0.75)
  &--removable
    position: relative
    &.item--disabled
      opacity: 0
  &--zoomable
    // Hide content that should only be shown when zoomed in
    background: transparent
    box-shadow: unset !important
    cursor: default
    > *:not(.item__action)
      transition: opacity 0.3s ease
      opacity: 0
      pointer-events: none
  &__action
    position: absolute
    display: grid
    place-items: center
    z-index: 20
    top: -15px
    right: -15px
    width: 30px
    height: 30px
    border-radius: 50%
    cursor: pointer
    font-size: 1.75rem
    color: white
    transform: scale(v-bind('adjustmentFactor'))
    transform-origin: center center !important
    background-color: v-bind('qualityColor')
    &::after
      content: ''
      z-index: 1
      pointer-events: none
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      border-radius: 50%
      background-color: currentColor
      opacity: 0
      transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1)
    &:hover
      &::after
        opacity: 0.12
    &--zoom
      top: -20px
      right: -20px
      width: 40px
      height: 40px
    &--loading
      > .spinner
        display: flex
        width: 20px
        height: 20px
        :deep(.path)
          stroke: white
</style>
