export const useInvitationStore = defineStore('invitation', () => {
  const invitationSignatureRequests = ref<SignatureRequestData[]>([])

  const draftDefaults = {
    signatureRequest: {
      id: '',
      title: '',
      message: '',
      documentId: '',
      business: '',
      quality: '',
      signingUrl: '',
      statusOverall: '',
      attachments: [],
      attachOnSuccess: [],
      signatures: [],
      ccEmailAddresses: [],
      owner: '',
      readAccess: [],
      writeAccess: [],
      createdAt: '',
      updatedAt: '',
      expirationDate: '',
      legislation: '',
    },
    options: {
      singleSigner: 1,
      userIsSigning: false,
      signingOrderEnabled: false,
      notifySigners: true,
    },
    order: {
      invitees: {},
      ordering: [],
    },
  }

  const activeDraft = ref<SignatureRequestInvitationDraft>(structuredClone(draftDefaults))

  const resetDraft = () => {
    activeDraft.value = structuredClone(draftDefaults)
  }

  return { activeDraft, invitationSignatureRequests, resetDraft }
})
