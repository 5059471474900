<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import InputTan from '@/components/inputs/TanInput.vue'

withDefaults(
  defineProps<{
    loading: boolean
  }>(),
  {
    loading: false,
  }
)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['document/DialogTanEntry']['contextData']): void
}>()

const { t } = useI18n()

const tan = ref('')

const validTan = computed((): boolean => {
  const isOnlyDigits = /^\d+$/.test(tan.value)
  const isSixCharactersLong = tan.value.length === 6
  return isOnlyDigits && isSixCharactersLong
})

const { tanMailData } = useSignatureHelpers()
const { directTanRepository } = useApi()

const isSending = ref(false)

const { createSnackbar } = useWidgetStore()

const resend = async () => {
  if (isSending.value) return

  isSending.value = true

  try {
    await directTanRepository.resend(
      tanMailData.value.subject ?? '',
      window.location.href,
      tanMailData.value.companyBranding
    )

    createSnackbar({
      message: t('viewer.tan.expired.tan_flow.feedback.success'),
    })
  } catch {
    createSnackbar({
      message: t('global.general_error'),
    })
  }

  isSending.value = false
}

const confirm = (): void => {
  if (validTan.value) emit('action', { tan: tan.value })
}
</script>

<template>
  <base-dialog
    data-cy="tan_entry_dialog"
    max-width="640"
    :title="t('viewer.tan.overlay.title')"
    :disabled="!validTan"
    :loading="loading"
    :action-text="t('viewer.tan.overlay.confirm_sign')"
    :cancel-text="t('viewer.tan.overlay.cancel')"
    @action="confirm"
    @close="emit('close')"
  >
    <p class="whitespace-pre-wrap">{{ t('viewer.tan.overlay.lead_text') }}</p>
    <p class="mt-8 mb-4 text-center">
      <strong>{{ t('viewer.tan.overlay.enter_code') }}</strong>
    </p>
    <input-tan v-model="tan" @enter="confirm" />
    <p class="mb-4 text-center">
      <small>
        <strong>{{ t('viewer.tan.overlay.mail_sent.help_text') }}</strong>
        {{ ' ' }}
        <v-fade-transition mode="out-in">
          <v-progress-circular v-if="isSending" class="ml-4" size="16" width="2" color="text" indeterminate />
          <a v-else class="cursor-pointer text-decoration-underline" @click="resend">{{
            t('viewer.tan.overlay.resend')
          }}</a>
        </v-fade-transition>
      </small>
    </p>
  </base-dialog>
</template>
