<script lang="ts" setup>
import { VAppBar, VToolbar, VAppBarTitle, VToolbarTitle } from 'vuetify/components'

const props = defineProps<{
  title?: string
  app?: boolean
  isCloseable?: boolean
}>()

const emit = defineEmits<{
  (event: 'close'): void
}>()

const { srBrandingLogo } = useSignatureHelpers()

const mainComponent = computed(() => {
  return props.app ? VAppBar : VToolbar
})

const titleComponent = computed(() => {
  return props.app ? VAppBarTitle : VToolbarTitle
})
</script>

<template>
  <component :is="mainComponent" class="toolbar" location="top" flat height="90">
    <v-btn v-if="isCloseable" data-cy="toolbar_base_close_btn" class="close-btn" variant="text" @click="emit('close')">
      <v-icon size="24">custom:c_close</v-icon>
    </v-btn>
    <img v-else-if="srBrandingLogo" class="logo" :src="srBrandingLogo" alt="Logo" />
    <nuxt-img v-else class="logo" width="54" height="30" src="/img/logo-skribble.svg" alt="Skribble" />
    <component :is="titleComponent" class="toolbar__title">
      <template v-if="title">
        {{ title }}
      </template>
      <slot v-else name="title" />
    </component>
    <template v-if="$slots.options">
      <v-spacer />
      <div class="toolbar__options">
        <slot name="options" />
      </div>
    </template>
  </component>
</template>

<style lang="sass" scoped>

.toolbar
  z-index: 1
  background-color: $c-white
  border-bottom: 1px solid $c-border
  &__title
    font-weight: 700
    font-size: 1rem
    margin-left: 5 * $bw
  .logo
    width: 58px
    height: 100%
    padding-left: 16px
    padding-right: 15px
    object-fit: contain
    border-right: 1px solid $c-border
  .close-btn
    width: 90px
    height: 100%
    border-right: 1px solid $c-border
    margin: 0

  :deep(.v-toolbar__content)
    padding: 0
    color: $c-text-alt
</style>
