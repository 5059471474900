<template>
  <v-container class="fill-height">
    <v-row>
      <!-- Show form to enter idenitity details. -->
      <enter-mobile-form
        v-if="viewState === ViewState.initial"
        :loading="loadingSaveButton"
        @submit="storeData"
        @back="goBack"
      />

      <!-- The user started on the signature standards page. No target quality
        is requested. -->

      <!-- Show the same screen with different text for AES. Heads-up: SES is
        instantly redirected to the legislation page. -->
      <completed-update-aes
        v-if="viewState === ViewState.noRequestHasAES"
        :skip-mobile-id="skipMobileId"
        @back="goBack"
      />

      <!-- This screen is shown to users with quality QES -->
      <completed-update-zertes v-if="viewState === ViewState.noRequestHasZertes" @back="goBack" />

      <completed-update-eidas v-if="viewState === ViewState.noRequestHasEidas" />

      <!-- After the user entered identity details, these screens are shown when
      the user can sign with the requested quality or higher. It's the success
      screens. -->

      <!-- The user started in the viewer. A target quality is requested -->
      <requested-updated-completed
        v-if="viewState === ViewState.hasRequestedQES || viewState === ViewState.hasRequestedAES"
        @back="goBack"
      />
    </v-row>
  </v-container>
</template>

<script lang="ts">
import CompletedUpdateAes from '@/components/pages/profile/signature-standards/update/CompletedUpdateAes.vue'
import CompletedUpdateEidas from '@/components/pages/profile/signature-standards/update/CompletedUpdateEidas.vue'
import CompletedUpdateZertes from '@/components/pages/profile/signature-standards/update/CompletedUpdateZertes.vue'
import EnterMobileForm from '@/components/pages/profile/signature-standards/update/EnterMobileForm.vue'
import RequestedUpdatedCompleted from '@/components/pages/profile/signature-standards/update/RequestedUpdatedCompleted.vue'

enum ViewState {
  initial,
  noRequestHasSES,
  noRequestHasZertes,
  noRequestHasEidas,
  noRequestHasAES,
  hasRequestedQES,
  hasRequestedAES,
  hasQESRequestZertes,
  hasQESRequestEidas,
  requestQESAndZertes,
  requestQESAndEidas,
  requestQESSkipMobileIdEidas,
  requestQESSkipMobileIdZertes,
  none,
}

export default defineComponent({
  components: {
    EnterMobileForm,
    CompletedUpdateZertes,
    CompletedUpdateEidas,
    CompletedUpdateAes,
    RequestedUpdatedCompleted,
  },
  setup() {
    definePageMeta({
      layout: 'simple',
    })

    const userStore = useUserStore()
    const { signatureQualities, highestSignatureQuality } = storeToRefs(userStore)

    const widgetStore = useWidgetStore()

    const { userRepository } = useApi()

    return {
      signatureQualities,
      highestSignatureQuality,
      storeProfileData: userRepository.update,
      getUser: userStore.fetchUser,
      createSnackbar: widgetStore.createSnackbar,
    }
  },
  data() {
    return {
      ViewState,
      loadingSaveButton: false,
      showStatus: false,
      updateCompleted: false,
      skipMobileId: false,
      redirect: {
        [ViewState.noRequestHasSES]: {
          path: 'profile-signature-standards-mobile-id',
        },
        [ViewState.hasQESRequestZertes]: {
          path: 'profile-signature-standards-zertes',
        },
        [ViewState.hasQESRequestEidas]: {
          path: 'profile-signature-standards-eidas',
        },
        [ViewState.requestQESAndZertes]: {
          path: 'profile-signature-standards-mobile-id',
          legislation: 'zertes',
        },
        [ViewState.requestQESAndEidas]: {
          path: 'profile-signature-standards-mobile-id',
          legislation: 'eidas',
        },
        [ViewState.requestQESSkipMobileIdEidas]: {
          path: 'profile-signature-standards-eidas',
        },
        [ViewState.requestQESSkipMobileIdZertes]: {
          path: 'profile-signature-standards-zertes',
        },
      },
    }
  },
  head() {
    return {
      title: 'Profile - Signature Standards - Update',
    }
  },
  computed: {
    cameFromViewer(): boolean {
      return !!this.$route.query.sr
    },
    requestedQuality(): string {
      return !Array.isArray(this.$route.query.q) ? this.$route.query.q || '' : ''
    },
    requestedLegislation(): 'zertes' | 'eidas' | '' {
      return !Array.isArray(this.$route.query.l) ? (this.$route.query.l as 'zertes' | 'eidas') || '' : ''
    },
    stateFromQuality(): ViewState {
      // branch - no request (quality), still has ses
      // note: this state is unlikely
      if (this.highestSignatureQuality === 'ses') {
        return ViewState.noRequestHasSES // redirect
      }

      if (this.highestSignatureQuality === 'aes') {
        // branch - no request (quality), now has aes
        return ViewState.noRequestHasAES
      }

      // user is qes
      const hasZertes = this.signatureQualities.qes?.zertes

      if (hasZertes) {
        // branch - no request (quality), now has qes w/ zertes
        return ViewState.noRequestHasZertes
      }

      const hasEidas = this.signatureQualities.qes?.eidas

      if (hasEidas) {
        // branch - no request (quality), now has qes w/ eidas
        return ViewState.noRequestHasEidas
      }

      // we can't reach here
      throw new Error('Impossible state')
    },
    viewState(): ViewState {
      // branch - initial (page loaded)
      if (!this.updateCompleted) {
        return ViewState.initial
      }

      // no target quality level requested
      if (!this.requestedQuality) {
        // no request branches
        return this.stateFromQuality
      }

      // With target/requested quality
      // Show different success screens on this page
      const achievedRequestedQuality = this.requestedQuality === this.highestSignatureQuality
      const qesInfo = this.signatureQualities.qes
      const hasRequestedLegislation =
        qesInfo && this.requestedLegislation ? this.requestedLegislation in qesInfo : false

      // branch - has request for qes, now has qes
      if (achievedRequestedQuality && hasRequestedLegislation) {
        return ViewState.hasRequestedQES
      }

      const userQualityIsAESOrHigher = this.highestSignatureQuality === 'qes' || this.highestSignatureQuality === 'aes'
      const requestedQualityIsAES = this.requestedQuality === 'aes'

      // branch - has request aes, now has (at least) aes
      if (userQualityIsAESOrHigher && requestedQualityIsAES) {
        return ViewState.hasRequestedAES
      }

      // user doesn't have the legislation they wanted (yet)

      if (this.requestedLegislation === 'zertes') {
        if (this.highestSignatureQuality === 'qes') {
          // branch - has qes, but doesn't have requested zertes legislation
          return ViewState.hasQESRequestZertes // redirect
        } else if (this.skipMobileId) {
          return ViewState.requestQESSkipMobileIdZertes // redirect
        } else {
          // branch - requesting qes and zertes legislation, has neither
          return ViewState.requestQESAndZertes // redirect
        }
      }

      if (this.requestedLegislation === 'eidas') {
        if (this.highestSignatureQuality === 'qes') {
          // branch - has qes, but doesn't have requested eidas legislation
          return ViewState.hasQESRequestEidas // redirect
        } else if (this.skipMobileId) {
          return ViewState.requestQESSkipMobileIdEidas // redirect
        } else {
          // branch - requesting qes and eidas legislation, has neither
          return ViewState.requestQESAndEidas // redirect
        }
      }

      return ViewState.none
    },
  },
  watch: {
    viewState() {
      if (this.redirect[this.viewState])
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.goTo(this.redirect[this.viewState].path, this.redirect[this.viewState].legislation)
    },
  },
  methods: {
    goTo(name: string, legislation?: 'zertes' | 'eidas'): void {
      const obj: {
        name: string
        query?: { sr?: string | (string | null)[]; l?: string }
      } = { name }
      const srid = this.$route.query.sr
      if (srid || legislation) {
        obj.query = {}
        if (srid) obj.query.sr = srid
        if (legislation) obj.query.l = legislation
      }
      navigateTo(obj)
    },
    goBack() {
      // Viewer of a logged in user
      if (this.cameFromViewer)
        return navigateTo({
          name: 'view-srid',
          params: { srid: this.$route.query.sr },
        })
      navigateTo({ name: 'profile-signature-standards' })
    },
    async storeData(phoneValue: string) {
      this.loadingSaveButton = true

      // We do not want to show the mobile ID screen to Austrian users
      this.skipMobileId = phoneValue.startsWith('43')

      const userData = {
        attributes: {
          mobile: [phoneValue],
        },
      }

      try {
        await this.storeProfileData(userData)
        // Reload user to get latest signature qualities
        await this.getUser()
        this.loadingSaveButton = false
        this.updateCompleted = true
      } catch {
        // Show generic error snackbar
        this.loadingSaveButton = false
        this.createSnackbar({
          message: this.$t('personal_data.user_save_error'),
        })
      }
    },
  },
})
</script>
