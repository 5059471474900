<script lang="ts" setup>
const businessStore = useBusinessStore()
const paymentStore = usePaymentStore()

const isShown = defineModel<boolean>()

const activate = async () => {
  await paymentStore.upgradeSubscription({
    type: 'SCALE',
    cycle: businessStore.currentPricePlan?.cycle === 'MONTHLY' ? 'monthly' : 'yearly',
  })
  await businessStore.getBusiness()
  await navigateTo({ name: 'business-billing-plan' })
}
</script>

<template>
  <v-dialog v-model="isShown" max-width="510" persistent>
    <v-card data-cy="activateScaleDialog">
      <v-card-title class="text-h5">
        {{ $t('dialog.business.activate_scale.title') }}
      </v-card-title>
      <v-card-text>
        <div class="pb-4">
          {{ $t('dialog.business.activate_scale.text') }}
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          class="ml-auto"
          size="x-large"
          color="info"
          data-cy="activateScaleBtn"
          variant="elevated"
          @click="activate"
        >
          {{ $t('dialog.business.activate_scale.btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
