<!-- eslint-disable @typescript-eslint/naming-convention -->
<template>
  <v-container class="pa-0">
    <v-row class="pb-10 flex-md-nowrap" align="center" justify="space-between">
      <v-col cols="12" md="auto" class="flex-shrink-1">
        <skr-heading level="1">{{ $t('business.members.domains.title') }}</skr-heading>
        <skr-heading level="2">{{ $t('business.members.domains.subtitle') }}</skr-heading>
      </v-col>
      <v-col cols="12" md="auto">
        <skr-button
          size="lg"
          data-cy="addDomainsButton"
          :block="$vuetify.display.smAndDown"
          @click="addDomainDialog = true"
        >
          {{ t('business.members.domains.add_domain') }}
        </skr-button>
      </v-col>
    </v-row>
  </v-container>
  <business-members-nav />
  <v-data-table
    sort-asc-icon="custom:arrow_up"
    sort-desc-icon="custom:arrow_down"
    :items-per-page-options="[5, 10, 25, 50]"
    :items-per-page-text="$t('global.rows_per_page')"
    :headers="domainHeaders"
    :items="domains"
    mobile-breakpoint="0"
  >
    <template v-if="domains.length < 6" #bottom></template>
    <template #no-data>
      {{ t('business.members.domains.no_domains') }}
    </template>
    <template #[`item.domain`]="{ item }">
      <strong>{{ item.domain }}</strong>
    </template>
    <template #[`item.status`]="{ item }">
      <skr-chip :color="statusColor(item.status.toUpperCase())" data-cy="domainStatus">{{
        statusCopy[item.status.toUpperCase()]
      }}</skr-chip>
    </template>
    <template #[`item.options`]="{ item }">
      <v-tooltip location="top" content-class="bg-error">
        <template #activator="{ props }">
          <v-btn
            variant="text"
            icon
            :ripple="false"
            color="error"
            v-bind="props"
            @click="showRemoveDomainDialog(item.domain)"
          >
            <v-icon color="text">custom:c_close</v-icon>
          </v-btn>
        </template>
        <span>{{ t('business.members.domains.table.remove_tooltip') }}</span>
      </v-tooltip>
    </template>
  </v-data-table>
  <i18n-t keypath="business.members.domains.help.text" tag="p" class="mt-8">
    <template #br> <br /><br /> </template>
    <template #link_text>
      <a :href="t('global.admin_guide_link_url')" target="_blank" rel="noopener">{{
        t('global.admin_guide_link_text')
      }}</a>
    </template>
  </i18n-t>

  <v-dialog v-model="addDomainDialog" width="500">
    <v-card>
      <v-card-title>
        {{ t('business.members.domains.add.dialog.title') }}
      </v-card-title>
      <v-card-text>
        <p class="mb-4">{{ t('business.members.domains.new.text') }}</p>
        <v-text-field
          v-model="domain"
          :label="`${t('business.members.domains.add.dialog.domain_label')} *`"
          prepend-inner-icon="custom:at"
          :rules="[validateDomains(domain)]"
          :hint="t('business.members.domains.add.dialog.hint')"
          name="domain"
          data-cy="domainTextField"
          variant="filled"
          :error="!domainCompliant"
          @update:model-value="updateDomainCompliant"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn v-if="!loading" size="x-large" color="info" variant="outlined" @click="addDomainDialog = false">
          {{ t('global.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          size="x-large"
          color="info"
          variant="elevated"
          :disabled="loading || !domainCompliant || !domain"
          :loading="loading"
          data-cy="addDomainButton"
          @click="addDomain"
        >
          {{ t('global.add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="removeDomainDialog" width="500">
    <v-card>
      <v-card-title class="bg-error text-white">
        {{ t('business.members.domains.remove.dialog.title') }}
      </v-card-title>
      <v-card-text>
        {{ t('business.members.domains.remove.dialog.text_upper') }}
        <div class="members-domains__removal-domain my-6">
          {{ removalDomain }}
        </div>
        <!-- We only show this info text for approved domains. -->
        <template v-if="hasBeenApproved(removalDomain)">
          {{ t('business.members.domains.remove.dialog.text_lower') }}
        </template>
        <skr-checkbox
          v-model="removeDomainConsent"
          :label="
            t('business.members.domains.remove.dialog.consent', {
              domain: removalDomain,
            })
          "
          color="error"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn v-if="!loading" size="x-large" color="error" variant="outlined" @click="removeDomainDialog = false">
          {{ t('global.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          size="x-large"
          color="error"
          variant="elevated"
          :disabled="loading || !removeDomainConsent"
          :loading="loading"
          @click="removeDomain()"
        >
          {{ t('business.members.domains.remove.dialog.remove') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import BusinessMembersNav from '@/components/in-page-nav/BusinessMembersNav.vue'
import SkrButton from '@/components/button/SkrButton.vue'
import SkrCheckbox from '@/components/SkrCheckbox.vue'
import SkrChip from '@/components/SkrChip.vue'
import SkrHeading from '@/components/SkrHeading.vue'

export default defineComponent({
  components: {
    SkrButton,
    SkrHeading,
    BusinessMembersNav,
    SkrChip,
    SkrCheckbox,
  },
  setup() {
    definePageMeta({
      layout: 'admin',
    })

    const { t } = useI18n()
    const { isDomain } = useValidation()
    const { domains } = storeToRefs(useBusinessStore())
    const businessStore = useBusinessStore()
    const widgetStore = useWidgetStore()

    const validateDomains = (domain: string): boolean | string => {
      return isDomain(domain) || String(t('global.domain_invalid'))
    }

    return { t, validateDomains, domains, businessStore, widgetStore, isDomain }
  },
  data() {
    return {
      addDomainDialog: false,
      removeDomainDialog: false,
      domain: '',
      removalDomain: '',
      removeDomainConsent: false,
      domainCompliant: false,
      loading: false,
      mainNavDrawer: null,
      pagination: {
        page: 0,
        sortBy: 'domain',
        rowsPerPage: 25,
        descending: false,
      },
    }
  },
  head() {
    return {
      title: 'Business - Members - Domains',
    }
  },
  computed: {
    statusCopy(): Record<string, string> {
      return {
        APPROVED: this.t('business.members.domains.table.approved'),
        REJECTED: this.t('business.members.domains.table.rejected'),
        REQUESTED: this.t('business.members.domains.table.requested'),
      }
    },
    domainHeaders(): {
      title: string
      value: string
      sortable: boolean
      align?: string
    }[] {
      return [
        {
          title: this.t('business.members.domains.table.header.domain'),
          value: 'domain',
          sortable: true,
        },
        {
          title: this.t('business.members.domains.table.header.status'),
          value: 'status',
          sortable: true,
        },
        {
          title: this.t('business.members.domains.table.header.remove'),
          value: 'options',
          sortable: false,
          align: 'right',
        },
      ]
    },
  },
  watch: {
    addDomainDialog(value) {
      // When closing the removalDomain dialog clear the domain to add
      if (!value) {
        this.domain = ''
      }
    },
    removeDomainDialog(value) {
      // When closing the removalDomain dialog uncheck the consent checkbox
      // and clear the domain to remove
      if (!value) {
        this.removeDomainConsent = false
        this.removalDomain = ''
      }
    },
  },
  methods: {
    statusColor(status: string) {
      switch (status) {
        case 'APPROVED':
          return 'success'
        case 'REQUESTED':
          return 'warning'
        case 'DECLINED':
          return 'error'
        default:
          return 'grey'
      }
    },
    async addDomain(): Promise<void> {
      this.loading = true
      try {
        await this.businessStore.addDomain(this.domain)
      } catch (_error) {
        const error = _error as { response?: { data?: { key?: string } } }
        const messages: Record<string, string> = {
          'business.members.msgs.user_not_admin': this.$t('business.members.domains.add.errors.user_not_admin'),
          'business.domains.msgs.invalid': this.$t('business.members.domains.add.errors.domain_invalid'),
          'business.domains.msgs.public_domain': this.$t('business.members.domains.add.errors.public_domain'),
          'business.domains.msgs.already_taken': this.$t('business.members.domains.add.errors.already_taken'),
          'business.admin.msgs.invalid': this.$t('business.members.domains.add.errors.domain_invalid'),
        }
        const errorKey = error.response?.data?.key || ''
        const message = errorKey in messages ? messages[errorKey] : this.$t('global.error.generic_support')

        this.widgetStore.createSnackbar({
          message,
        })
      } finally {
        this.loading = false
        this.addDomainDialog = false
      }
    },
    async removeDomain(): Promise<void> {
      this.loading = true
      await this.businessStore.removeDomain(this.removalDomain)
      this.loading = false
      this.removeDomainDialog = false
    },
    showRemoveDomainDialog(domain: string): void {
      this.removalDomain = domain
      this.removeDomainDialog = true
    },
    hasBeenApproved(domain: string): boolean {
      if (!this.domains) return false
      // Search for domain in business.domains and check if its status is 'approved'
      return this.domains.some(d => d.domain === domain && d.status.toUpperCase() === 'APPROVED')
    },
    updateDomainCompliant(): void {
      this.domainCompliant = this.isDomain(this.domain)
    },
  },
})
</script>
