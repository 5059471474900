<script lang="ts" setup>
const props = defineProps<{
  items: (NavigationItem | NavigationDivider)[]
}>()

const { xs } = useDisplay()

const navItems = computed(() => props.items.filter(item => !('divider' in item)) as NavigationItem[])
</script>

<template>
  <v-bottom-navigation v-if="items.length" color="primary" grow>
    <v-btn v-for="(item, i) in navItems" :key="`${item}-${i}`" :to="item.route">
      <v-icon>{{ item.icon }}</v-icon>
      <span v-if="!xs">{{ item.title }}</span>
    </v-btn>
  </v-bottom-navigation>
</template>
