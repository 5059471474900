import type { CustomImageData } from '@/repository/visualSignature.ts'

export interface VisualSignatureData {
  name?: string
  company?: string // TODO: Is now called optional line 1
  language?: string
  location?: string // TODO: Is now called optional line 2
  quality?: string
  legislation?: string
  provider?: string
  displayClaim?: boolean
  displayStandards?: boolean
  displayDate?: boolean
  placeholder?: boolean
  image?: CustomImageData | null
}

export type SignatureMethod = 'signature' | 'seal'

export type SignatureType = 'draft-placed' | 'pre-placed' | 'placed' | 'signed'

export interface PositionedItem {
  width: number
  height: number
  x: number
  y: number
}

export type PositionId = `${SignatureType}_${PositionedItem['x']}_${PositionedItem['y']}` | `${SignatureType}_${number}`

export interface PositionedSignature extends PositionedItem {
  id: PositionId
  type: SignatureType
  author?: string
  isOwned?: boolean
  sealId?: string
  additionalData?: Record<string, string>
}

const paramMap = new Map<keyof VisualSignatureData, VisualSignatureParam>([
  ['name', 'name'],
  ['company', 'opt'],
  ['language', 'lang'],
  ['location', 'loc'],
  ['quality', 'qual'],
  ['legislation', 'leg'],
  ['provider', 'prov'],
  ['displayClaim', 'claim'],
  ['displayStandards', 'std'],
  ['displayDate', 'date'],
  ['placeholder', 'ph'],
])

const mapParams = (data: VisualSignatureData, selection?: (keyof VisualSignatureData)[]) => {
  const keys = (Object.keys(data) as (keyof VisualSignatureData)[]).filter(key => {
    // We are only interested in keys that are known by the visual signature service, and as the service
    // only checks for the presence of the key, we need to make sure that no falsy values are sent
    if (!paramMap.has(key) || !paramMap.get(key) || key === 'image') return false

    if (selection) {
      return selection.includes(key)
    }

    return true
  }) as Exclude<keyof VisualSignatureData, 'image'>[]

  return keys.reduce(
    (params, key) => {
      const value = data[key]!
      params[paramMap.get(key)!] = typeof value === 'boolean' ? value.toString() : value
      return params
    },
    {} as Record<VisualSignatureParam, string>
  )
}

// The target dimensions for visual signatures
const VISUAL_SIGNATURE_WIDTH = 300
const VISUAL_SIGNATURE_HEIGHT = 140

export const useVisualSignatureStore = defineStore('visualSignature', () => {
  const directSignStore = useDirectSignStore()

  const { visualSignatureRepository: signatureRepo, directVisualSignatureRepository: directSignatureRepo } = useApi()

  const signatures = ref<VisualSignatureDetails[]>([])

  const defaultSignatureId = ref('')

  const selectedSignature = computed(() => {
    if (!defaultSignatureId.value) return signatures.value[0]

    return signatures.value.find(signature => signature.id === defaultSignatureId.value)
  })

  const updateSignatures = async () => {
    const { defaultSignature, signatures: signatureDetails } = await signatureRepo.getAll()

    signatures.value = signatureDetails
    defaultSignatureId.value = defaultSignature
  }

  const createSignature = async (details: Omit<VisualSignatureDetails, 'id'> & { customImage?: CustomImageData }) => {
    await signatureRepo.create(details)
    await updateSignatures()
  }

  const updateSignature = async (id: string, updates: Partial<Omit<VisualSignatureDetails, 'id'>>) => {
    await signatureRepo.update(id, updates)
    await updateSignatures()
  }

  const deleteSignature = async (id: string) => {
    await signatureRepo.delete(id)
    await updateSignatures()
  }

  const removeSignatureImage = async (signatureId: string) => {
    await signatureRepo.deleteImage(signatureId)
  }

  // TODO: Clean up conflicting `VisualSignature` types
  const saveSignatureImage = async (signatureId: string, imageData: CustomImageData) => {
    await signatureRepo.setImage(signatureId, imageData)
  }

  const getPredefinedVisualSignature = async (signatureId: string, parameters: VisualSignatureData) => {
    return await signatureRepo.getPredefined(signatureId, mapParams(parameters))
  }

  /**
   * Fetch the default visual signature image associated with the current user.
   *
   * @param parameters The visual signature metadata
   * @param image The optional custom image to be rendered on the visual signature
   */
  const getVisualSignature = async (parameters: VisualSignatureData, image?: CustomImageData) => {
    if (directSignStore.isDirectSign) {
      // For NAS, all the info except `quality` and `legislation` is provided by the backend
      return await directSignatureRepo.getDefault(mapParams(parameters))
    } else if (image) {
      return await signatureRepo.getPreview(mapParams(parameters), image)
    } else {
      // This will just fetch the default visual signature for the current user
      return await signatureRepo.getDefault(mapParams(parameters))
    }
  }

  /**
   * Fetch preview visual signature image
   * @param parameters The visual signature metadata
   * @param image The optional custom image to be rendered instead of name
   */
  const getVisualSignaturePreview = async (parameters: VisualSignatureData, image?: CustomImageData | null) => {
    return await signatureRepo.getPreview(mapParams(parameters), image)
  }

  const { sealRepository } = useApi()

  const getSealImage = async (accountName: string) => {
    const businessStore = useBusinessStore()

    return await sealRepository.getSealImage(businessStore.id, accountName)
  }

  const sealDimensions = ref<Map<string, { width: number; height: number }>>(new Map())

  /**
   * Fits the seal dimensions to the target dimensions.
   *
   * We need to handle 'pre-placed' seals when users switch their signing method from 'signature' to 'seal'. As we only
   * know the placement data for a usually differently sized visual signature, we need to fit the seal to the target.
   *
   * @param sealId
   * @param targetDimensions
   */
  const fitSealDimensions = (sealId: string, targetDimensions: { width: number; height: number }) => {
    const dimensions = sealDimensions.value.get(sealId)

    if (!dimensions) return targetDimensions

    const widthRatio = targetDimensions.width / dimensions.width
    const heightRatio = targetDimensions.height / dimensions.height

    const ratio = Math.min(widthRatio, heightRatio)

    return {
      width: dimensions.width * ratio,
      height: dimensions.height * ratio,
    }
  }

  return {
    signatures,
    defaultSignatureId,
    selectedSignature,
    sealDimensions,
    defaultSignatureDimensions: { width: VISUAL_SIGNATURE_WIDTH, height: VISUAL_SIGNATURE_HEIGHT },
    createSignature,
    updateSignature,
    updateSignatures,
    deleteSignature,
    removeSignatureImage,
    saveSignatureImage,
    getPredefinedVisualSignature,
    getVisualSignaturePreview,
    getVisualSignature,
    getSealImage,
    fitSealDimensions,
  }
})
