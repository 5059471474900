const srRepository = (fetch: CustomFetch) => ({
  async get(id: string) {
    return fetch<SignatureRequestData>(`/v1/signature-requests/${id}`)
  },
  async getAll(type: SignatureRequestType = 'all', email?: string) {
    const searchParams: Record<string, string> = {}

    switch (type) {
      case 'toSign':
        Object.assign(searchParams, {
          status_overall: 'OPEN',
          signature_status: 'OPEN',
          account_email: email,
        })
        break
      case 'pending':
        Object.assign(searchParams, {
          status_overall: 'OPEN',
          /**
           * Magic number defined in the backend, signifying `signatures_count > 0` 😵‍💫
           *
           * @see {@link https://app.asana.com/0/1201960016666275/1204888603830081/f}
           */
          signatures_count: 672,
        })
        break
      case 'empty':
        Object.assign(searchParams, {
          status_overall: 'OPEN',
          signatures_count: 0,
        })
        break
      case 'declined':
        searchParams.status_overall = 'DECLINED,WITHDRAWN'
        break
      case 'completed':
        searchParams.status_overall = 'SIGNED'
        break
    }

    return fetch<SignatureRequestData[]>('/v1/signature-requests', { query: searchParams })
  },
  async update(
    id: string,
    updateData:
      | Partial<SignatureRequestData>
      | (Omit<SignatureRequestData, 'signatures'> & { signatures: Partial<SignatureData>[] })
  ) {
    // TODO: Talk to backend about this, why not `/v1/signature-requests/${id}`?
    return fetch<SignatureRequestData>('/v1/signature-requests', {
      method: 'PUT',
      body: objectToSnakeCase({ id, ...updateData }),
    })
  },
  async getEvents(id: string) {
    return fetch<SignatureRequestEvent[]>(`/v1/signature-requests/${id}/events`)
  },
  async getDocument(documentId: string) {
    return fetch<DocumentData>(`/v1/documents/${documentId}`)
  },
  async checkLock(signatureRequestId: string) {
    return fetch<{ locked: boolean }>(`/v1/signature-requests/${signatureRequestId}/lock-status`)
  },
  async decline(id: string, message?: string) {
    return fetch<{ status: DocumentActionResult }>(`/v1/signature-requests/${id}/decline`, {
      method: 'POST',
      body: { message },
    })
  },
  async withdraw(id: string, message?: string) {
    return fetch<{ status: DocumentActionResult }>(`/v1/signature-requests/${id}/withdraw`, {
      method: 'POST',
      body: { message },
    })
  },
  async remove(id: string) {
    return fetch<{ status: DocumentActionResult }>(`/v1/signature-requests/${id}`, {
      method: 'DELETE',
    })
  },
  async remindSigners(id: string): Promise<void> {
    try {
      await fetch(`/v1/signature-requests/${id}/remind`, {
        method: 'POST',
      })
    } catch {
      throw new Error('Failed to remind signers')
    }
  },
  async delegate(srId: string, delegateData: SignatureDelegateData) {
    return await fetch<{ status: DocumentActionResult }>(`/v1/signature-requests/${srId}/delegate`, {
      method: 'POST',
      body: delegateData,
    })
  },
  async inviteSigner(srId: string, email: string) {
    try {
      await fetch(`/v2/signature-requests/${srId}/signatures`, {
        method: 'POST',
        body: {
          account_email: email,
          signer_identity_data: {
            email_address: email,
          },
        },
      })
    } catch {
      throw new Error('Failed to invite signer')
    }
  },
  async removeSigner(id: string, signatureId: string) {
    return fetch<{ status: DocumentActionResult }>(`/v1/signature-requests/${id}/signatures/${signatureId}`, {
      method: 'DELETE',
    })
  },
})

export default srRepository
