const srRepository = (fetch: CustomFetch) => ({
  async get(id: string) {
    return fetch<SignatureRequestData>(`/v1/direct/signature-requests/${id}`)
  },
  async getEvents(id: string) {
    return fetch<SignatureRequestEvent[]>(`/v1/direct/signature-requests/${id}/events`)
  },
  async getDocument(documentId: string) {
    return fetch<DocumentData>(`/v1/direct/documents/${documentId}`)
  },
  async checkLock(signatureRequestId: string) {
    return fetch<{ locked: boolean }>(`/v1/direct/signature-requests/${signatureRequestId}/lock-status`)
  },
  async decline(id: string, message?: string) {
    return fetch<{ status: DocumentActionResult }>(`/v1/direct/signature-requests/${id}/decline`, {
      method: 'POST',
      body: { message },
    })
  },
})

export default srRepository
