const signingRepository = (fetch: CustomFetch) => ({
  async sign(signData: SignData, language: Language = 'en', tan?: string) {
    return fetch<SignResponse>('/v1/direct/sign', {
      method: 'POST',
      body: objectToSnakeCase({
        signatureRequests: [
          {
            signatureRequestId: signData.id,
            visualSignature: signData.visualSignature,
          },
        ],
        language,
        ...(Boolean(tan) && { tan }),
      }),
    })
  },
  async continueSign(continuationId: string, isPwdotp = false) {
    return fetch<SignResponse>(`/v1/direct/swisscom/proceed/${continuationId}`, {
      method: 'POST',
      body: { pwdotp: isPwdotp },
    })
  },
})

export default signingRepository
