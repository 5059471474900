
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93L2lBPLM2g1Meta } from "/opt/build/repo/src/pages/[...slug].vue?macro=true";
import { default as activityMqoJYkIt8zMeta } from "/opt/build/repo/src/pages/business/activity.vue?macro=true";
import { default as indexb4fRnf2a1zMeta } from "/opt/build/repo/src/pages/business/billing/index.vue?macro=true";
import { default as paymenttpsA8SU3jHMeta } from "/opt/build/repo/src/pages/business/billing/payment.vue?macro=true";
import { default as planYWfx5xD5U1Meta } from "/opt/build/repo/src/pages/business/billing/plan.vue?macro=true";
import { default as dashboardytzfWdQ3IKMeta } from "/opt/build/repo/src/pages/business/dashboard.vue?macro=true";
import { default as apiHFV3a0KPSmMeta } from "/opt/build/repo/src/pages/business/developers/api.vue?macro=true";
import { default as indexfVQTEmrmowMeta } from "/opt/build/repo/src/pages/business/extras/index.vue?macro=true";
import { default as domainsKlK6sJ0YH6Meta } from "/opt/build/repo/src/pages/business/members/domains.vue?macro=true";
import { default as indexlcvYeRXlfBMeta } from "/opt/build/repo/src/pages/business/members/index.vue?macro=true";
import { default as aes_45for_45businesspoapjnM2ZRMeta } from "/opt/build/repo/src/pages/business/profile/aes-for-business.vue?macro=true";
import { default as indexM3NjyvqCfeMeta } from "/opt/build/repo/src/pages/business/profile/index.vue?macro=true";
import { default as indexovP2VeE0RcMeta } from "/opt/build/repo/src/pages/business/register/index.vue?macro=true";
import { default as indexaCJwCXTcIbMeta } from "/opt/build/repo/src/pages/business/seals/[accountname]/index.vue?macro=true";
import { default as indexPXQdJqcYDxMeta } from "/opt/build/repo/src/pages/business/seals/index.vue?macro=true";
import { default as settingsh7CfgoLCgdMeta } from "/opt/build/repo/src/pages/business/settings.vue?macro=true";
import { default as indexAvYPiGCfyWMeta } from "/opt/build/repo/src/pages/business/signup/index.vue?macro=true";
import { default as mail_45sentXWHkzQR6wWMeta } from "/opt/build/repo/src/pages/business/signup/mail-sent.vue?macro=true";
import { default as stepsSBoRPgCm6QMeta } from "/opt/build/repo/src/pages/business/signup/steps.vue?macro=true";
import { default as upgradewIbfhwc8R1Meta } from "/opt/build/repo/src/pages/business/upgrade.vue?macro=true";
import { default as welcome14LWP9HuvDMeta } from "/opt/build/repo/src/pages/business/welcome.vue?macro=true";
import { default as byeHxG6CMoX9vMeta } from "/opt/build/repo/src/pages/bye.vue?macro=true";
import { default as component_45testeVaB6CMDASMeta } from "/opt/build/repo/src/pages/component-test.vue?macro=true";
import { default as cookiesblockedkTbWnOpN1PMeta } from "/opt/build/repo/src/pages/cookiesblocked.vue?macro=true";
import { default as indexiBx5e1aZMSMeta } from "/opt/build/repo/src/pages/direct-sign/identification/[id]/[userid]/index.vue?macro=true";
import { default as previewL0CqtV3dRnMeta } from "/opt/build/repo/src/pages/direct-sign/identification/[id]/[userid]/preview.vue?macro=true";
import { default as indexVHNN0o11thMeta } from "/opt/build/repo/src/pages/direct-sign/identification/eidas/index.vue?macro=true";
import { default as selfQQBLFa9geXMeta } from "/opt/build/repo/src/pages/direct-sign/identification/eidas/self.vue?macro=true";
import { default as video7LScBIUIETMeta } from "/opt/build/repo/src/pages/direct-sign/identification/eidas/video.vue?macro=true";
import { default as id_45austria_45successfXmRJEToHfMeta } from "/opt/build/repo/src/pages/direct-sign/identification/id-austria-success.vue?macro=true";
import { default as autoeo9ASLfkCaMeta } from "/opt/build/repo/src/pages/direct-sign/identification/zertes/auto.vue?macro=true";
import { default as indexgjRwCLqwy7Meta } from "/opt/build/repo/src/pages/direct-sign/identification/zertes/index.vue?macro=true";
import { default as personal_45detailsfN33vw54uuMeta } from "/opt/build/repo/src/pages/direct-sign/identification/zertes/personal-details.vue?macro=true";
import { default as videoho0TLchIihMeta } from "/opt/build/repo/src/pages/direct-sign/identification/zertes/video.vue?macro=true";
import { default as expiredz0jvB0aVg0Meta } from "/opt/build/repo/src/pages/expired.vue?macro=true";
import { default as indexMUmX8mAfjNMeta } from "/opt/build/repo/src/pages/index.vue?macro=true";
import { default as indexIFkXk8RjJgMeta } from "/opt/build/repo/src/pages/invite/[id]/index.vue?macro=true";
import { default as loginwV8UxyWAwuMeta } from "/opt/build/repo/src/pages/login.vue?macro=true";
import { default as logoutwmCjSedCAxMeta } from "/opt/build/repo/src/pages/logout.vue?macro=true";
import { default as confirmhsXA4soTxYMeta } from "/opt/build/repo/src/pages/password-reset/confirm.vue?macro=true";
import { default as indexcQLYDpd6dBMeta } from "/opt/build/repo/src/pages/password-reset/index.vue?macro=true";
import { default as mail_45sentZTB561hUjtMeta } from "/opt/build/repo/src/pages/password-reset/mail-sent.vue?macro=true";
import { default as newnmrAgibIPIMeta } from "/opt/build/repo/src/pages/password-reset/new.vue?macro=true";
import { default as change_45passwordlb9Sr8tr0fMeta } from "/opt/build/repo/src/pages/profile/change-password.vue?macro=true";
import { default as confirmfkNardR4aqMeta } from "/opt/build/repo/src/pages/profile/confirm.vue?macro=true";
import { default as indexogWrHBJCGBMeta } from "/opt/build/repo/src/pages/profile/index.vue?macro=true";
import { default as settingsrHcr2beXjfMeta } from "/opt/build/repo/src/pages/profile/settings.vue?macro=true";
import { default as indexjXhiclBcRtMeta } from "/opt/build/repo/src/pages/profile/signature-standards/eidas/index.vue?macro=true";
import { default as selftZIR7m60fMMeta } from "/opt/build/repo/src/pages/profile/signature-standards/eidas/self.vue?macro=true";
import { default as videoiTKaXKDxohMeta } from "/opt/build/repo/src/pages/profile/signature-standards/eidas/video.vue?macro=true";
import { default as id_45austria_45successNBm2ceG0a0Meta } from "/opt/build/repo/src/pages/profile/signature-standards/id-austria-success.vue?macro=true";
import { default as indexQz32Cp5xGcMeta } from "/opt/build/repo/src/pages/profile/signature-standards/index.vue?macro=true";
import { default as legislationDXMg0R5QSJMeta } from "/opt/build/repo/src/pages/profile/signature-standards/legislation.vue?macro=true";
import { default as mobile_45idBALZya8DdeMeta } from "/opt/build/repo/src/pages/profile/signature-standards/mobile-id.vue?macro=true";
import { default as updateoCq26dMXzJMeta } from "/opt/build/repo/src/pages/profile/signature-standards/update.vue?macro=true";
import { default as autoCGUFBWdkcEMeta } from "/opt/build/repo/src/pages/profile/signature-standards/zertes/auto.vue?macro=true";
import { default as indexHVk0PqYFayMeta } from "/opt/build/repo/src/pages/profile/signature-standards/zertes/index.vue?macro=true";
import { default as video4AhmH5lqezMeta } from "/opt/build/repo/src/pages/profile/signature-standards/zertes/video.vue?macro=true";
import { default as visual_45signature28Eca21BknMeta } from "/opt/build/repo/src/pages/profile/visual-signature.vue?macro=true";
import { default as confirmEsyv6RpQOkMeta } from "/opt/build/repo/src/pages/signup/confirm.vue?macro=true";
import { default as confirm5HsvFAlX7OMeta } from "/opt/build/repo/src/pages/signup/flows/eidplus/confirm.vue?macro=true";
import { default as newFeqFtSkt1BMeta } from "/opt/build/repo/src/pages/signup/flows/eidplus/new.vue?macro=true";
import { default as indexVOtBJ1nbXrMeta } from "/opt/build/repo/src/pages/signup/index.vue?macro=true";
import { default as mail_45sentB45a5nLHpjMeta } from "/opt/build/repo/src/pages/signup/mail-sent.vue?macro=true";
import { default as ssoIThOREAXO2Meta } from "/opt/build/repo/src/pages/signup/sso.vue?macro=true";
import { default as stepsP5SZSU7el8Meta } from "/opt/build/repo/src/pages/signup/steps.vue?macro=true";
import { default as sorry8GphlOdYKCMeta } from "/opt/build/repo/src/pages/sorry.vue?macro=true";
import { default as companyoqvu544JPvMeta } from "/opt/build/repo/src/pages/sso/error/company.vue?macro=true";
import { default as indexvpoiIfwuC4Meta } from "/opt/build/repo/src/pages/sso/error/index.vue?macro=true";
import { default as ident_45resetAlTbN1dk5rMeta } from "/opt/build/repo/src/pages/super-admin/ident-reset.vue?macro=true";
import { default as upgrade0OqClcpXP5Meta } from "/opt/build/repo/src/pages/upgrade.vue?macro=true";
import { default as uploadiYtUksZm2yMeta } from "/opt/build/repo/src/pages/upload.vue?macro=true";
import { default as indexE788LsHUbNMeta } from "/opt/build/repo/src/pages/view/[id]/[userid]/index.vue?macro=true";
import { default as indexqjx9DCHIMTMeta } from "/opt/build/repo/src/pages/view/[srid]/index.vue?macro=true";
import { default as welcomevtKZs28NgIMeta } from "/opt/build/repo/src/pages/welcome.vue?macro=true";
export default [
  {
    name: "404",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93L2lBPLM2g1Meta || {},
    component: () => import("/opt/build/repo/src/pages/[...slug].vue")
  },
  {
    name: "business-activity",
    path: "/business/activity",
    meta: activityMqoJYkIt8zMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/activity.vue")
  },
  {
    name: "business-billing",
    path: "/business/billing",
    meta: indexb4fRnf2a1zMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/billing/index.vue")
  },
  {
    name: "business-billing-payment",
    path: "/business/billing/payment",
    meta: paymenttpsA8SU3jHMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/billing/payment.vue")
  },
  {
    name: "business-billing-plan",
    path: "/business/billing/plan",
    meta: planYWfx5xD5U1Meta || {},
    component: () => import("/opt/build/repo/src/pages/business/billing/plan.vue")
  },
  {
    name: "business-dashboard",
    path: "/business/dashboard",
    meta: dashboardytzfWdQ3IKMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/dashboard.vue")
  },
  {
    name: "business-developers-api",
    path: "/business/developers/api",
    meta: apiHFV3a0KPSmMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/developers/api.vue")
  },
  {
    name: "business-extras",
    path: "/business/extras",
    meta: indexfVQTEmrmowMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/extras/index.vue")
  },
  {
    name: "business-members-domains",
    path: "/business/members/domains",
    meta: domainsKlK6sJ0YH6Meta || {},
    component: () => import("/opt/build/repo/src/pages/business/members/domains.vue")
  },
  {
    name: "business-members",
    path: "/business/members",
    meta: indexlcvYeRXlfBMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/members/index.vue")
  },
  {
    name: "business-profile-aes-for-business",
    path: "/business/profile/aes-for-business",
    meta: aes_45for_45businesspoapjnM2ZRMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/profile/aes-for-business.vue")
  },
  {
    name: "business-profile",
    path: "/business/profile",
    meta: indexM3NjyvqCfeMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/profile/index.vue")
  },
  {
    name: "business-register",
    path: "/business/register",
    component: () => import("/opt/build/repo/src/pages/business/register/index.vue")
  },
  {
    name: "business-seals-accountname",
    path: "/business/seals/:accountname()",
    component: () => import("/opt/build/repo/src/pages/business/seals/[accountname]/index.vue")
  },
  {
    name: "business-seals",
    path: "/business/seals",
    meta: indexPXQdJqcYDxMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/seals/index.vue")
  },
  {
    name: "business-settings",
    path: "/business/settings",
    meta: settingsh7CfgoLCgdMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/settings.vue")
  },
  {
    name: "business-signup",
    path: "/business/signup",
    meta: indexAvYPiGCfyWMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/signup/index.vue")
  },
  {
    name: "business-signup-mail-sent",
    path: "/business/signup/mail-sent",
    meta: mail_45sentXWHkzQR6wWMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/signup/mail-sent.vue")
  },
  {
    name: "business-signup-steps",
    path: "/business/signup/steps",
    component: () => import("/opt/build/repo/src/pages/business/signup/steps.vue")
  },
  {
    name: "business-upgrade",
    path: "/business/upgrade",
    component: () => import("/opt/build/repo/src/pages/business/upgrade.vue")
  },
  {
    name: "business-welcome",
    path: "/business/welcome",
    meta: welcome14LWP9HuvDMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/welcome.vue")
  },
  {
    name: "bye",
    path: "/bye",
    meta: byeHxG6CMoX9vMeta || {},
    component: () => import("/opt/build/repo/src/pages/bye.vue")
  },
  {
    name: "component-test",
    path: "/component-test",
    component: () => import("/opt/build/repo/src/pages/component-test.vue")
  },
  {
    name: "cookiesblocked",
    path: "/cookiesblocked",
    component: () => import("/opt/build/repo/src/pages/cookiesblocked.vue")
  },
  {
    name: "direct-sign-identification-id-userid",
    path: "/direct-sign/identification/:id()/:userid()",
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/[id]/[userid]/index.vue")
  },
  {
    name: "direct-sign-identification-id-userid-preview",
    path: "/direct-sign/identification/:id()/:userid()/preview",
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/[id]/[userid]/preview.vue")
  },
  {
    name: "direct-sign-identification-eidas",
    path: "/direct-sign/identification/eidas",
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/eidas/index.vue")
  },
  {
    name: "direct-sign-identification-eidas-self",
    path: "/direct-sign/identification/eidas/self",
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/eidas/self.vue")
  },
  {
    name: "direct-sign-identification-eidas-video",
    path: "/direct-sign/identification/eidas/video",
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/eidas/video.vue")
  },
  {
    name: "direct-sign-identification-id-austria-success",
    path: "/direct-sign/identification/id-austria-success",
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/id-austria-success.vue")
  },
  {
    name: "direct-sign-identification-zertes-auto",
    path: "/direct-sign/identification/zertes/auto",
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/zertes/auto.vue")
  },
  {
    name: "direct-sign-identification-zertes",
    path: "/direct-sign/identification/zertes",
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/zertes/index.vue")
  },
  {
    name: "direct-sign-identification-zertes-personal-details",
    path: "/direct-sign/identification/zertes/personal-details",
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/zertes/personal-details.vue")
  },
  {
    name: "direct-sign-identification-zertes-video",
    path: "/direct-sign/identification/zertes/video",
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/zertes/video.vue")
  },
  {
    name: "expired",
    path: "/expired",
    meta: expiredz0jvB0aVg0Meta || {},
    component: () => import("/opt/build/repo/src/pages/expired.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexMUmX8mAfjNMeta || {},
    component: () => import("/opt/build/repo/src/pages/index.vue")
  },
  {
    name: "invite-id",
    path: "/invite/:id()",
    component: () => import("/opt/build/repo/src/pages/invite/[id]/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginwV8UxyWAwuMeta || {},
    component: () => import("/opt/build/repo/src/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutwmCjSedCAxMeta || {},
    component: () => import("/opt/build/repo/src/pages/logout.vue")
  },
  {
    name: "password-reset-confirm",
    path: "/password-reset/confirm",
    meta: confirmhsXA4soTxYMeta || {},
    component: () => import("/opt/build/repo/src/pages/password-reset/confirm.vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: indexcQLYDpd6dBMeta || {},
    component: () => import("/opt/build/repo/src/pages/password-reset/index.vue")
  },
  {
    name: "password-reset-mail-sent",
    path: "/password-reset/mail-sent",
    meta: mail_45sentZTB561hUjtMeta || {},
    component: () => import("/opt/build/repo/src/pages/password-reset/mail-sent.vue")
  },
  {
    name: "password-reset-new",
    path: "/password-reset/new",
    meta: newnmrAgibIPIMeta || {},
    component: () => import("/opt/build/repo/src/pages/password-reset/new.vue")
  },
  {
    name: "profile-change-password",
    path: "/profile/change-password",
    component: () => import("/opt/build/repo/src/pages/profile/change-password.vue")
  },
  {
    name: "profile-confirm",
    path: "/profile/confirm",
    component: () => import("/opt/build/repo/src/pages/profile/confirm.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexogWrHBJCGBMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/index.vue")
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: settingsrHcr2beXjfMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/settings.vue")
  },
  {
    name: "profile-signature-standards-eidas",
    path: "/profile/signature-standards/eidas",
    meta: indexjXhiclBcRtMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/eidas/index.vue")
  },
  {
    name: "profile-signature-standards-eidas-self",
    path: "/profile/signature-standards/eidas/self",
    meta: selftZIR7m60fMMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/eidas/self.vue")
  },
  {
    name: "profile-signature-standards-eidas-video",
    path: "/profile/signature-standards/eidas/video",
    meta: videoiTKaXKDxohMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/eidas/video.vue")
  },
  {
    name: "profile-signature-standards-id-austria-success",
    path: "/profile/signature-standards/id-austria-success",
    meta: id_45austria_45successNBm2ceG0a0Meta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/id-austria-success.vue")
  },
  {
    name: "profile-signature-standards",
    path: "/profile/signature-standards",
    meta: indexQz32Cp5xGcMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/index.vue")
  },
  {
    name: "profile-signature-standards-legislation",
    path: "/profile/signature-standards/legislation",
    meta: legislationDXMg0R5QSJMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/legislation.vue")
  },
  {
    name: "profile-signature-standards-mobile-id",
    path: "/profile/signature-standards/mobile-id",
    meta: mobile_45idBALZya8DdeMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/mobile-id.vue")
  },
  {
    name: "profile-signature-standards-update",
    path: "/profile/signature-standards/update",
    meta: updateoCq26dMXzJMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/update.vue")
  },
  {
    name: "profile-signature-standards-zertes-auto",
    path: "/profile/signature-standards/zertes/auto",
    meta: autoCGUFBWdkcEMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/zertes/auto.vue")
  },
  {
    name: "profile-signature-standards-zertes",
    path: "/profile/signature-standards/zertes",
    meta: indexHVk0PqYFayMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/zertes/index.vue")
  },
  {
    name: "profile-signature-standards-zertes-video",
    path: "/profile/signature-standards/zertes/video",
    meta: video4AhmH5lqezMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/zertes/video.vue")
  },
  {
    name: "profile-visual-signature",
    path: "/profile/visual-signature",
    meta: visual_45signature28Eca21BknMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/visual-signature.vue")
  },
  {
    name: "signup-confirm",
    path: "/signup/confirm",
    meta: confirmEsyv6RpQOkMeta || {},
    component: () => import("/opt/build/repo/src/pages/signup/confirm.vue")
  },
  {
    name: "signup-flows-eidplus-confirm",
    path: "/signup/flows/eidplus/confirm",
    meta: confirm5HsvFAlX7OMeta || {},
    component: () => import("/opt/build/repo/src/pages/signup/flows/eidplus/confirm.vue")
  },
  {
    name: "signup-flows-eidplus-new",
    path: "/signup/flows/eidplus/new",
    meta: newFeqFtSkt1BMeta || {},
    component: () => import("/opt/build/repo/src/pages/signup/flows/eidplus/new.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: indexVOtBJ1nbXrMeta || {},
    component: () => import("/opt/build/repo/src/pages/signup/index.vue")
  },
  {
    name: "signup-mail-sent",
    path: "/signup/mail-sent",
    meta: mail_45sentB45a5nLHpjMeta || {},
    component: () => import("/opt/build/repo/src/pages/signup/mail-sent.vue")
  },
  {
    name: "signup-sso",
    path: "/signup/sso",
    component: () => import("/opt/build/repo/src/pages/signup/sso.vue")
  },
  {
    name: "signup-steps",
    path: "/signup/steps",
    component: () => import("/opt/build/repo/src/pages/signup/steps.vue")
  },
  {
    name: "sorry",
    path: "/sorry",
    meta: sorry8GphlOdYKCMeta || {},
    component: () => import("/opt/build/repo/src/pages/sorry.vue")
  },
  {
    name: "sso-error-company",
    path: "/sso/error/company",
    component: () => import("/opt/build/repo/src/pages/sso/error/company.vue")
  },
  {
    name: "sso-error",
    path: "/sso/error",
    component: () => import("/opt/build/repo/src/pages/sso/error/index.vue")
  },
  {
    name: "super-admin-ident-reset",
    path: "/super-admin/ident-reset",
    component: () => import("/opt/build/repo/src/pages/super-admin/ident-reset.vue")
  },
  {
    name: "upgrade",
    path: "/upgrade",
    component: () => import("/opt/build/repo/src/pages/upgrade.vue")
  },
  {
    name: "upload",
    path: "/upload",
    component: () => import("/opt/build/repo/src/pages/upload.vue")
  },
  {
    name: "view-id-userid",
    path: "/view/:id()/:userid()",
    component: () => import("/opt/build/repo/src/pages/view/[id]/[userid]/index.vue")
  },
  {
    name: "view-srid",
    path: "/view/:srid()",
    meta: indexqjx9DCHIMTMeta || {},
    component: () => import("/opt/build/repo/src/pages/view/[srid]/index.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomevtKZs28NgIMeta || {},
    component: () => import("/opt/build/repo/src/pages/welcome.vue")
  }
]