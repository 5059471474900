<script lang="ts" setup>
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

const route = useRoute()

const layoutMeta = computed(() => {
  return {
    showFooter: false,
    ...route.meta.simple,
  }
})
</script>

<template>
  <v-main>
    <site-header :use-plain-header="true" />
    <div class="content">
      <slot />
    </div>
    <site-footer v-if="layoutMeta.showFooter" />
  </v-main>
</template>

<style lang="sass" scoped>
.content
  height: 100%
  margin: 0 auto
</style>
