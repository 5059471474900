<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import SkrCheckbox from '@/components/SkrCheckbox.vue'

withDefaults(
  defineProps<{
    loading: boolean
  }>(),
  { loading: false }
)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['document/DialogWithdraw']['contextData']): void
}>()

const { t } = useI18n()

const data = reactive({
  message: '',
  messageMaxLength: 1000,
  checkboxDelete: false,
})

const withdraw = () => {
  emit('action', { message: data.message, shouldDeleteDocument: data.checkboxDelete })
}
</script>

<template>
  <base-dialog data-cy="withdrawDocDialog" max-width="510" :title="t('dialog.withdraw_document.title')">
    <div class="pb-4">{{ $t('global.withdraw_dialog.withdraw_document_text') }}</div>
    <div class="mb-2">
      <strong>{{ $t('global.withdraw_dialog.message_label') }}</strong>
    </div>
    <v-textarea
      v-model="data.message"
      auto-grow
      counter
      clear-icon="custom:clear"
      :maxlength="data.messageMaxLength"
      clearable
      :label="$t('global.withdraw_dialog.message_placeholder')"
      @blur="data.message = data.message.trim()"
    />
    <template #actions>
      <skr-checkbox
        v-model="data.checkboxDelete"
        class="ml-8"
        data-cy="delete_checkbox"
        :label="$t('global.delete_document_form_account')"
      />
      <v-divider />
      <v-card-actions>
        <v-btn size="x-large" color="error" variant="outlined" @click="emit('close')">
          {{ $t('global.no_thanks') }}
        </v-btn>
        <v-spacer />
        <v-btn
          :loading="loading"
          :disabled="loading"
          size="x-large"
          color="error"
          data-cy="confirmWithdrawButton"
          variant="elevated"
          @click="withdraw"
        >
          {{ $t('global.withdraw') }}
        </v-btn>
      </v-card-actions>
    </template>
  </base-dialog>
</template>
