/*
 * Checks if the url points to view and has an exitURL query param and
 * stores exitURL for the current session. If errorURL and/or declineURL
 * are supplied as well, they get stored too.
 */

/**
 * javascript: is a bad time, can be used by malicious actors
 * but this can be obfuscated in too many ways for blacklisting
 * so instead use a whitelist to only allow URLS starting with http: or https:
 */
const skipInvalidURL = (value: string | undefined) =>
  value && (value.startsWith('http:') || value.startsWith('https:')) ? value : undefined

/* maybe the url doesn't exist, check for it here */
const valueOrUndef = (url: string | (string | null)[]) => (Array.isArray(url) ? url[0] : url) ?? undefined

/* wrap the two fns in a convenience fn */
const parseURL = (url: string | (string | null)[]) => skipInvalidURL(valueOrUndef(url))

export default defineNuxtRouteMiddleware(to => {
  if (to.name === 'view-srid' || to.name === 'view-id-userid') {
    if (to.query.exitURL || to.query.exiturl || to.query.exitUrl) {
      const exitUrlStore = useExitUrlStore()

      const signatureRequest = to.params.srid || to.params.id

      if (signatureRequest) {
        exitUrlStore.lastSignatureRequestId = signatureRequest
      }

      const exitUrl = parseURL(to.query.exitURL || to.query.exiturl || to.query.exitUrl)
      exitUrlStore.exitUrl = exitUrl ?? ''

      // exitURL can be overwritten with specific errorURL for error case
      const errorUrl = parseURL(to.query.errorURL || to.query.errorurl || to.query.errorUrl) || exitUrl
      exitUrlStore.errorUrl = errorUrl ?? ''

      // exitURL can be overwritten with specific declineURL for decline case
      const declineUrl = parseURL(to.query.declineURL || to.query.declineurl || to.query.declineUrl) || exitUrl
      exitUrlStore.declineUrl = declineUrl ?? ''

      // The duration until an automatic redirection takes place can be set by
      // a URL parameter 'redirectTimeout' in seconds which cannot be set lower
      // than 10s or bigger than 90s and defaults to 45 seconds.
      let timeout = 45
      if (typeof to.query.redirectTimeout === 'string' || typeof to.query.redirecttimeout === 'string') {
        let tmpTimeout = parseInt(to.query.redirectTimeout?.toString() || to.query.redirecttimeout?.toString())
        // Timeout needs to be a number otherwise we ignore it.
        if (!isNaN(tmpTimeout)) {
          // Enforce our constraints.
          tmpTimeout = Math.max(tmpTimeout, 10)
          tmpTimeout = Math.min(tmpTimeout, 90)

          timeout = tmpTimeout
        }
      }

      exitUrlStore.timeout = timeout

      // TODO: Seems like this was never used, should we remove it?
      exitUrlStore.shouldDeclineRedirect = Boolean(declineUrl && (to.query.declineredirect || to.query.declineRedirect))
    }
  }
})
