import differenceInDays from 'date-fns/differenceInDays'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'

import type { PositionedSignature } from '@/stores/visualSignature'

interface DocumentState {
  lastSelectedType: ActiveTab | null
  visualSignature: Partial<VisualSignature>
  activeDocument: DocumentData
  activeSignatureRequest: SignatureRequest
  daysUntilDeletion: number
  placedSignatures: Map<number, Map<string, PositionedSignature>>
  aspectRatios: Map<number, Pick<DocumentData, 'pageWidth' | 'pageHeight'>>
}

export interface ActiveVisualSignature {
  signatureId: string
  signatureAuthor: string
  positions: SignaturePosition[]
  isSigned: boolean
  isOwned?: boolean
}

export interface ActivePage {
  height: number
  width: number
  number: number
  url: string
  visualSignatures: ActiveVisualSignature[]
}

const signatureRequestDefaults: SignatureRequest = {
  id: '',
  title: '',
  message: '',
  documentId: '',
  legislation: '',
  quality: '',
  statusOverall: '',
  signingUrl: '',
  business: '',
  attachOnSuccess: [],
  signatures: [],
  expirationDate: '',
  attachments: [],
  ccEmailAddresses: [],
  owner: '',
  readAccess: [],
  writeAccess: [],
  createdAt: '',
  updatedAt: '',
  bizName: '',
  branding: null,
  events: [],
}

const documentDefaults: DocumentData = {
  id: '',
  title: '',
  contentType: '',
  owner: '',
  readAccess: [],
  writeAccess: [],
  createdAt: '',
  updatedAt: '',
  size: 0,
  pageCount: 0,
  pageHeight: 0,
  pageWidth: 0,
}

const getSignatureName = (signature: SignatureData) => {
  const name = [signature.signerIdentityData?.firstName, signature.signerIdentityData?.lastName].join(' ')
  return name && name !== ' ' ? name : signature.signerIdentityData?.emailAddress || signature.accountEmail || ''
}

const isOnPage = (position: SignaturePosition, pageNumber: number) => {
  return Number(position.page) + 1 === pageNumber
}

export const useDocumentStore = defineStore('document', {
  state: (): DocumentState => ({
    lastSelectedType: null,
    visualSignature: {},
    activeDocument: { ...documentDefaults },
    activeSignatureRequest: { ...signatureRequestDefaults },
    daysUntilDeletion: 0,
    placedSignatures: new Map(),
    aspectRatios: new Map(),
  }),
  getters: {
    userCanRemind(): boolean {
      const userStore = useUserStore()
      /* there is an active document */
      if (!this.activeDocument.id) return false
      /* document is open for signing and signers invited */
      if (this.activeSignatureRequest.statusOverall !== 'OPEN') return false
      if (this.activeSignatureRequest.signatures.length === 0) return false
      /* user is owner of the document */
      if (this.activeDocument.owner !== userStore.email) return false
      /* user is not a free user */
      if (!userStore.id || userStore.isFreeUser) return false
      return true
    },
    /**
     * The positioned items attached to the currently active signature request. Currently, this includes
     * both visual signatures and seals, as their shape is indistinguishable.
     */
    activeVisualSignatures(): ActiveVisualSignature[] {
      const userStore = useUserStore()

      return this.activeSignatureRequest.signatures.reduce<ActiveVisualSignature[]>((visualSignatures, signature) => {
        if (signature.visualSignature) {
          // For NAS, the `accountEmail` is actually the user ID for some reason, as well as the `email` in the user store
          const isOwned =
            (signature.signerIdentityData?.emailAddress ?? signature.accountEmail) === userStore.email ||
            signature.accountEmail === userStore.email

          const visualSignatureData = {
            signatureId: signature.sid,
            signatureAuthor: getSignatureName(signature),
            // For backwards compatibility, we need to support both `positions` and `position`
            positions:
              signature.visualSignature.positions ??
              (signature.visualSignature.position ? [signature.visualSignature.position] : []),
            isSigned: signature.statusCode === 'SIGNED',
            isOwned,
          }

          visualSignatures.push(visualSignatureData)
        }

        return visualSignatures
      }, [])
    },
    documentPages(): ActivePage[] {
      if (!this.activeSignatureRequest.id || !this.activeDocument.id) return []

      // TODO: Refactor this
      const { documentPagePreviewURL } = useDocumentUrl()

      return [...this.aspectRatios.entries()].map(([pageNumber, dimensions]) => {
        const visualSignatures = this.activeVisualSignatures
          .filter(signature => {
            return signature.positions.some(position => isOnPage(position, pageNumber))
          })
          .map(signature => {
            return {
              ...signature,
              positions: signature.positions.filter(position => isOnPage(position, pageNumber)),
            }
          })

        return {
          // FIXME: For now, we only get a general size for the document, not for each page, so we use what we already know and update later
          width: dimensions.pageWidth,
          height: dimensions.pageHeight,
          number: pageNumber,
          url: documentPagePreviewURL(this.activeDocument.id, pageNumber - 1, 100),
          visualSignatures,
        }
      })
    },
    visualSignatureSpec(): VisualSignatureSpec {
      const userStore = useUserStore()

      const specData: VisualSignatureSpec = {
        applicable: false,
        positions: [],
        presetImage: false,
        pagesWithPreplacedSignatures: [],
      }
      // Construct array of pages which have a signature preplaced in order to
      // not lazy load them later on. This affects the VisSig of the current
      // user and the VisSigs of the co-signers.
      this.activeSignatureRequest.signatures.forEach(signature => {
        const pageNumber = parseInt(signature.visualSignature?.position?.page ?? '-1')
        if (pageNumber !== -1 && !specData.pagesWithPreplacedSignatures.includes(pageNumber)) {
          specData.pagesWithPreplacedSignatures.push(pageNumber)
        }
      })

      if (this.activeSignatureRequest.statusOverall === 'OPEN') {
        const userSignature = this.activeSignatureRequest.signatures.find(
          signature => signature.accountEmail === userStore.email && signature.statusCode === 'OPEN'
        )

        if (userSignature) {
          specData.applicable = true

          if (userSignature.visualSignature?.position) {
            specData.positions.push(userSignature.visualSignature.position)
          } else if (userSignature.visualSignature?.formField) {
            const field = this.activeDocument.signatureFields?.find(field => {
              return field.name === userSignature.visualSignature?.formField
            })

            if (field) {
              specData.positions = field.positions
            }
          }
          // TODO: What the heck is this?
          // if (userSignature.image) {
          //   specData.presetImage = true
          // }
        }
      }

      return specData
    },
  },
  actions: {
    async fetchActiveDocument(docId: string) {
      const directSignStore = useDirectSignStore()
      const { srRepository, directSrRepository } = useApi()

      const documentData = directSignStore.isDirectSign
        ? await directSrRepository.getDocument(docId)
        : await srRepository.getDocument(docId)

      Array.from({ length: documentData.pageCount }, (_, i) => {
        const pageNumber = i + 1
        return { pageNumber, pageWidth: documentData.pageWidth, pageHeight: documentData.pageHeight }
      }).forEach(({ pageNumber, pageWidth, pageHeight }) => {
        this.aspectRatios.set(pageNumber, { pageWidth, pageHeight })
      })

      // Workaround until we have full Vue 3 support
      this.aspectRatios = new Map(this.aspectRatios)

      this.$patch({ activeDocument: documentData })
      void this.updateDaysUntilDeletion()
    },
    updatePageDimensions(pageNumber: number, pageWidth: number, pageHeight: number) {
      this.aspectRatios.set(pageNumber, { pageWidth, pageHeight })
      // Workaround until we have full Vue 3 support
      this.aspectRatios = new Map(this.aspectRatios)
    },
    async loadActiveSignatureRequest(srId: string) {
      const directSignStore = useDirectSignStore()
      const { srRepository, directSrRepository } = useApi()

      const signatureRequestData = directSignStore.isDirectSign
        ? await directSrRepository.get(srId)
        : await srRepository.get(srId)

      this.$patch({ activeSignatureRequest: signatureRequestData })
    },
    async fetchSignatureRequestEvents() {
      const directSignStore = useDirectSignStore()
      const { srRepository, directSrRepository } = useApi()

      const events = directSignStore.isDirectSign
        ? await directSrRepository.getEvents(this.activeSignatureRequest.id)
        : await srRepository.getEvents(this.activeSignatureRequest.id)

      this.$patch({ activeSignatureRequest: { events } })
    },
    async fetchCompanyBranding() {
      if (!this.activeSignatureRequest.business) return

      const { businessRepository } = useApi()

      const businessData = await businessRepository.get(this.activeSignatureRequest.business)
      const brandingData = businessData.settings?.companyBranding
      if (brandingData) {
        this.$patch({ activeSignatureRequest: { branding: brandingData } })
      }
      this.$patch({ activeSignatureRequest: { bizName: businessData.name } })
    },
    /**
     * Convenience method to update related SR data in one go.
     */
    async updateActiveSignatureRequestData(id: string) {
      await this.loadActiveSignatureRequest(id)
      await Promise.all([
        this.fetchActiveDocument(this.activeSignatureRequest.documentId),
        this.fetchSignatureRequestEvents(),
        this.fetchCompanyBranding(),
      ])
    },
    /**
     * Resets active document data.
     *
     * Needed to mimic old behaviour of the app.
     */
    resetActiveDocument() {
      this.$patch({ activeDocument: { ...documentDefaults } })
      this.aspectRatios = new Map()
    },
    /**
     * Resets active signature request data.
     *
     * Needed to mimic old behaviour of the app.
     */
    resetActiveSignatureRequest() {
      this.$patch({ activeSignatureRequest: { ...signatureRequestDefaults } })
    },
    /**
     * Convenience method to reset related SR data in one go.
     */
    async resetActiveSignatureRequestData() {
      await Promise.all([this.resetActiveDocument(), this.resetActiveSignatureRequest()])
    },
    setVisualSignature(visualSignature: Partial<VisualSignature>) {
      this.$patch({ visualSignature })
    },
    resetVisualSignature() {
      // the visual signature is not placed
      this.visualSignature = {}
    },
    async updateDaysUntilDeletion() {
      // Get current datetime from server and pass it on to document previews
      // so we can calculate accurate time until deletion of individual documents
      const systemStore = useSystemStore()
      await systemStore.updateTime()
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
      const ds = `${this.activeSignatureRequest?.expirationDate}T03:00`
      let diff = differenceInDays(
        utcToZonedTime(ds, tz, {
          timeZone: 'UTC',
        }),
        utcToZonedTime(systemStore.time.now, tz, {
          timeZone: 'UTC',
        })
      )
      if (diff < 0) diff = 0
      this.daysUntilDeletion = diff
    },
    async getDocumentPreview(documentId: string, pageNumber: number, scale = 20) {
      const { documentRepository, directDocumentRepository } = useApi()

      const directSignStore = useDirectSignStore()

      if (directSignStore.isDirectSign) {
        const token = directSignStore.directSignB64AuthHeader
        return await directDocumentRepository.getDocumentPage(documentId, pageNumber, { scale, token })
      } else {
        return await documentRepository.getDocumentPage(documentId, pageNumber, scale)
      }
    },
    async getDocumentPage(pageNumber: number, scale = 100) {
      const { documentRepository, directDocumentRepository } = useApi()

      const directSignStore = useDirectSignStore()

      if (directSignStore.isDirectSign) {
        const token = directSignStore.directSignB64AuthHeader
        return await directDocumentRepository.getDocumentPage(this.activeDocument.id, pageNumber, { scale, token })
      } else {
        return await documentRepository.getDocumentPage(this.activeDocument.id, pageNumber, scale)
      }
    },
    updateSignaturePlacement(pageNumber: number, signature: PositionedSignature) {
      const page = this.placedSignatures.get(pageNumber) ?? new Map<string, PositionedSignature>()
      page.set(signature.id, signature)
      // Workaround until we have full Vue 3 support
      this.placedSignatures = new Map(this.placedSignatures.set(pageNumber, page))
    },
    removePlacedSignature(pageNumber: number, signatureId: string) {
      if (this.placedSignatures.has(pageNumber)) {
        const page = this.placedSignatures.get(pageNumber)!
        page.delete(signatureId)
        if (page.size === 0) {
          this.placedSignatures.delete(pageNumber)
        } else {
          this.placedSignatures.set(pageNumber, page)
        }
        // Workaround until we have full Vue 3 support
        this.placedSignatures = new Map(this.placedSignatures)
      }
    },
    clearDocument() {
      this.$reset()
    },
  },
})
