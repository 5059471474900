import type { Ref } from 'vue'

export default function useDocumentActions(signatureRequest: Ref<SignatureRequestData>) {
  const { isDirectSign, directSignB64AuthHeader } = storeToRefs(useDirectSignStore())
  const { email } = storeToRefs(useUserStore())

  const {
    documentRepository: docsRepo,
    directDocumentRepository: directDocsRepo,
    srRepository: srRepo,
    directSrRepository: directSrRepo,
  } = useApi()

  // TODO: Multiple boolean parameters can be confusing, we may want to use an options object instead
  // Also, isSigned could also be inferred from the injected signatureRequest
  const downloadDocument = async (isSigned: boolean, isInline = false) => {
    const documentId = signatureRequest.value.documentId

    const options = {
      isSigned,
      isInline,
    }

    const { data, name } = isDirectSign.value
      ? await directDocsRepo.getDocumentData(documentId, directSignB64AuthHeader.value, options)
      : await docsRepo.getDocumentData(documentId, options)

    downloadFile(data, name)
  }

  const downloadProtocol = async (type: SignatureProtocolType = 'pdf') => {
    const srId = signatureRequest.value.id

    const { data, name } = isDirectSign.value
      ? await directDocsRepo.getProtocol(srId, type, directSignB64AuthHeader.value)
      : await docsRepo.getProtocol(srId, type)

    downloadFile(data, name)
  }

  const downloadAttachment = async (attachmentId: string, name?: string) => {
    const srId = signatureRequest.value.id

    const { data, name: attachmentName } = isDirectSign.value
      ? await directDocsRepo.getAttachment(srId, attachmentId, directSignB64AuthHeader.value)
      : await docsRepo.getAttachment(srId, attachmentId)

    downloadFile(data, name ?? attachmentName)
  }

  const assign = async (recipient: string, message: string): Promise<DocumentActionResult> => {
    const signatureId = signatureRequest.value.signatures.find(
      sig => sig.accountEmail === email.value && sig.statusCode === 'OPEN'
    )?.sid

    if (!signatureId) throw new Error('No signature of assigning user found')

    const { status } = await srRepo.delegate(signatureRequest.value.id, {
      sid: signatureId,
      to: [recipient],
      message,
    })

    return status
  }

  const decline = async (message?: string): Promise<DocumentActionResult> => {
    const srId = signatureRequest.value.id

    const { status } = isDirectSign.value
      ? await directSrRepo.decline(srId, message)
      : await srRepo.decline(srId, message)

    return status
  }

  const withdraw = async (message?: string): Promise<DocumentActionResult> => {
    const { status } = await srRepo.withdraw(signatureRequest.value.id, message)

    return status
  }

  const remove = async (): Promise<DocumentActionResult> => {
    const { status } = await srRepo.remove(signatureRequest.value.id)

    return status
  }

  const remind = async () => {
    await srRepo.remindSigners(signatureRequest.value.id)
  }

  const inviteSigner = async (email: string) => {
    await srRepo.inviteSigner(signatureRequest.value.id, email)
  }

  const removeSigner = async (signatureId: string) => {
    await srRepo.removeSigner(signatureRequest.value.id, signatureId)
  }

  return {
    downloadDocument,
    downloadProtocol,
    downloadAttachment,
    assign,
    decline,
    withdraw,
    remove,
    remind,
    inviteSigner,
    removeSigner,
  }
}
