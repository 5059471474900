<template>
  <v-container class="fill-height content">
    <v-row class="fill-height" align="center">
      <v-col cols="12" md="5">
        <div class="mb-10">
          <skr-heading level="1" semantic>{{ $t('password_reset.new.title') }}</skr-heading>
          <skr-heading level="2">{{ $t('password_reset.new.subtitle', { n: 10 }) }}</skr-heading>
        </div>
        <password-input
          v-model="password"
          :label="$t('global.password')"
          :token="token"
          @validate="isPasswordValid => (isValid = isPasswordValid)"
        />
        <skr-button block size="xl" :disabled="!isValid" @click="resetPassword">{{
          $t('password_reset.new.cta')
        }}</skr-button>
      </v-col>
      <v-col v-if="$vuetify.display.mdAndUp" cols="7">
        <responsive-image source="set-password" :width="447" :height="352" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import SkrButton from '@/components/button/SkrButton.vue'
import PasswordInput from '@/components/PasswordInput.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SkrHeading from '@/components/SkrHeading.vue'

export default defineComponent({
  components: {
    SkrHeading,
    SkrButton,
    PasswordInput,
    ResponsiveImage,
  },
  setup() {
    definePageMeta({
      layout: 'unauthenticated',
    })

    const { t } = useI18n()

    const widgetStore = useWidgetStore()

    const password = ref('')
    const token = ref<string | null>(null)
    const isValid = ref(false)

    const noToken = () => {
      // show general error snackbar
      void widgetStore.createSnackbar({
        message: t('global.general_error'),
      })
      // send user to login
      void navigateTo({ name: 'login' })
    }

    const { userRepository } = useApi()

    const resetPassword = async () => {
      const tokenValue = token.value || ''
      if (!tokenValue) return noToken()
      try {
        await userRepository.resetPassword(tokenValue, password.value)
        localStorage.removeItem('reset-token')
        widgetStore.createSnackbar({
          message: t('password_reset.new.successfully_reset'),
        })
        await navigateTo({ name: 'login' })
      } catch {
        localStorage.removeItem('reset-token')
        await navigateTo({ name: 'password-reset-confirm', params: { s: tokenValue } })
      }
    }

    onBeforeMount(() => {
      const storedToken = localStorage.getItem('reset-token')
      if (storedToken) {
        token.value = storedToken
      } else {
        noToken()
      }
    })

    return {
      password,
      isValid,
      resetPassword,
      token,
    }
  },
  head() {
    return {
      title: 'Password Reset - New',
    }
  },
})
</script>
