export default defineNuxtPlugin({
  name: 'sentry',
  async setup(nuxtApp) {
    const router = useRouter()
    const {
      public: { sentry, domainName },
    } = useRuntimeConfig()

    if (!sentry.dsn || sentry.disabled) {
      return {}
    }

    const Sentry = await import('@sentry/vue')

    const sampleRate = sentry.tracing.tracesSampleRate

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: sentry.config.environment,
      release: sentry.config.release,
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

      tracesSampleRate: sampleRate,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', `https://my.${domainName}`],

      replaysSessionSampleRate: sampleRate,
      replaysOnErrorSampleRate: 0.8,
    })

    return {
      provide: {
        sentry: {
          captureException: Sentry.captureException,
          captureMessage: Sentry.captureMessage,
          captureEvent: Sentry.captureEvent,
          setUser: Sentry.setUser,
        },
      },
    }
  },
})
