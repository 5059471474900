import type { PartialDeep } from 'type-fest'

export const useBusinessStore = defineStore('business', {
  state: (): BusinessData => ({
    id: '',
    admins: [],
    members: [],
    invitedMembers: [],
    domains: [],
    email: '',
    name: '',
    phone: '',
    createdAt: '',
    gtcAcceptedAt: '',
    dpaAcceptedAt: '',
    billingStatus: '',
    billing: {
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      vat: '',
      currency: undefined,
      paymentMethod: undefined,
      stripeSubscriptionStatus: undefined,
    },
    pricePlan: {
      licenses: {
        idents: {
          eidas: {
            payForMembers: false,
            payForInvitations: false,
          },
          zertes: {
            payForMembers: false,
            payForInvitations: false,
          },
        },
      },
      current: {
        base: null,
      },
      next: {
        base: null,
      },
      discounts: [],
    },
    settings: {
      supportContact: { email: '', phone: '' },
      signatureRequests: { part11: { enabled: false } },
      companyBranding: {
        logo: { height: '', width: '', url: '' },
      },
      mailService: {
        attachments: {
          signedDocument: { enabled: false, customisable: false },
          signatureProtocol: { enabled: false, customisable: false },
        },
      },
    },
    seals: [],
    stripe: {
      customerPortalURL: '',
      subscriptionErrorCode: '',
      subscriptionStatus: '',
    },
    aes4biz: {
      enabled: false,
      representativeName: '',
      representativeEmail: '',
      commercialRegister: '',
      domains: [],
    },
    dashboard: null,
  }),
  getters: {
    branding: state => {
      if ('companyBranding' in state.settings) {
        if (state.settings.companyBranding?.logo !== undefined) {
          return state.settings.companyBranding
        } else {
          return Object.assign({}, state.settings.companyBranding, {
            logo: { url: '' },
          })
        }
      }
      return {
        logo: {
          url: '',
        },
      }
    },
    isPrePricing2023(): boolean {
      return !this.currentPricePlan
    },
    currentPricePlan(state): ActivePricePlan | null {
      return state.pricePlan.current.base
    },
    /**
     * Infers the plan name from the plan id.
     *
     * We don't get a human-readable name from the backend, so we need to compute it.
     */
    currentPricePlanName(): PricePlanName | '' {
      const id = this.currentPricePlan?.type
      const inferredName = id?.split('_').shift()
      if (!inferredName) return ''
      return (inferredName.charAt(0) + inferredName.slice(1).toLowerCase()) as PricePlanName
    },
    isCancelling(): boolean {
      return this.pricePlan?.next?.base?.type === 'CANCELED'
    },
    isCancelled(): boolean {
      return this.currentPricePlan?.type === 'CANCELED'
    },
    isTrialing(state): boolean {
      return state.billing.paymentMethod === 'TRIALING' || state.billingStatus === 'trialing'
    },
    isTrialExpired(state): boolean {
      return state.billing.paymentMethod === 'NEEDS_PAYMENT_METHOD' || state.billingStatus === 'needs_payment_method'
    },
    isLocked(state): boolean {
      return state.billing.paymentMethod === 'BLOCKED'
    },
    isManuallyInvoiced(state): boolean {
      return (
        state.billing.paymentMethod === 'MANUAL' || (state.billingStatus === 'manual' && !state.settings.stripe?.status)
      )
    },
    isStripeBusiness(state): boolean {
      if (this.isTrialing) return false
      if (this.isManuallyInvoiced) return false
      if (this.isChargebeeBusiness) return false
      return state.billing.paymentMethod === 'STRIPE' || state.billingStatus === 'stripe'
    },
    isChargebeeBusiness(state): boolean {
      return state.billing.paymentMethod === 'CHARGEBEE'
    },
    trialEndDate(state): Date | null {
      if (!this.isTrialing) return null
      const trialEndDateString = state.pricePlan.current.base?.endAt
      return trialEndDateString ? new Date(trialEndDateString) : null
    },
    stripeCustomerPortalUrl(state): string | null {
      return state.stripe.customerPortalURL
    },
    hasPaymentIssues(state): boolean {
      return (
        (!this.isLocked &&
          state.stripe.subscriptionStatus &&
          !['active', 'incomplete_expired'].includes(state.stripe.subscriptionStatus)) ||
        Boolean(
          state.stripe?.subscriptionStatus &&
            !['active', 'incomplete_expired'].includes(state.stripe?.subscriptionStatus)
        )
      )
    },
    companyHasAutoDeletionEnabled(state) {
      return state.settings?.signatureRequests?.retention?.enabled ?? false
    },
    companyHasLegacyAutoDeletion(state) {
      return state.settings?.signatureRequests?.retention?.terminalState ?? false
    },
    companyHasPart11(state) {
      return state.settings?.signatureRequests?.part11?.enabled ?? false
    },
    companyIsEnterprise(state) {
      if (state.pricePlan.name) {
        return state.pricePlan.name.substring(0, 3) === 'ent'
      } else {
        return false
      }
    },
    hasDiscountApplied(state) {
      return Boolean(state.pricePlan.discounts?.length)
    },
    discountPromoCode(state) {
      if (!this.hasDiscountApplied) return null
      return state.pricePlan.discounts?.[0].promoCode
    },
    discountExpirationDateDisplay(state) {
      if (!this.hasDiscountApplied) return null
      const { formatDate } = useDate()
      const expiresAt = state.pricePlan.discounts?.[0].expiresAt
      if (!expiresAt) return null
      const date = new Date(expiresAt)
      return formatDate(date)
    },
    isDiscountActive(state) {
      if (!this.hasDiscountApplied) return false
      return state.pricePlan.discounts?.[0].status === 'ACTIVE'
    },
    isDiscountUsedUp(state) {
      if (!this.hasDiscountApplied) return false
      return state.pricePlan.discounts?.[0].status === 'USED_UP'
    },
    isSMGDiscountApplied(state) {
      if (!this.hasDiscountApplied) return false
      return state.pricePlan.discounts?.[0]?.type?.toLocaleLowerCase().startsWith('smg_')
    },
    isIdentificationActive(state) {
      return (
        (state.pricePlan.licenses?.idents?.eidas.payForInvitations ||
          state.pricePlan.licenses?.idents?.eidas.payForMembers ||
          state.pricePlan.licenses?.idents?.zertes.payForInvitations ||
          state.pricePlan.licenses?.idents?.zertes.payForMembers) ??
        false
      )
    },
  },
  actions: {
    async registerBusiness(registerData: RegisterBusinessPayload) {
      const { businessRepository } = useApi()

      const businessData = await businessRepository.create(registerData)

      if (registerData.attributes?.companySize || registerData.attributes?.skribbleSigningVolume) {
        const { $userpilot } = useNuxtApp()
        $userpilot?.setData({
          companySize: registerData.attributes?.companySize,
          skribbleSigningVolume: registerData.attributes?.skribbleSigningVolume,
        })
      }

      this.$patch(businessData)
    },
    async getBusiness() {
      if (!this.id) return

      const { businessRepository } = useApi()
      const businessData = await businessRepository.get(this.id)

      this.$patch(businessData)
    },
    async getStripeCustomerPortalUrl() {
      if (!this.id) return

      const { businessRepository } = useApi()
      this.stripe.customerPortalURL = await businessRepository.getCustomerPortalUrl(this.id)
    },
    async updateContactInfo(contactInfo: Partial<BusinessContactInfo>) {
      const { businessRepository } = useApi()
      const updatedData = await businessRepository.updateContactInfo(this.id, contactInfo)
      this.$patch(updatedData)
    },
    async updateSupportContact(contactData: SupportContactData) {
      const { businessRepository } = useApi()

      const updatedData = await businessRepository.updateSupportContact(this.id, {
        settings: { supportContact: contactData },
        // FIXME: Backend expects all below as well, which doesn't make a lot of sense
        name: this.name,
        email: this.email,
        phone: this.phone,
      })

      this.$patch(updatedData)
      this.parseAndPatchSettings(updatedData.settings)
    },
    async updateBillingAddress(addressData: Partial<Address>) {
      const { businessRepository } = useApi()
      const updatedData = await businessRepository.updateBillingAddress(this.id, addressData)
      this.$patch(updatedData)
    },
    async deleteBusiness() {
      const { businessRepository } = useApi()
      await businessRepository.delete(this.id)
    },
    async revokeCancelBusiness() {
      const { businessRepository } = useApi()
      await businessRepository.revokeDeletion(this.id)
      await this.getBusiness()

      if (this.pricePlan?.next?.base?.type === 'CANCELED') {
        this.pricePlan.next.base = null
      }
    },
    async getApiUsers(): Promise<APIKeyList> {
      const { businessRepository } = useApi()
      return businessRepository.getApiUsers(this.id)
    },
    async createApiUser(data: {
      type: string
      description: string
    }): Promise<{ user: APIKeyCreateUser; status?: string }> {
      const { businessRepository } = useApi()
      return await businessRepository.createApiUser(this.id, data)
    },
    async deleteApiUser(username: string) {
      const { businessRepository } = useApi()
      return await businessRepository.deleteApiUser(this.id, username)
    },
    async resetApiUser(username: string): Promise<{ user: APIKeyReset }> {
      const { businessRepository } = useApi()
      return await businessRepository.resetApiUser(this.id, username)
    },
    async updateApiUser(data: { username: string; description: string }): Promise<{ user: APIKeyUpdate }> {
      const { businessRepository } = useApi()
      return await businessRepository.updateApiUser(this.id, data)
    },
    async getSeals() {
      if (!this.id) return

      const { businessRepository } = useApi()
      const seals = await businessRepository.getSeals(this.id)

      this.$patch({ seals })
    },
    async addMembers(userEmails: string[]) {
      const { businessRepository } = useApi()
      return businessRepository.addMembers(this.id, userEmails)
    },
    async removeMember(userIdOrIds: string | string[]) {
      const { businessRepository } = useApi()
      if (Array.isArray(userIdOrIds)) {
        await businessRepository.removeMembers(this.id, userIdOrIds)
      } else {
        await businessRepository.removeMember(this.id, userIdOrIds)
      }
    },
    async makeAdmin(userIdOrIds: string | string[]) {
      const { businessRepository } = useApi()
      if (Array.isArray(userIdOrIds)) {
        await businessRepository.makeAdmins(this.id, userIdOrIds)
      } else {
        await businessRepository.makeAdmin(this.id, userIdOrIds)
      }
    },
    async revokeAdmin(userIdOrIds: string | string[]) {
      const { businessRepository } = useApi()
      if (Array.isArray(userIdOrIds)) {
        await businessRepository.revokeAdmins(this.id, userIdOrIds)
      } else {
        await businessRepository.revokeAdmin(this.id, userIdOrIds)
      }
    },
    async revokeInvite(userId: string) {
      const { businessRepository } = useApi()
      await businessRepository.revokeInvite(this.id, userId)
    },
    async addDomain(domain: string) {
      const { businessRepository } = useApi()
      const domains = await businessRepository.addDomain(this.id, domain)
      this.$patch({ domains })
    },
    async removeDomain(domain: string) {
      const { businessRepository } = useApi()
      const domains = await businessRepository.removeDomain(this.id, domain)
      this.$patch({ domains })
    },
    async getSettings() {
      const { businessRepository } = useApi()
      this.parseAndPatchSettings(await businessRepository.getSettings(this.id))
    },
    async updateSettings(settings: PartialDeep<BusinessPreferences>) {
      const { businessRepository } = useApi()
      this.parseAndPatchSettings(await businessRepository.updateSettings(this.id, settings))
    },
    async cancelTrial() {
      const { businessRepository } = useApi()
      return businessRepository.cancelTrial(this.id)
    },
    parseAndPatchSettings(settings: BusinessPreferences) {
      const validator = useValidation()
      if (settings.supportContact) {
        /* remove possibly bad email, as this comes from user defined value */
        if (settings.supportContact.email && !validator.isEmail(settings.supportContact.email)) {
          settings.supportContact.email = ''
        }
        /* remove possibly bad phone, as this comes from user defined value */
        if (settings.supportContact.phone && !validator.isPhoneNumber(settings.supportContact.phone)) {
          settings.supportContact.phone = ''
        }
      }
      this.$patch({ settings })

      const { $userpilot } = useNuxtApp()
      $userpilot?.initialize()
    },
    async loadDashboard() {
      const { businessRepository } = useApi()
      const dashboard = await businessRepository.getDashboard(this.id)
      this.$patch({ dashboard })
    },
    clearBusiness() {
      this.$reset()
    },
  },
})
