interface VisualSignaturePermission {
  canUpdate: boolean // is the user able to see (and update) this visual signature option
  defaultValue: boolean // default value of the visual signature toggle setting
}

interface VisualSignaturePermissions {
  claim: VisualSignaturePermission
  date: VisualSignaturePermission
  std: VisualSignaturePermission
}

interface UserPermissions {
  visualSignatures: VisualSignaturePermissions
}

interface UserData {
  id: string
  email: string
  emailVerified: boolean
  firstName: string
  lastName: string
  hasSso: boolean
  retention: number
  attributes: Partial<UserAttributes>
  signatureQualities: SignatureQualityData
  disabledQualities: string[]
  permissions: UserPermissions
}

export const useUserStore = defineStore('user', {
  state: (): UserData => ({
    id: '',
    email: '',
    emailVerified: false,
    firstName: '',
    lastName: '',
    hasSso: false,
    retention: 0,
    signatureQualities: {
      ses: { any: null },
      aes: { any: null },
      aesMinimal: { eidas: null, zertes: null },
      qes: { eidas: null, zertes: null },
      seal: {
        aes: {},
        qes: {},
      },
    },
    disabledQualities: [],
    attributes: {},
    permissions: {
      visualSignatures: {
        claim: { canUpdate: false, defaultValue: false },
        date: { canUpdate: false, defaultValue: false },
        std: { canUpdate: false, defaultValue: false },
      },
    },
  }),
  getters: {
    isBusinessMember(): boolean {
      const businessStore = useBusinessStore()
      return Boolean(this.id && businessStore.members.includes(this.id))
    },
    isBusinessAdmin(): boolean {
      const businessStore = useBusinessStore()
      return Boolean(this.id && businessStore.admins.includes(this.id))
    },
    isSsoSetupIncomplete(): boolean {
      const directSignStore = useDirectSignStore()
      return !directSignStore.isDirectSign && !this.firstName && !this.lastName && !this.attributes.accepted_gtc?.[0]
    },
    signerIdentityEmail(): string | undefined {
      return this.attributes.signer_identity_email?.[0]
    },
    // TODO: What is this?
    hasSigningCredit(): boolean {
      // This user can sign anyway because they are either in a biz account
      // or are a pro user. so we set this to true
      if (!this.isFreeUser) return true

      const directSignStore = useDirectSignStore()

      // Check if user is a NAS or if the user has at least
      // 1 free signature left
      return Boolean(
        directSignStore.directSignSession || parseInt(this.attributes?.free_signatures_remaining?.[0] ?? '0', 10) > 0
      )
    },
    mobile(): string | undefined {
      return this.attributes?.mobile?.[0]
    },
    isFreeUser(): boolean {
      return !this.attributes?.stripe_subscription_id && !this.attributes?.member_of
    },
    highestSignatureQuality(state): string | null {
      if (!state.emailVerified) return null

      if (state.signatureQualities?.qes?.eidas || state.signatureQualities?.qes?.zertes) {
        // If a user did a video ident they can only sign with eIDAS. However,
        // it is possible to end up in a ZertES-only case when the user only
        // accepted the ZertES GTC (and not the eIDAS GTC) of Swisscom.
        // Therefore we need to check both keys for 'demo'.
        if (state.signatureQualities.qes.eidas?.quality?.toLowerCase() === 'demo') {
          return 'demo'
        } else if (state.signatureQualities.qes.zertes?.quality?.toLowerCase() === 'demo') {
          return 'demo'
        } else {
          return 'qes'
        }
      } else if (state.signatureQualities?.aes?.any) {
        return 'aes'
      } else if (state.signatureQualities?.ses?.any) {
        return 'ses'
      }

      return null
    },
    part11SignerData(): { name: string; reason: string } | null {
      const name = this.attributes.p11_signing_name?.[0]
      const reason = this.attributes.p11_signing_reason?.[0]

      if (!name || !reason) return null

      return { name, reason }
    },
    preferredLanguage(): Language {
      return this.attributes?.lang?.[0] ?? 'en'
    },
    hasImmutableMobile(): boolean {
      return this.attributes?.immutable_eid_fields?.includes('mobile') || false
    },
  },
  actions: {
    async loginUser(email: string, password: string, stayLoggedIn = false) {
      const authStore = useAuthStore()
      const { userRepository } = useApi()

      const token = await userRepository.login(email, password)
      authStore.storeToken(token, 'Bearer', stayLoggedIn)
    },
    async registerUser(email: string, registerData: RegisterUserPayload) {
      const { userRepository } = useApi()

      await userRepository.register(registerData)

      const authStore = useAuthStore()

      const token = await userRepository.login(email, registerData.password, true)
      authStore.storeToken(token)
    },
    async fetchUser() {
      const directSignStore = useDirectSignStore()
      const languageStore = useLanguageStore()

      const { userRepository, directUserRepository } = useApi()

      const userData = directSignStore.isDirectSign ? await directUserRepository.get() : await userRepository.get()

      const userLanguage = userData.attributes?.lang?.[0]

      if (directSignStore.isDirectSign) {
        // TODO: NAS should follow the same pattern as for regular users (backend needs to supply dedicated endpoint to fetch NAS visual signatures)
        const visualSignatureStore = useVisualSignatureStore()

        const defaultSignature = {
          name: userData.attributes?.visual_signature_name?.[0] ?? '',
          language: userLanguage ?? 'en',
          company: '',
          location: '',
          displayClaim: false,
          displayStandards: false,
          displayQr: false,
          displayDate: true,
        }

        visualSignatureStore.$patch({
          signatures: [defaultSignature],
        })
      }

      this.$patch(userData)
      // Attributes may just outright not exist anymore, so we need to take that into account
      this.attributes = userData.attributes ?? {}

      if (userLanguage) {
        await languageStore.setLanguage(userLanguage)
      }

      // free legacy needs init
      if (userData.attributes?.free_account_type?.[0] === 'free_legacy') {
        const { $userpilot } = useNuxtApp()
        $userpilot?.initialize()
      }
    },
    // TODO: Harmonise with business store
    async fetchUserBusiness() {
      const { businessRepository } = useApi()

      try {
        return await businessRepository.getDefault()
      } catch {
        return null
      }
    },
    async loadUserBusinessData() {
      const business = await this.fetchUserBusiness()
      if (!business) return
      // fetch stripe status from both old and new world business properties
      const stripeSubscriptionStatus = business.settings?.stripe?.status || business.billing?.stripeSubscriptionStatus
      if (stripeSubscriptionStatus) {
        Object.assign(business, { stripe: { subscriptionStatus: stripeSubscriptionStatus } })
      }

      const businessStore = useBusinessStore()
      businessStore.$patch(business)
      return business
    },
    async setPreferredLanguage(language: Language) {
      if (!this.id) return

      const { userRepository } = useApi()

      await userRepository.updateAttributes({ lang: language })
      this.$patch({ attributes: { lang: [language] } })
    },
    async setNotificationLevel(level: string) {
      if (!this.id) return

      const { userRepository } = useApi()

      await userRepository.updateAttributes({ email_notification_level: level })
      this.$patch({ attributes: { email_notification_level: [level] } })
    },
    async setDefaultLegislation(legislation: string) {
      if (!this.id) return

      const { userRepository } = useApi()

      await userRepository.updateAttributes({ default_legislation: legislation })
      this.$patch({ attributes: { default_legislation: [legislation] } })
    },
    async setDefaultQuality(quality: string) {
      if (!this.id) return

      const { userRepository } = useApi()

      await userRepository.updateAttributes({ default_quality: quality })
      this.$patch({ attributes: { default_quality: [quality] } })
    },
    clearUser() {
      const authStore = useAuthStore()
      this.$reset()
      authStore.removeStoredToken()
    },
    async logoutUser(redirectTo = '/login?logged-out') {
      const { $sentry, $auth } = useNuxtApp()
      const { userRepository } = useApi()

      this.clearUser()
      await userRepository.logout()
      $sentry?.setUser(null)
      $auth.signalLogout()
      window.location.href = redirectTo
    },
    async accept(terms: 'gtc' | 'tou' | 'biz') {
      const { userRepository } = useApi()

      switch (terms) {
        case 'gtc':
          return await userRepository.acceptGeneralTerms()
        case 'tou':
          return await userRepository.acceptSwisscomTerms()
        case 'biz':
          return await userRepository.acceptBusinessInvitation()
      }
    },
  },
})
