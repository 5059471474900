import payload_plugin_qLmFnukI99 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_KAumv2pUni from "/opt/build/repo/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jB24kmad32 from "/opt/build/repo/node_modules/@kgierke/nuxt-matomo/dist/runtime/plugin.js";
import vuetify_no_client_hints_kyYn5AnnAK from "/opt/build/repo/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import _0_error_handling_WhIDEYY3hx from "/opt/build/repo/src/plugins/0.error-handling.ts";
import analytics_ao9ICGyq1A from "/opt/build/repo/src/plugins/analytics.ts";
import auth_e0FkfVuy48 from "/opt/build/repo/src/plugins/auth.ts";
import cypress_bPcAVP8KDa from "/opt/build/repo/src/plugins/cypress.ts";
import http_JHM6w8Twll from "/opt/build/repo/src/plugins/http.ts";
import i18n_iso_countries_hKfTRgnNLK from "/opt/build/repo/src/plugins/i18n-iso-countries.ts";
import i18n_YCWKwfVXF6 from "/opt/build/repo/src/plugins/i18n.ts";
import sentry_h0fMjCG9AB from "/opt/build/repo/src/plugins/sentry.ts";
import userpilot_CVxGu26IvJ from "/opt/build/repo/src/plugins/userpilot.ts";
import verification_SMOmiFkdwp from "/opt/build/repo/src/plugins/verification.ts";
import vuetify_F4zXYcNIwC from "/opt/build/repo/src/plugins/vuetify.ts";
import webp_xo0rHd345T from "/opt/build/repo/src/plugins/webp.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/opt/build/repo/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_KAumv2pUni,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_jB24kmad32,
  vuetify_no_client_hints_kyYn5AnnAK,
  _0_error_handling_WhIDEYY3hx,
  analytics_ao9ICGyq1A,
  auth_e0FkfVuy48,
  cypress_bPcAVP8KDa,
  http_JHM6w8Twll,
  i18n_iso_countries_hKfTRgnNLK,
  i18n_YCWKwfVXF6,
  sentry_h0fMjCG9AB,
  userpilot_CVxGu26IvJ,
  verification_SMOmiFkdwp,
  vuetify_F4zXYcNIwC,
  webp_xo0rHd345T,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]