<template>
  <!--
    TODO: This and MainNav.vue are very similar and should be
    refactored for less duplication.
    Maybe even consider MainNavBusiness.vue and MiniNavBusiness.vue too.
  -->
  <v-navigation-drawer v-model="drawer" class="main-nav" temporary :width="300">
    <div class="d-flex flex-column justify-space-between fill-height">
      <div>
        <nuxt-link to="/" class="text-no-decoration">
          <logo class="main-nav__logo my-5 mx-4" :image="brandingLogoUrl" />
        </nuxt-link>
        <template v-if="$vuetify.display.smAndDown && isNewFreePlan">
          <div class="px-6 pb-6">
            <p class="mb-4">{{ $t('upgrade.free_indicator') }}</p>
            <skr-button size="lg" block to="/upgrade">{{ $t('upgrade.choose_plan') }}</skr-button>
          </div>
          <skr-divider />
        </template>
        <v-list>
          <v-list-item :disabled="isNewFreePlan" :to="{ name: 'upload' }" class="main-nav__add" @click="drawer = false">
            <v-list-item-action>
              <v-icon>custom:c_add_full</v-icon>
            </v-list-item-action>

            <v-list-item-title>
              {{ $t('global.navigation.new') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'index' }" :active="route.name === 'index'" @click="drawer = false">
            <v-list-item-action>
              <v-icon>custom:folder_user</v-icon>
            </v-list-item-action>

            <v-list-item-title>
              {{ $t('global.navigation.documents') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
      <div>
        <v-divider />
        <v-list>
          <v-list-item :to="{ name: 'logout' }">
            <v-list-item-action>
              <v-icon>custom:leave</v-icon>
            </v-list-item-action>

            <v-list-item-title>
              {{ $t('global.navigation.logout') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-list class="main-nav__help-overview">
          <v-list-group value="Help">
            <template #activator="{ props }">
              <v-list-item v-bind="props" class="px-0">
                <v-list-item-action>
                  <v-icon>custom:c_question</v-icon>
                </v-list-item-action>

                <v-list-item-title>
                  {{ $t('global.navigation.help_center') }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <v-list-item :href="$t('global.navigation.helpcenter.link')" target="_blank" rel="noopener">
              <v-list-item-action>
                <v-icon>custom:help_center</v-icon>
              </v-list-item-action>

              <v-list-item-title>
                {{ $t('global.navigation.helpcenter.label') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item :href="$t('global.navigation.tutorials.link')" target="_blank" rel="noopener">
              <v-list-item-action>
                <v-icon>custom:video_tutorials</v-icon>
              </v-list-item-action>

              <v-list-item-title>
                {{ $t('global.navigation.tutorials.label') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item :href="getEmailContactLink" target="_blank" rel="noopener">
              <v-list-item-action>
                <v-icon>custom:contact_email</v-icon>
              </v-list-item-action>

              <v-list-item-title>
                {{
                  supportContact?.email
                    ? $t('global.navigation.internal_support_contact.mail.label')
                    : $t('global.navigation.contact.label')
                }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item :href="getSupportContactLink" target="_blank" rel="noopener">
              <v-list-item-action>
                <v-icon>custom:support_hotline</v-icon>
              </v-list-item-action>

              <v-list-item-title class="pb-1">
                {{
                  supportContact?.phone
                    ? $t('global.navigation.internal_support_contact.phone.label')
                    : $t('global.navigation.call.label')
                }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ supportContact.phone ?? $t('global.skribble_support_phone_number') }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list-group>
        </v-list>
        <template v-if="userIsAdmin">
          <v-divider />
          <v-list>
            <v-list-item data-cy="businessButton" :exact="true" :to="{ name: goToAdmin }" @click="drawer = false">
              <v-list-item-action>
                <v-icon>custom:business_account</v-icon>
              </v-list-item-action>

              <v-list-item-title>
                {{ $t('global.navigation.admin_area') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
        <v-divider />
        <v-list>
          <v-list-item :to="{ name: 'profile' }" @click="drawer = false">
            <v-avatar class="mr-5 my-2">
              <avatar />
              <nuxt-img
                v-if="!highestSignatureQuality"
                width="16"
                height="16"
                class="main-nav__signing-status"
                src="/img/info-warning-small.svg"
                alt=""
              />
            </v-avatar>

            <v-list-item-title>{{ displayedName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Avatar from '@/components/Avatar.vue'
import SkrButton from '@/components/button/SkrButton.vue'
import Logo from '@/components/Logo.vue'
import SkrDivider from '@/components/SkrDivider.vue'

export default defineComponent({
  name: 'MainNav',
  components: {
    Avatar,
    Logo,
    SkrButton,
    SkrDivider,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const route = useRoute()

    const businessStore = useBusinessStore()
    const { isPrePricing2023 } = storeToRefs(businessStore)
    const { firstName, lastName, isBusinessAdmin, isBusinessMember, highestSignatureQuality } =
      storeToRefs(useUserStore())

    const { activePricePlanId, isNewFreePlan } = useUserPlanInfo()
    const systemStore = useSystemStore()

    const displayedName = computed(() => `${firstName.value.split(' ')[0]} ${lastName.value}`)
    const supportContact = computed(() => businessStore.settings.supportContact)
    const brandingLogoUrl = computed(() => businessStore.settings.companyBranding?.logo.url ?? '')
    const platform = computed(() => systemStore.platform || 'CH')
    const { t } = useI18n()

    const getEmailContactLink = computed(() => {
      return supportContact.value?.email
        ? `mailto:${supportContact.value.email}`
        : platform.value === 'EU'
          ? t('global.navigation.contact.link_form_de')
          : t('global.navigation.contact.link')
    })

    const getSupportContactLink = computed(() => {
      return supportContact.value?.phone
        ? `tel:${supportContact.value.phone}`
        : platform.value === 'EU'
          ? t('global.navigation.call.call_support_form_de')
          : t('global.navigation.call.call_support_form')
    })

    const goToAdmin = computed(() => {
      if (['TRIAL', 'TEAM', 'PRO', 'SCALE'].some(plan => activePricePlanId.value?.startsWith(plan))) {
        return 'business-dashboard'
      } else if (activePricePlanId.value?.startsWith('INDIVIDUAL')) {
        return 'business-billing-plan'
      } else {
        return 'business-members'
      }
    })

    return {
      userIsAdmin: isBusinessAdmin,
      userIsMember: isBusinessMember,
      route,
      displayedName,
      isNewFreePlan,
      isPrePricing2023,
      activePricePlanId,
      supportContact,
      brandingLogoUrl,
      highestSignatureQuality,
      platform,
      getSupportContactLink,
      getEmailContactLink,
      goToAdmin,
    }
  },
  data() {
    return {
      drawer: false,
    }
  },
  watch: {
    active() {
      this.drawer = this.active
    },
    drawer() {
      this.$emit('changed', this.drawer)
    },
  },
})
</script>

<style lang="sass">
.main-nav
  .version
    position: absolute
    top: 0.5rem
    right: 0.5rem
    color: $c-text
    font-size: 0.75rem

  .v-avatar
    position: relative
    overflow: visible

  .v-list-item
    padding: 0 22px !important
    font-size: 0.875rem
    line-height: 1.1428
    font-weight: bold
    &.v-theme--light
      &:hover,
      &:focus
        color: $c-primary

        .v-icon
          color: $c-primary

      .v-list-item__sub-title
        color: $c-text-alt
    .v-avatar
      margin-left: -6px
    &__title
      font-size: 0.875rem
      line-height: 1.1428
    &--active
      color: $c-primary
      &:before
        background: none
    &--disabled, &--disabled span
      color: #D8D8D8

    .v-list-item-action
      margin: 12px 32px 12px 0
    .v-list-item__content
      display: flex
      align-items: center
      overflow: visible
  .v-list-group__header
    .v-list-item
      &:hover
        background: none

  .v-card__title
    small
      font-size: 0.875rem
      color: $c-text

  &__add
    color: $c-primary
    .v-icon
      color: $c-primary

  .main-nav__signing-status
    position: absolute
    right: -4px
    top: -4px
    width: 16px
    height: 16px

  &__help-overview
    .v-list__group:before, .v-list__group:after
      display: none

    .v-list__group__header--active
      color: $c-primary
      border: none
      border-left: 4px solid $c-primary

      .v-list-item .v-theme--light, .v-list-item__content
        transition: none
        margin-left: -4px

      svg, i
        color: $c-primary

    .v-list-item__sub-title
      color: inherit !important
</style>
