<script lang="ts" setup>
import type { ButtonColor } from '@/components/button/SkrButton.vue'
import SkrButton from '@/components/button/SkrButton.vue'

interface Props {
  title: string
  maxWidth?: string
  actionText?: string
  actionColor?: ButtonColor
  cancelText?: string
  disabled?: boolean
  loading?: boolean
  persistent?: boolean
  dataCy?: string
}

withDefaults(defineProps<Props>(), {
  maxWidth: '570',
})

const emit = defineEmits<{
  (event: 'action' | 'close'): void
}>()

const { t } = useI18n()

const modelValue = ref(true)

watchEffect(() => {
  if (!modelValue.value) {
    emit('close')
  }
})
</script>

<template>
  <v-dialog :model-value="modelValue" :max-width="maxWidth" :persistent="persistent" :close-on-back="true">
    <v-card :data-cy="dataCy">
      <v-card-title class="whitespace-normal">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <slot />
      </v-card-text>
      <slot name="actions">
        <v-divider />
        <v-card-actions>
          <skr-button
            data-cy="dialog_cancel_button"
            type="secondary"
            size="xl"
            :color="actionColor"
            @click="emit('close')"
          >
            {{ cancelText ?? t('global.no_thanks') }}
          </skr-button>
          <v-spacer />
          <skr-button
            data-cy="dialog_action_button"
            size="xl"
            :color="actionColor"
            :disabled="disabled"
            :loading="loading"
            @click="emit('action')"
          >
            {{ actionText ?? t('global.accept') }}
          </skr-button>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>
