<script lang="ts" setup>
import TextButton from '@/components/business/TextButton.vue'

// Use the system store to get platform information
const systemStore = useSystemStore()
const { t } = useI18n()

// Computed properties
const currentPlatform = computed(() => systemStore.platform)

const flagSrc = computed(() => {
  const currentPlatformFlag = currentPlatform.value === 'CH' ? 'CH' : 'DE'
  return `/img/flags/${currentPlatformFlag}-round.svg`
})

const switchToUrl = computed(() => {
  const toTld = currentPlatform.value === 'CH' ? 'de' : 'com'
  return `https://my.skribble.${toTld}`
})

const currentCountry = computed(() => {
  return currentPlatform.value === 'CH' ? t('global.ch') : t('global.de')
})

const switchToPlatformCountry = computed(() => {
  return currentPlatform.value === 'CH' ? t('platform_switch.de') : t('platform_switch.ch')
})
</script>

<template>
  <div class="platform-switcher" data-cy="platform_switcher">
    <v-row align="center" dense :class="{ 'my-2': $vuetify.display.smAndDown }">
      <v-col cols="12" md="auto">
        <v-row align="end" class="platform-switcher__current" no-gutters>
          <v-col cols="12" md="auto">
            <span class="mr-1" :class="{ 'text-body-2': $vuetify.display.mdAndDown }">{{
              $t('platform_switch.currently_on')
            }}</span>
          </v-col>
          <v-col cols="12" md="auto">
            <div class="d-flex align-center">
              <span class="font-weight-bold grey-darker--text mr-2">Skribble {{ currentCountry }}</span>
              <nuxt-img class="flag-icon" :src="flagSrc" data-cy="platform_switcher_icon" />
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="auto">
        <text-button
          class="platform-switcher__button"
          color="primary"
          :href="switchToUrl"
          data-cy="platform_switcher_target_location"
        >
          {{ $t('platform_switch.switch_to') }} {{ switchToPlatformCountry }}
        </text-button>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="sass">

.platform-switcher
  &__current
    display: flex
    align-items: center
    background-color: #fafafa
    padding: 0.75rem 1rem
    border-radius: 8px
    margin: 0 1rem

  &__current-platform
    color: $c-grey-darker
    font-weight: bold
    margin-left: 0.5rem

    &--flag-icon
      display: inline-block
      margin-left: 0.5rem
      width: 24px
      height: 24px
      border-radius: 50%
      object-fit: cover

  &__button
    flex-shrink: 0
    padding: 0.75rem 1rem
    white-space: nowrap
    margin: 0 1rem
</style>
