// NB: This mostly mirrors the document repository, but we need to use a different base URL
// and we need to pass the token as a query parameter instead of a header

import { getRetryOptions } from '@/repository/document.ts'

const documentRepository = (fetch: CustomFetch) => ({
  async getDocumentData(documentId: string, token: string, options = { isSigned: false, isInline: false }) {
    const params = new URLSearchParams()
    params.append('token', token)
    if (options.isSigned) params.append('signed', 'true')
    if (options.isInline) params.append('inline', 'true')

    const response = await fetch.raw<Blob>(`v1/direct/documents/${documentId}/download?${params.toString()}`)
    const data = response._data!
    const name = extractFilename(response)

    return {
      data,
      name: name ?? `${documentId}.pdf`,
    }
  },
  async getProtocol(srId: string, type: SignatureProtocolType, token: string) {
    const response = await fetch.raw<Blob>(`v1/direct/signature-requests/${srId}/report?type=${type}&token=${token}`)

    const data = response._data!
    const name = extractFilename(response)

    return {
      data,
      name: name ?? `${srId}_protocol.pdf`,
    }
  },
  async getAttachment(srId: string, attachmentId: string, token: string) {
    const response = await fetch.raw<Blob>(
      `v1/direct/signature-requests/${srId}/attachments/${attachmentId}/content?token=${token}`
    )

    const data = response._data!
    const dataType = data.type.split('/')[1]
    const name = extractFilename(response)

    return {
      data,
      name: name ?? `${srId}_attachment-${attachmentId}.${dataType}`,
    }
  },
  async getDocumentPage(documentId: string, pageNumber: number, parameters: { scale?: number; token: string }) {
    const query = new URLSearchParams({
      scale: parameters.scale?.toString() ?? '100',
      token: parameters.token,
    })
    return fetch<Blob>(`v1/direct/documents/${documentId}/pages/${pageNumber}?${query.toString()}`, {
      ...getRetryOptions(10),
    })
  },
})

export default documentRepository
