export default function useDocumentUrl() {
  const directSignStore = useDirectSignStore()
  const { directSignB64AuthHeader, isDirectSign } = storeToRefs(directSignStore)

  const { http } = useRuntimeConfig().public

  const getPagePreviewUrl = (documentId: number | string, page: number, scale: number): string => {
    const params = new URLSearchParams()
    params.append('scale', scale.toString())

    if (isDirectSign.value) {
      params.append('token', directSignB64AuthHeader.value)
      return `${http.browserBaseURL}/v1/direct/documents/${documentId}/pages/${page}?${params.toString()}`
    } else {
      return `${http.browserBaseURL}/v1/documents/${documentId}/pages/${page}?${params.toString()}`
    }
  }

  return {
    // For backwards compatibility
    documentPagePreviewURL: getPagePreviewUrl,
  }
}
