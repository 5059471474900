import type { Snackbar } from '@/components/snackbar/SkrSnackbar.vue'

interface RegisteredSnackbar extends Snackbar {
  id: string
}

interface WidgetData {
  snackbarQueue: RegisteredSnackbar[]
  activeOverlay: DynamicOverlayData | null
  activeDialog: DynamicDialogData | null
}

export const useWidgetStore = defineStore('widget', {
  state: (): WidgetData => ({
    snackbarQueue: [],
    activeOverlay: null,
    activeDialog: null,
  }),
  actions: {
    setOverlay<T extends OverlayName>(name: T, props: Overlays[T], onAction?: DynamicOverlayData['onAction']) {
      this.activeOverlay = {
        id: Date.now().toString(),
        name,
        props,
        onAction,
      }
    },
    setDialog<T extends DialogName>(name: T, props: Dialogs[T]['props'], onAction?: DialogAction<T>) {
      const segments = name.split('/')

      if (segments.length === 2) {
        const [namespace, dialogName] = segments as [ResolvedNamespace<T>, ResolvedDialogName<T>]
        this.activeDialog = {
          id: Date.now().toString(),
          name: dialogName,
          namespace,
          props,
          onAction,
        }
      } else if (segments.length === 1) {
        this.activeDialog = {
          id: Date.now().toString(),
          name: segments[0] as ResolvedDialogName<T>,
          props,
          onAction,
        }
      }
    },
    createSnackbar(data: Snackbar) {
      const snackbarData: RegisteredSnackbar = {
        id: Date.now().toString(),
        color: 'skribbleu',
        ...data,
      }

      this.snackbarQueue.push(snackbarData)
    },
    removeSnackbar(id: string) {
      this.snackbarQueue = this.snackbarQueue.filter(snackbar => snackbar.id !== id)
    },
    resetSnackbars() {
      this.snackbarQueue = []
    },
    resetWidgets() {
      this.resetSnackbars()
      this.activeOverlay = null
    },
  },
})
