<script lang="ts" setup>
const props = defineProps<{
  navigation: (NavigationItem | NavigationDivider)[]
}>()

const { xs, smAndDown } = useDisplay()

const route = useRoute()

const navBreakpointCondition = inject<boolean>('navBreakpointCondition', smAndDown.value)

const filteredNavigation = props.navigation.filter((item): item is NavigationItem => !item.divider)
</script>

<template>
  <!-- Bottom nav is hidden when there's only 1 entry -->
  <v-bottom-navigation
    v-if="filteredNavigation.length > 1 && navBreakpointCondition"
    class="in-page-nav-bottom"
    grow
    :mode="xs ? 'shift' : 'fixed'"
  >
    <v-btn
      v-for="(item, i) in filteredNavigation"
      :key="`${item}-${i}`"
      :class="{
        'v-btn--active': item.showAsActive || item.routeName === route.name,
        'in-page-nav-bottom__active': item.showAsActive || item.routeName === route.name,
      }"
      :exact="true"
      variant="text"
      @click="navigateTo({ name: item.routeName })"
    >
      <span>{{ item.displayTitle }}</span>
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<style lang="sass">
.in-page-nav-bottom__active
  color: $c-primary !important

.admin-layout
  .in-page-nav-bottom
    z-index: 4 !important
</style>
