<template>
  <div class="business-billing-payment">
    <div class="mb-10">
      <skr-heading level="1">{{ $t('business.payment_method.title') }}</skr-heading>
      <skr-heading level="2">{{ $t('business.payment_method.subtitle') }}</skr-heading>
    </div>
    <business-billing-nav />
    <card v-if="businessStore.isTrialing && !legacyBusinessStore.defaultPaymentMethod" class="mb-6">
      <v-row>
        <v-col>
          <v-row class="flex-column h-100" align="start" justify="space-between">
            <p class="mb-1">
              <strong>{{
                $t('business.free_trial.runs_until', {
                  dueDate: trialEndDisplay,
                })
              }}</strong>
            </p>
            <p class="mb-4">
              {{ $t('business.payment_method.trialing_state.card.text') }}
            </p>
            <v-btn size="x-large" class="bg-info ma-0" @click.stop="navigateTo({ name: 'business-upgrade' })">
              {{ $t('global.upgrade_now') }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </card>
    <v-expansion-panels>
      <expansion-panel-disabled
        v-if="!businessStore.isManuallyInvoiced && !businessStore.isTrialing"
        :title="$t('global.billing_period')"
        :message="billingCycleTranslated"
      />
    </v-expansion-panels>
    <div v-if="!businessStore.isManuallyInvoiced && !businessStore.isTrialing" class="my-4 ml-6">
      <i18n-t keypath="business.billing.billing_period.contact.text">
        <template #email>
          <a :href="`mailto:${$t('business.billing.billing_period.contact.email')}`">{{
            $t('business.billing.billing_period.contact.email')
          }}</a>
        </template>
      </i18n-t>
    </div>
    <business-info-edit
      v-if="!businessStore.isTrialing"
      :is-manual="businessStore.isManuallyInvoiced"
      :params="{
        email: true,
        address: Boolean(businessStore.isManuallyInvoiced || legacyBusinessStore.defaultPaymentMethod),
      }"
    />
    <v-expansion-panels v-model="billingPanelOpen" class="mt-8">
      <expansion-panel-payment-method
        v-if="!businessStore.isManuallyInvoiced && legacyBusinessStore.defaultPaymentMethod"
        :loading="loadingPaymentMethods || savingAddressAndPaymentMethod"
        :saving="savingPaymentMethod || savingAddressAndPaymentMethod"
        :save-button-text="saveButtonText"
        :is-active="activeBillingPanel === 'paymentMethod'"
        @cancel="billingPanelOpen = null"
        @updatePaymentMethod="updatePaymentMethod($event, true)"
        @billingPanelOpened="activeBillingPanel = $event.source"
      />
    </v-expansion-panels>
    <stripe-error-message :visible="false" :code="errorCode" @onErrorMessage="onErrorMessage" />
  </div>
</template>

<script lang="ts">
import type { PaymentMethodCreateParams, StripeElements } from '@stripe/stripe-js'

import BusinessInfoEdit from '@/components/business/BusinessInfoEdit.vue'
import Card from '@/components/Card.vue'
import ExpansionPanelDisabled from '@/components/expansion-panel/ExpansionPanelDisabled.vue'
import ExpansionPanelPaymentMethod from '@/components/expansion-panel/ExpansionPanelPaymentMethod.vue'
import BusinessBillingNav from '@/components/in-page-nav/BusinessBillingNav.vue'
import StripeErrorMessage from '@/components/StripeErrorMessage.vue'
import SkrHeading from '@/components/SkrHeading.vue'

export default defineComponent({
  components: {
    SkrHeading,
    BusinessInfoEdit,
    ExpansionPanelDisabled,
    ExpansionPanelPaymentMethod,
    BusinessBillingNav,
    Card,
    StripeErrorMessage,
  },
  setup() {
    definePageMeta({
      layout: 'admin',
      admin: {
        sidebar: false,
      },
    })

    const legacyBusinessStore = useLegacyBusinessStore()
    const businessStore = useBusinessStore()
    const widgetStore = useWidgetStore()
    const { formatDate } = useDate()
    const { t } = useI18n()

    const trialEndDisplay = computed(() => {
      if (!businessStore.trialEndDate) return ''
      return formatDate(businessStore.trialEndDate)
    })

    const billingCycleTranslated = computed((): string => {
      const billingCycle = businessStore.isPrePricing2023
        ? businessStore.pricePlan.billingCycle.toUpperCase()
        : businessStore.currentPricePlan?.cycle

      switch (billingCycle) {
        case 'MONTHLY':
          return t('billing.billing_cycle.monthly') as string
        case 'YEARLY':
          return t('billing.billing_cycle.yearly') as string
        default:
          return ''
      }
    })

    return { businessStore, legacyBusinessStore, trialEndDisplay, billingCycleTranslated, widgetStore }
  },
  data() {
    return {
      activeBillingPanel: '',
      loadingBusiness: false,
      loadingBillingCycle: false,
      mainNavDrawer: false,
      savingPaymentMethod: false,
      loadingPaymentMethods: false,
      stripeCardData: undefined,
      billingAddress: {
        city: '',
        country: '',
        address1: '',
        address2: '',
        postal_code: '',
        state: '',
      } as CompanyBilling,
      billingPanelOpen: null,
      savingAddressAndPaymentMethod: false,
      errorCode: undefined as string | undefined,
    }
  },
  head() {
    return {
      title: 'Business - Billing - Payment',
    }
  },
  computed: {
    stripeFormattedAddress(): PaymentMethodCreateParams.BillingDetails.Address {
      const company = this.businessStore
      return {
        city: company.billing.city,
        country: company.billing.country,
        line1: company.billing.address1,
        line2: company.billing.address2,
        postal_code: company.billing.postalCode,
        state: '',
      }
    },
    saveButtonText(): string {
      if (this.legacyBusinessStore.defaultPaymentMethod) return this.$t('business.payment_method.panel_content.button')
      return this.$t('global.save')
    },
  },
  watch: {
    billingPanelOpen(value) {
      if (typeof value === 'undefined' || value === null) this.activeBillingPanel = ''
    },
  },
  beforeMount() {
    void this.fetchStripePaymentMethods()
  },
  created() {
    this.billingAddress = this.businessStore.billing as CompanyBilling
  },
  methods: {
    async updatePaymentMethod(
      {
        stripeElements,
      }: {
        stripeElements: StripeElements
      },
      showSnackbar = false,
      closePanels = true
    ): Promise<void> {
      this.savingPaymentMethod = true

      try {
        if (stripeElements) {
          const company = this.businessStore
          // Setup stripe payment method and
          // confirm this payment method with our backend
          await this.legacyBusinessStore.getAndConfirmStripeSetupIntent({
            billingDetails: {
              name: company.name,
              address: this.stripeFormattedAddress,
              email: company.email,
              phone: '',
            },
            stripeElements,
            country: company.billing.country || '',
          })

          await this.fetchStripePaymentMethods()

          if (showSnackbar)
            this.widgetStore.createSnackbar({
              message: this.$t('snackbar.payment_method_added.message'),
            })

          // Close panels
          if (closePanels) this.billingPanelOpen = null
        }
      } catch (error: unknown) {
        this.errorCode = (error as { code?: string }).code ?? ''
      } finally {
        this.savingPaymentMethod = false
      }
    },
    async fetchStripePaymentMethods(): Promise<void> {
      // Fetch new card details from backend
      this.loadingPaymentMethods = true
      await this.legacyBusinessStore.getStripePaymentMethods()
      this.loadingPaymentMethods = false
    },
    onErrorMessage(message: string) {
      this.widgetStore.createSnackbar({
        message,
      })
    },
  },
})
</script>

<style scoped lang="sass">
$ns: business-billing-payment

.#{$ns}
  &__section-title
    &--hint
      line-height: 1rem
      font-size: 0.75rem
      width: 80px
</style>
