<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

// TODO: Are these layout props really needed?
const props = withDefaults(
  defineProps<{
    quality: 'ses' | 'aes' | 'qes'
    legislation: 'zertes' | 'eidas' | ''
    lang: Language
    showImage: boolean
    to: string
    closable: boolean
  }>(),
  {
    legislation: '',
    showImage: false,
    closable: false,
  }
)

const emit = defineEmits<{
  (event: 'action' | 'close'): void
}>()

const { t } = useI18n()
</script>

<template>
  <base-dialog data-cy="upgrade_standards_dialog" max-width="640" :title="t('viewer.unlock_qes.overlay.title')">
    <v-row>
      <v-col cols="12" sm="7">
        <p class="mb-4">
          <strong>{{ $t('viewer.unlock_qes.overlay.subtitle') }}</strong>
        </p>
        <p class="mb-4">
          <v-row align="center">
            <v-col cols="2">
              <nuxt-img
                width="34"
                height="34"
                class="mr-4 | quality-badge"
                :src="`/img/badge-${props.quality}-${props.lang}.png`"
                alt=""
              />
            </v-col>
            <v-col v-if="props.quality === 'aes'">
              {{ $t('viewer.unlock_qes.overlay.aes_text') }}
            </v-col>
            <v-col v-else-if="props.legislation === 'zertes'">
              {{ $t('viewer.unlock_qes.overlay.ch_text') }}
            </v-col>
            <v-col v-else-if="props.legislation === 'eidas'">
              {{ $t('viewer.unlock_qes.overlay.eu_text') }}
            </v-col>
          </v-row>
        </p>
        <p class="mb-4">
          <strong>{{ $t('viewer.unlock_qes.overlay.hint_title') }}</strong>
        </p>
        <p class="mb-4">{{ $t('viewer.unlock_qes.overlay.hint_text') }}</p>
      </v-col>
      <v-col v-if="props.showImage" order="1" sm="5">
        <responsive-image source="sign-contract" :width="281" :height="268" />
      </v-col>
    </v-row>
    <template #actions>
      <v-card-actions>
        <v-btn size="x-large" :block="props.closable" color="info" variant="outlined" @click="emit('close')">
          {{ $t('viewer.unlock_qes.overlay.close') }}
        </v-btn>
        <v-spacer />
        <v-btn
          size="x-large"
          :class="props.closable ? 'order-first' : ''"
          color="info"
          :block="props.closable"
          :href="props.to"
          variant="elevated"
        >
          {{ $t('viewer.unlock_qes.overlay.increase') }}
        </v-btn>
      </v-card-actions>
    </template>
  </base-dialog>
</template>

<style lang="sass">
.quality-badge
  width: 34px
  height: 34px
  flex: 0 0 auto
</style>
