<template>
  <v-container class="fill-height content">
    <v-row class="fill-height" align="center">
      <v-col cols="12" md="5">
        <div class="mb-10">
          <skr-heading level="1" semantic>{{ $t('password_reset.title') }}</skr-heading>
          <skr-heading level="2">{{ $t('password_reset.enter_mail') }}</skr-heading>
        </div>
        <v-form v-model="isValid" class="v-form-skr-flex mb-6">
          <text-input
            v-model="email"
            type="email"
            :hint="$t('global.email_editor_help_text')"
            :label="$t('global.email_address')"
            @enter="resetPassword"
          />
          <skr-button size="xl" block :disabled="!isValid" @click="resetPassword">
            {{ $t('global.next') }}
          </skr-button>
        </v-form>
      </v-col>
      <v-col v-if="$vuetify.display.mdAndUp" cols="7">
        <responsive-image source="password-reset" :width="560" :height="420" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import SkrButton from '@/components/button/SkrButton.vue'
import SkrHeading from '@/components/SkrHeading.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

export default defineComponent({
  components: {
    ResponsiveImage,
    SkrButton,
    TextInput,
    SkrHeading,
  },
  setup() {
    definePageMeta({
      layout: 'unauthenticated',
    })

    const { t } = useI18n()

    const { createSnackbar } = useWidgetStore()

    const email = ref('')
    const isValid = ref(false)

    const { userRepository } = useApi()

    const resetPassword = async () => {
      if (!isValid.value) return

      try {
        await userRepository.requestPasswordReset(email.value)
        await navigateTo({
          name: 'password-reset-mail-sent',
          params: { email: email.value },
        })
      } catch {
        void createSnackbar({
          message: t('global.general_error'),
        })
      }
    }

    return {
      email,
      isValid,
      resetPassword,
    }
  },
  head() {
    return {
      title: 'Password Reset',
    }
  },
})
</script>
