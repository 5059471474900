<script lang="ts" setup>
interface MenuItem {
  title?: string
  value?: string
  type?: string
}

const props = withDefaults(
  defineProps<{
    modelValue?: string | string[]
    label?: string
    placeholder?: string
    rules?: ((value: unknown) => boolean | string)[]
    multiple?: boolean
    maxValues?: number
    maxSuggestions?: number
    autofocus?: boolean
  }>(),
  {
    maxSuggestions: 3,
  }
)

const emit = defineEmits<{
  (event: 'focus' | 'blur' | 'tab'): void
  (event: 'select', value: string): void
  (event: 'enter', blurComponent: () => void): void
  (event: 'update:error', isError: boolean): void
  (event: 'update:modelValue' | 'input', value: string | string[]): void
}>()

const { t } = useI18n()

const widgetStore = useWidgetStore()

const options = computed(() => {
  if (!props.multiple) {
    return {
      hideDetails: true,
    }
  }
  return {
    multiple: true,
    counter: props.maxValues ? Number(props.maxValues) : false,
    hideDetails: !props.maxValues,
  }
})

const { isEmail } = useValidation()

const inputRules = computed(() => {
  if (props.rules) return props.rules

  if (props.multiple) {
    return [(value: string[]) => value.every(isEmail)]
  } else {
    return [(value: string) => isEmail(value)]
  }
})

const selection = computed({
  get() {
    if (!props.modelValue) return props.multiple ? [] : ''
    return props.modelValue
  },
  set(newValue: string | string[]) {
    emit('input', newValue)
    emit('update:modelValue', newValue)
  },
})

const query = ref<string>()

/**
 * Convenience computed property to abstract away nullish values
 * that may be emitted by Vuetify components.
 */
const searchInput = computed({
  get: () => query.value ?? '',
  set: (newValue?: string) => {
    query.value = newValue
  },
})

const { isLoading, suggestions, suggestionType, suggestionsHeader, error, updateSuggestions } =
  useEmailSuggestions(query)

watch(error, e => {
  if (e) {
    widgetStore.createSnackbar({ message: t('global.general_error') })
  }
})

/**
 * Values added by the user that we need to keep track of in order to allow selecting
 * values that are not part of the BE suggestions.
 */
const userAdditions = ref<string[]>([])

const menuItems = computed<MenuItem[]>(() => {
  const items: MenuItem[] = []

  const selectedValues = (Array.isArray(selection.value) ? selection.value : [selection.value]).filter(Boolean)

  // In some cases we want to show a suggestion header, but only if there are unselected suggestions
  if (suggestionsHeader.value && suggestions.value.some(suggestion => !selectedValues.includes(suggestion))) {
    items.push({
      type: 'subheader',
      title: suggestionsHeader.value,
    })
  }

  const values = [...new Set([...userAdditions.value, ...selectedValues])]

  // Limit number of displayed fresh suggestions
  const addedSuggestions = suggestions.value
    .filter(suggestion => !values.includes(suggestion))
    .slice(0, Number(props.maxSuggestions))

  items.push(...[...values, ...addedSuggestions].map(title => ({ value: title, title })))

  return items
})

/**
 * Filter function for the Vuetify autocomplete component.
 *
 * Most of the filtering is done by the backend, but we want to prevent showing
 * lingering prefix results between debounce intervals.
 */
const itemFilter = (value: string, query: string, item?: { raw: MenuItem }) => {
  if (suggestionType.value === 'prefix') {
    return Boolean(query && item?.raw.title?.toLowerCase().startsWith(query.toLowerCase()))
  }

  return true
}

const addEmail = (email: string) => {
  addEmails([email])
}

const addEmails = (emails: string[]) => {
  emails.forEach(email => {
    if (!userAdditions.value.includes(email)) {
      userAdditions.value.push(email)
    }
  })

  if (props.multiple && Array.isArray(selection.value)) {
    selection.value = [...new Set([...selection.value, ...emails])]
  } else {
    selection.value = emails[0] ?? ''
  }
}

const removeItem = (value: string) => {
  if (Array.isArray(selection.value)) {
    selection.value = selection.value.filter(item => item !== value)
  }
}

watch(selection, (newSelection, oldSelection) => {
  // We need to remove previous user-provided values that are not part of the selection anymore
  userAdditions.value = userAdditions.value.filter(addition => newSelection.includes(addition))

  // We want to reset the search query when an item has been selected
  if (props.multiple && newSelection.length > oldSelection.length) {
    searchInput.value = ''
  }
})

const separatorPattern = /[,;]/

watch(searchInput, input => {
  if (!input) {
    // TODO: The following method does not exist anymore, check if still needed
    // autocompleteComponent.value?.setMenuIndex(-1)
    return
  }

  if (props.multiple && separatorPattern.test(input)) {
    const maybeEmails = input
      .split(separatorPattern)
      .map(part => part.trim())
      .filter(Boolean)
    addEmails(maybeEmails)
    searchInput.value = ''
  } else if (!props.multiple) {
    userAdditions.value = [input]
    selection.value = input
  }
})

/*
// TODO: Implement under Vuetify 3

const highlightedIndex = ref(-1)

const highlightedSuggestion = computed(() => {
  if (props.multiple) return
  // Previewing in the input only makes sense for prefix suggestions
  if (searchInput.value === '' || suggestionType.value !== 'prefix') return

  const item = menuItems.value.filter(item => item.title !== selection.value)[highlightedIndex.value]

  return item?.title
})

const autocompleteElement = ref<HTMLElement | null>(null)
const placeholderElement = ref<HTMLElement | null>(null)
const inputElement = ref<HTMLInputElement | null>(null)

const observer = ref<MutationObserver | null>(null)

const repositionPlaceholder = (referenceElement: HTMLElement) => {
  if (autocompleteElement.value && placeholderElement.value) {
    const inputRect = referenceElement.getBoundingClientRect()
    const autocompleteRect = autocompleteElement.value.getBoundingClientRect()

    const absLeft = inputRect.left - autocompleteRect.left
    const absTop = inputRect.top - autocompleteRect.top + 7.5 // 8px is the padding of the input field

    Object.assign(placeholderElement.value.style, {
      left: `${absLeft}px`,
      top: `${absTop}px`,
    })
  }
}

const setupInputObserver = () => {
  if (!autocompleteElement.value) return

  placeholderElement.value = autocompleteElement.value.querySelector('.placeholder')
  inputElement.value = autocompleteElement.value.querySelector('.v-select__slot input')

  observer.value = new MutationObserver(mutations => {
    for (const mutation of mutations) {
      if ((mutation.target as HTMLInputElement) === inputElement.value) {
        repositionPlaceholder(inputElement.value)
      }
    }
  })

  if (inputElement.value) {
    observer.value.observe(inputElement.value, { attributes: true })
  }
}

onMounted(() => {
  setupInputObserver()
})


onBeforeUnmount(() => {
  observer.value?.disconnect()
})
*/

const onFocus = () => {
  // We want to already suggest recently used values
  // as soon as users focus on the empty input field
  if (!searchInput.value.length) {
    void updateSuggestions()
  }
  emit('focus')
}

const onBlur = () => {
  if (isEmail(searchInput.value)) {
    addEmail(searchInput.value)
  }
  if (props.multiple) {
    searchInput.value = ''
  }
  emit('blur')
}

const autocompleteComponent = ref()

// We need to be able to blur the component from the outside because
// we may lose focus without Vuetify knowing about it, e.g. when
// pressing the "Enter" key to add another input field.
const blurComponent = () => {
  autocompleteComponent.value?.blur()
}

const onEnter = () => {
  if (isEmail(searchInput.value)) {
    addEmail(searchInput.value)
  }
  emit('enter', blurComponent)
}
</script>

<template>
  <div
    ref="autocompleteElement"
    class="autocomplete"
    :class="{ 'multiple-email-chips': multiple }"
    data-cy="email_autocomplete"
  >
    <v-form @update:model-value="isValid => emit('update:error', !isValid)">
      <v-autocomplete
        ref="autocompleteComponent"
        v-model="selection"
        v-model:search="query"
        v-bind="options"
        variant="filled"
        hide-no-data
        autocomplete="off"
        list="autocompleteOff"
        aria-autocomplete="none"
        :hide-selected="suggestionType !== 'exact'"
        :menu-props="{ contentClass: 'autocomplete__menu' }"
        :auto-select-first="suggestionType !== 'recent' && suggestionType !== 'exact'"
        :custom-filter="itemFilter"
        :filter-keys="['title']"
        :autofocus="autofocus"
        :label="label"
        :placeholder="placeholder"
        :rules="inputRules"
        :loading="isLoading"
        :items="menuItems"
        menu-icon=""
        @focus="onFocus"
        @blur="onBlur"
        @keydown.enter="onEnter"
      >
        <template v-if="props.multiple" #chip="{ item }">
          <v-chip v-if="isEmail(item.title)" color="primary" closable @click:close="removeItem(item.title)">
            {{ item.title }}
          </v-chip>
          <v-chip v-else color="error-lighten-5" closable @click:close="removeItem(item.title)">
            {{ item.title }}
          </v-chip>
        </template>
        <template #item="{ props: itemProps, item }">
          <v-list-item v-bind="{ ...itemProps, title: '' }" :disabled="item.raw.type === 'subheader'">
            <span v-if="item.raw.type === 'subheader'" class="text-subtitle-2">{{ item.title }}</span>
            <span v-else-if="suggestionType === 'prefix' && item.title.startsWith(searchInput)">
              <span>{{ searchInput }}</span
              ><span class="font-weight-bold">{{ item.title.slice(searchInput.length) }}</span>
            </span>
            <span v-else-if="suggestionType === 'domain'">
              <span class="font-weight-bold">{{ item.title.slice(0, item.title.indexOf('@')) }}</span
              ><span>{{ item.title.slice(item.title.indexOf('@')) }}</span>
            </span>
            <span v-else-if="suggestionType === 'suggest' || suggestionType === 'recent'" class="font-weight-bold">{{
              item.title
            }}</span>
            <span v-else>{{ item.title }}</span>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-form>
  </div>
</template>

<style lang="sass">
// We need to apply these styles globally because Vuetify detaches menus by default
// and we need to overwrite undesired very specific default styles via `!important`
.autocomplete__menu
  .v-list
    box-shadow: 0 12px 20px -5px rgba(0,0,0,0.15) !important
  .v-list-item
    &:hover
      color: $c-primary !important
    &::before
      background-color: currentColor !important
</style>

<style lang="sass" scoped>
.autocomplete
  flex: 1 1 auto
  position: relative
  &.multiple-email-chips
    :deep(.v-field__input)
      row-gap: 16px
      &:has(> .v-autocomplete__selection)
        margin-top: 12px
        margin-bottom: 12px
    :deep(.v-autocomplete__selection)
      margin-inline-end: 12px
  :deep(.v-chip)
    color: $c-primary
    background-color: rgba(179, 212, 247, 0.5)
    font-weight: normal
    .v-icon
      color: currentColor
  :deep(.v-select__selections)
    min-height: initial !important
    padding-top: 0 !important
  :deep(.v-label--active)
    + .v-select__selections
      padding-top: 24px !important
  .placeholder
    pointer-events: none
    position: absolute
    max-height: 32px
    font-size: 1.125rem
    line-height: 1.125rem
</style>
