export default function useSubNav(type: Ref<string | null>) {
  const { t } = useI18n()

  const navigationItems = computed(() => {
    if (type.value === 'profile') {
      return [
        { route: '/profile', title: t('global.navigation.personal_data'), icon: 'custom:circle_profile' },
        {
          route: '/profile/signature-standards',
          title: t('global.navigation.signature_standards'),
          icon: 'custom:signature_standards',
        },
        { route: '/profile/visual-signature', title: t('global.navigation.visual_signature'), icon: 'custom:artboard' },
        { route: '/profile/settings', title: t('global.navigation.settings'), icon: 'custom:gear' },
        { divider: true },
        { route: '/logout', title: t('global.navigation.logout'), icon: 'custom:leave' },
      ]
    }

    return []
  })

  return {
    navigationItems,
  }
}
