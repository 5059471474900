<script lang="ts" setup>
defineProps<{
  items: (NavigationItem | NavigationDivider)[]
}>()

const route = useRoute()
</script>

<template>
  <div>
    <nav class="nav">
      <div v-for="(item, i) in items" :key="`${item}-${i}`">
        <v-divider v-if="item.divider" class="my-2 mx-4" />
        <v-btn
          v-else
          class="nav__link"
          :class="{
            'nav__link--active': item.route === route.path,
          }"
          selected-class="nav__link--active"
          :exact="true"
          :ripple="false"
          rounded
          variant="plain"
          @click="navigateTo(item.route)"
        >
          {{ item.title }}
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </div>
    </nav>
  </div>
</template>

<style lang="sass" scoped>
.nav
  display: flex
  flex-direction: column
  min-width: 230px
  margin-right: 24px

  &__link
    width: 100%
    height: 44px !important
    margin: 0
    font-weight: bold
    font-size: 1rem !important
    cursor: pointer
    justify-content: unset
    opacity: 1
    &--active
      background-color: $c-primary-bg
      color: $c-primary
    &:hover
      color: $c-primary
    :deep(.v-btn__content)
      display: flex
      justify-content: space-between
      align-items: center
      opacity: 1 !important
</style>
