<template>
  <v-container class="fill-height content">
    <v-row class="fill-height" align="center">
      <v-col v-if="isLoading">
        <v-progress-circular :size="70" color="text" indeterminate />
      </v-col>
      <template v-else-if="isVerificationFailed">
        <v-col>
          <div class="mb-10">
            <skr-heading level="1" semantic>{{ $t('password_reset.confirm.error.title') }}</skr-heading>
            <skr-heading level="2">{{ $t('password_reset.confirm.error.subtitle') }}</skr-heading>
          </div>
          <skr-button size="xl" :to="{ name: 'password-reset' }">{{
            $t('password_reset.confirm.error.cta')
          }}</skr-button>
        </v-col>
        <v-col v-if="$vuetify.display.mdAndUp">
          <responsive-image source="set-password-link-expired" :width="376" :height="441" />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import SkrButton from '@/components/button/SkrButton.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SkrHeading from '@/components/SkrHeading.vue'

export default defineComponent({
  components: {
    ResponsiveImage,
    SkrButton,
    SkrHeading,
  },
  setup() {
    definePageMeta({
      layout: 'unauthenticated',
    })

    const route = useRoute()

    const languageStore = useLanguageStore()

    const { userRepository } = useApi()

    const isLoading = ref(true)
    const isVerificationFailed = ref(false)

    const verifyToken = async () => {
      try {
        const token = route.query.s as string | undefined
        if (!token) throw new Error('No token provided')
        localStorage.setItem('reset-token', token)

        await userRepository.verifyConfirmationToken(token, languageStore.language)
        isLoading.value = false

        // Delay the redirection to make sure that we got the token right
        window.setTimeout(() => {
          void navigateTo({ name: 'signup-flows-eidplus-new' })
        }, 100)
      } catch {
        // There was a problem, most likely the token timed out
        // or an invalid token was sent, so display an error
        // remove previously set token in error case
        localStorage.removeItem('reset-token')
        isVerificationFailed.value = true
        isLoading.value = false
      }
    }

    onBeforeMount(() => {
      // We saw some issues with undefined tokens at password-reset which was most
      // likely because we set the value based on query parameter s. it might
      // have happened that this parameter was only read after the redirection
      // which changed the route and therefore had no longer access to s.
      // we now store it in a variable and won't redirect until everything is
      // properly loaded.
      void verifyToken()
    })

    return {
      isLoading,
      isVerificationFailed,
      verifyToken,
    }
  },
  head() {
    return {
      title: 'Sign Up Confirm EID+',
    }
  },
})
</script>
