export default defineNuxtPlugin(() => {
  if (window.Cypress) {
    const documentStore = useDocumentStore()
    const invitationStore = useInvitationStore()

    window.__STORE__ = {
      document: documentStore,
      invitation: invitationStore,
    }
  }
})
