<script lang="ts" setup>
defineProps<{
  navigation: (NavigationItem | NavigationDivider)[]
}>()

const route = useRoute()
</script>

<template>
  <!--
    The in page nav shows a vertical navigation to the left of the content.
  -->
  <div class="in-page-nav pt-1">
    <nav class="in-page-nav__side">
      <div v-for="(item, i) in navigation" :key="`${item}-${i}`">
        <v-divider v-if="item.divider" class="my-2 mx-4" />
        <v-btn
          v-else
          class="in-page-nav__link"
          :class="{
            'v-btn--active': item.showAsActive || item.routeName === route.name,
          }"
          :exact="true"
          :ripple="false"
          :data-cy="item.cypressIdentifier"
          rounded
          variant="plain"
          @click="navigateTo({ name: item.routeName })"
        >
          {{ item.displayTitle }}
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </div>
    </nav>
  </div>
</template>

<style lang="sass">
.in-page-nav
  position: sticky
  top: 22px

  &__side
    display: flex
    flex-direction: column
    min-width: 230px
    margin-right: 24px
    +maw(sm)
      display: none

  &__link
    width: 100%
    height: 44px !important
    margin: 0
    font-weight: bold
    font-size: 1rem !important
    cursor: pointer
    justify-content: unset
    opacity: 1
    &.v-btn--active
      background-color: $c-primary-bg
      color: $c-primary
    &:hover
      color: $c-primary

    .v-btn__content
      display: flex
      justify-content: space-between
      align-items: center
      opacity: 1 !important
</style>
