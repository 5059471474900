import { format, formatDistanceToNowStrict, formatRelative, type Locale } from 'date-fns'
import { de, enGB, frCH, it } from 'date-fns/locale'

export default function useDate() {
  const { locale } = useI18n()

  const relativeDateDefaults = {
    lastWeek: 'PP',
    nextWeek: 'PP',
    other: 'PP',
  }

  const localeData = computed<{ config: Locale; format: string }>(() => {
    switch (locale.value) {
      case 'de-ch':
        return {
          config: {
            ...de,
            formatRelative(token: string) {
              return {
                ...relativeDateDefaults,
                yesterday: "'Gestern'",
                tomorrow: "'Morgen'",
                today: "'Heute'",
              }[token]
            },
          },
          format: 'd. LLLL yyyy',
        }
      case 'fr-ch':
        return {
          config: {
            ...frCH,
            formatRelative(token: string) {
              return {
                ...relativeDateDefaults,
                yesterday: "'Hier'",
                tomorrow: "'Demain'",
                today: "'Aujourd’hui'",
              }[token]
            },
          },
          format: 'd LLLL yyyy',
        }
      case 'it':
        return {
          config: {
            ...it,
            formatRelative(token: string) {
              return {
                ...relativeDateDefaults,
                yesterday: "'Ieri'",
                tomorrow: "'Domani'",
                today: "'Oggi'",
              }[token]
            },
          },
          format: 'd LLLL yyyy',
        }
      case 'en-gb':
      default:
        return {
          config: {
            ...enGB,
            formatRelative(token: string) {
              return {
                ...relativeDateDefaults,
                yesterday: "'Yesterday'",
                tomorrow: "'Tomorrow'",
                today: "'Today'",
              }[token]
            },
          },
          format: 'd LLLL yyyy',
        }
    }
  })

  const formatDate = (date: Date, pattern?: string) => {
    return format(date, pattern ?? localeData.value.format, { locale: localeData.value.config })
  }

  const formatRelativeDate = (date: Date) => {
    return formatRelative(date, new Date(), { locale: localeData.value.config })
  }

  const formatDistanceToNow = (date: Date) => {
    return formatDistanceToNowStrict(date, {
      roundingMethod: 'floor',
      locale: localeData.value.config,
    })
  }

  return {
    formatDate,
    formatRelativeDate,
    formatDistanceToNow,
  }
}
