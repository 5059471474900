interface GetDirectUserResponse {
  attributes: Partial<Camelized<UserAttributes>>
  emailVerified: boolean
  username: string
  retention: number
  disabledQualities: string[]
  hasSso: boolean
  email: string
}

const userRepository = (fetch: CustomFetch) => ({
  async get(): Promise<Partial<IUser>> {
    const userData = await fetch<GetDirectUserResponse>('/v1/direct/user')

    // FIXME: We are still using snake_case for attributes
    const baseData = { ...userData, attributes: objectToSnakeCase(userData.attributes) }

    const signatureQualityData = await fetch<SignatureQualities>('/v1/direct/user/signature-qualities-detail')

    return {
      ...baseData,
      id: baseData.username,
      signatureQualities: {
        ...signatureQualityData,
        // FIXME: We are still using snake_case for seals
        seal: objectToSnakeCase(signatureQualityData.seal),
      },
    }
  },
  async update(data: DirectUserUpdateData) {
    await fetch('/v1/direct/user', {
      method: 'PUT',
      body: data,
    })
  },
})

export default userRepository
