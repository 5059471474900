import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45exit_45url_45global from "/opt/build/repo/src/middleware/01.exitUrl.global.ts";
import _02_45login_45email_45global from "/opt/build/repo/src/middleware/02.loginEmail.global.ts";
import _03_45cookiesblocked_45global from "/opt/build/repo/src/middleware/03.cookiesblocked.global.ts";
import _04_45auth_45global from "/opt/build/repo/src/middleware/04.auth.global.ts";
import _05_45i18n_45global from "/opt/build/repo/src/middleware/05.i18n.global.ts";
import _06_45testing_45global from "/opt/build/repo/src/middleware/06.testing.global.ts";
import _07_45locked_45global from "/opt/build/repo/src/middleware/07.locked.global.ts";
import _08_45business_45global from "/opt/build/repo/src/middleware/08.business.global.ts";
import _09_45identification_45global from "/opt/build/repo/src/middleware/09.identification.global.ts";
import _10_45sso_45global from "/opt/build/repo/src/middleware/10.sso.global.ts";
import _11_45userpilot_45global from "/opt/build/repo/src/middleware/11.userpilot.global.ts";
import _12_45free_45user_45global from "/opt/build/repo/src/middleware/12.freeUser.global.ts";
import _13_45user_45delete_45global from "/opt/build/repo/src/middleware/13.userDelete.global.ts";
import _14_45billing_45global from "/opt/build/repo/src/middleware/14.billing.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45exit_45url_45global,
  _02_45login_45email_45global,
  _03_45cookiesblocked_45global,
  _04_45auth_45global,
  _05_45i18n_45global,
  _06_45testing_45global,
  _07_45locked_45global,
  _08_45business_45global,
  _09_45identification_45global,
  _10_45sso_45global,
  _11_45userpilot_45global,
  _12_45free_45user_45global,
  _13_45user_45delete_45global,
  _14_45billing_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}