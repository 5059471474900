<template>
  <v-container v-if="isIdentificationCovered === null" class="fill-height">
    <v-progress-circular :size="70" color="text" indeterminate />
  </v-container>
  <two-cols-block v-else class="fill-height" split="5/7">
    <template #left>
      <div class="mb-12">
        <skr-heading level="1">{{ t('eu_ident.title_new') }}</skr-heading>
      </div>
      <identification-methods
        :available-methods="availableMethods"
        :show-help-link="!isIdentificationCovered"
        @select="onSelect"
      />
      <skr-button class="mt-8" size="xl" type="secondary" block @click="router.back()">
        {{ t('global.button.go_back') }}
      </skr-button>
    </template>
    <template v-if="$vuetify.display.mdAndUp" #right>
      <responsive-image source="zertes-id-magnifying-glass" :width="570" :height="630" />
    </template>
  </two-cols-block>
</template>

<script lang="ts">
import IdentificationMethods from '@/components/identification/IdentificationMethods.vue'
import SkrHeading from '@/components/SkrHeading.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import TwoColsBlock from '@/components/layout/TwoColsBlock.vue'
import SkrButton from '@/components/button/SkrButton.vue'

export default defineComponent({
  components: {
    SkrButton,
    TwoColsBlock,
    ResponsiveImage,
    SkrHeading,
    IdentificationMethods,
  },
  setup() {
    definePageMeta({
      layout: 'simple',
    })

    const { t } = useI18n()

    const router = useRouter()

    const { isIdentificationCovered, availableMethods, redirect, selectMethod } = useIdentAvailability('zertes')

    const onSelect = (method: IdentificationMethod) => {
      if (method === 'external-sc') {
        redirect()
      } else {
        selectMethod(method)
      }
    }

    return { t, router, isIdentificationCovered, availableMethods, onSelect }
  },
})
</script>

<style lang="sass">
.sisale
  &__radio
    +skribble-box-shadow
    width: 100%
    border: 2px solid transparent
    border-radius: 3px
    &.text-accent
      border-color: $c-primary

    &-title
      font-size: 1.25rem
      font-weight: bold
      color: $c-skribbleu
      +maw(xs)
        font-size: 1.125rem

    &-text
      font-size: 1rem
      +maw(xs)
        font-size: 0.875rem

  &__flag
    width: 50px
    height: 50px
    border-radius: 3px
    object-fit: cover
    +maw(xs)
      width: 40px
      height: 40px
</style>
